<script lang="jsx">
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { template } from '@shein/common-function'
export default {
  name: 'NewParallelImport',
  functional: true,
  props: {
    language: {
      type: Object,
      default: () => {},
    },
    router: {
      type: Object,
      default: () => {},
    },
    exposeInfo: {
      type: Object,
      default: () => {}
    }
  },
  render(h, { props }) {
    const { language, router, exposeInfo } = props
    const { SHEIN_KEY_PWA_28730, SHEIN_KEY_PWA_28731, SHEIN_KEY_PWA_28732 } = language || {}
    const parallelimportText = `<div id="ParallelImportBox" class="ParallelImportBox">${template(`<span style="color: #2D68A8;">${SHEIN_KEY_PWA_28731}</span>`, SHEIN_KEY_PWA_28730)} ${SHEIN_KEY_PWA_28732}</div>`
    const jumpTo = (e) => {
      if (e?.target?.id !== 'ParallelImportBox') {
        daEventCenter.triggerNotice({ daId: '1-6-1-175', bindData: exposeInfo })
        if (typeof gbCommonInfo !== 'undefined') {
          const { langPath } = gbCommonInfo
          router?.push?.(`${langPath}/product/article/2008`)
        }
      }
    }
    return <div 
      {...{ directives: [{ name: 'expose', value: { id: '1-6-1-176', data: exposeInfo || {} } }] }}
      domPropsInnerHTML={parallelimportText} 
      vOn:click={jumpTo} 
      class="ParallelImport"
    ></div>
  },
}
</script>

<style lang="less">
.ParallelImport {
  margin-bottom: .213333rem;
  background: #fff;
  color: #767676;
  font-size: 12px;
  line-height: 14px;
  overflow: hidden;
}
.ParallelImportBox {
  margin: 0.4rem 0.32rem;
  width: calc(100% - 0.64rem);
}
</style>
  
