<script lang="jsx">
const { PUBLIC_CDN } = gbCommonInfo
export default {
  name: 'TrendsLabel',
  functional: true,
  props: {
    language: {
      type: Object,
      default: () => ({})
    },
    trendsInfo: {
      type: Object,
      default: undefined
    },
    trendsImg: {
      type: String,
      default: ''
    }
  },
  render(h, { props }) {
    const { trendsInfo, language, trendsImg } = props
    const displayTitle = trendsInfo?.display_title

    return (
      trendsInfo && (
        <span class="trends-label-wrap">
          {trendsImg ? (
            <img src={trendsImg} class="trends-label-wrap__trends-img" />
          ) : (
            <span class="trends-label-wrap-default">
              {language.SHEIN_KEY_PWA_30166 || 'Trends'}
              <img
                class="trends-label-wrap-default__icon"
                src={`${PUBLIC_CDN}/pwa_dist/images/store/infashion_label/default-fd2954326c.png`}
              />
            </span>
          )}
          {displayTitle && <span class="trends-label-wrap__trends-desc">{displayTitle}</span>}
        </span>
      )
    )
  }
}
</script>

<style lang="less" scoped>
.trends-label-wrap {
  height: 14px;
  line-height: 14px;
  display: flex;
  img {
    height: 100%;
    vertical-align: top;
  }
  &__trends-img {
    position: relative;
    z-index: 1;
  }
  &__trends-desc {
    font-size: 10px;
    color: rgba(148, 98, 255);
    background-color: #F4EFFF;
    padding: 0px 4px 0px 7px;
    border-radius: 0px 2px 2px 0px;
    margin-left: -4px;
    .text-overflow();
  }
  &-default {
    height: 14px;
    line-height: 14px;
    position: relative;
    display: inline-block;
    padding: 0 5px 0 2px;
    font-size: 11px;
    font-style: italic;
    font-weight: 858;
    color: #fff;
    z-index: 1;
    vertical-align: top;
    &__icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }
}
</style>
