<script lang="jsx">
import { template } from '@shein/common-function'
export default {
  name: 'ParallelImport',
  functional: true,
  props: {
    language: {
      type: Object,
      default: () => {},
    },
    click: {
      type: Function,
      default: () => null
    },
    exposeInfo: {
      type: Object,
      default: () => {}
    }
  },
  render(h, { props }) {
    const { language, click, exposeInfo } = props
    const { SHEIN_KEY_PWA_28730, SHEIN_KEY_PWA_28731, SHEIN_KEY_PWA_28732 } = language || {}
    const parallelimportText = `<div id="ParallelImportBox" class="ParallelImportBox">${template(`<span style="color: #2D68A8;">${SHEIN_KEY_PWA_28731}</span>`, SHEIN_KEY_PWA_28730)} ${SHEIN_KEY_PWA_28732}</div>`
    const jumpTo = (e) => {
      if (e?.target?.id !== 'ParallelImportBox') {
        click?.(e)
      }
    }
    return <div 
      {...{ directives: [{ name: 'expose', value: { id: '1-6-1-176', data: exposeInfo || {} } }] }}
      domPropsInnerHTML={parallelimportText} 
      vOn:click={jumpTo} 
      class="ParallelImport"
    ></div>
  },
}
</script>

<style lang="less">
.ParallelImport {
  margin-bottom: .213333rem;
  background: #fff;
  color: #767676;
  font-size: 12px;
  line-height: 14px;
  overflow: hidden;
}
.ParallelImportBox {
  margin: 0.4rem 0.32rem;
  width: calc(100% - 0.64rem);
}
</style>
  
