var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
    id: '1-6-4-85',
    data: {
      ..._vm.extAnalysisInfo,
      ..._vm.exposeConfig,
    }
  }),expression:"{\n    id: '1-6-4-85',\n    data: {\n      ...extAnalysisInfo,\n      ...exposeConfig,\n    }\n  }"}],staticClass:"category-rec"},[_c('div',{staticClass:"category-rec__wrap"},[_c('div',{staticClass:"category-rec__title",on:{"click":function($event){return _vm.toList()}}},[_c('div',{staticClass:"category-rec__title-txt"},[_vm._v("\n        "+_vm._s(_vm.language.SHEIN_KEY_PWA_30121)+"\n      ")]),_vm._v(" "),_c('Icon',{attrs:{"name":"sui_icon_more_right_16px_2","color":"#999","size":"16px"}})],1),_vm._v(" "),_c('div',{staticClass:"category-rec__list"},[_c('ul',_vm._l((_vm.categoryRecommendList),function(item,index){return _c('li',{key:item.cat_id,on:{"click":function($event){return _vm.toList(item, index)}}},[_c('div',{staticClass:"category-rec__list-imgbox"},[_c('img',{staticClass:"lazy lazyload",attrs:{"data-src":_vm.transformImg({ img: item.main_image?.medium_image }),"src":_vm.LAZY_IMG,"data-design-width":"30","data-image-url":item.main_image?.medium_image}})]),_vm._v(" "),_c('div',{staticClass:"category-rec__list-name"},[_vm._v("\n            "+_vm._s(item.cat_name)+"\n          ")])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }