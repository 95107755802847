import Vue from 'vue'
import { Toast, RadioColor, RadioGroup } from '@shein/sui-mobile'
Vue.prototype.$toast = Toast
Vue.use(RadioColor)
Vue.use(RadioGroup)

let compInstance = null

export const selectionPopup = {
  open: (config) => {
    if (compInstance) {
      compInstance.open(config)
      return compInstance
    }

    import(/* webpackChunkName: "select_quick_add" */ './SelectionPopup.vue').then((components) => {
      const selectionPopup = components.default
      const vm = new Vue({
        router: config.router,
        render (h) {
          return h(selectionPopup)
        }
      }).$mount()
      document.body.appendChild(vm.$el)
      const comp = vm.$children[0]
      comp.open(config)
      compInstance = comp
    })
  }
}
