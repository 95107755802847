<template>
  <div
    v-if="showHotPerson"
    ref="HotPersonZone"
    v-expose="{ id: '1-6-2-65'}"
    class="hot-person"
    @click.stop
    @touchend="touchChange()"
  >
    <div
      class="hot-person__title"
      :aria-label="template(allCustomerGallerySum, language.SHEIN_KEY_PWA_29455)"
      tabindex="1"
      :role="template(allCustomerGallerySum, language.SHEIN_KEY_PWA_29455)"
    >
      <div>
        {{ template(allCustomerGallerySum, language.SHEIN_KEY_PWA_29455) }}
      </div>
      <div
        class="view-all-btn"
        :class="viewAllBtnClassName"
        @click="openHotPerson(-1)"
      >
        <span>{{ language.SHEIN_KEY_PWA_22253 }}</span>
        <i class="suiiconfont sui_icon_more_right_12px_2"></i>
      </div>
    </div>
    <div
      :class="{
        'products-box-scroll': true,
        'products-box-scrollSingleLine': true
      }"
    >
      <div class="scroll-box">
        <!-- 商品项 -->
        <div
          v-for="(item,index) in customerShowList.slice(0, 10)"
          :key="item.comment_id"
          v-expose="getExpoedData(item, index)"
          class="hot-person__item_wrapper"
        >
          <img
            :data-src="transformImg({ img: item.firstImg })"
            :src="LAZY_IMG"
            data-design-width="200"
            class="lazy j-push-history-hash lazyload"
            :data-image-url="item.firstImg"
            :data-index="index + 1"
            @click="openHotPerson(item.comment_id)"
          />
        </div>
        <div
          v-if="allCustomerGallerySum>10"
          class="hot-person__mask"
          @click="openHotPerson(-1,3)"
        >
          <i class="suiiconfont sui_icon_viewmore_right_40px"></i>
          <p>{{ language.SHEIN_KEY_PWA_22253 }}</p>
        </div>
      </div>
    </div>

    <GalleryPopup
      v-if="galleryPopShowed"
      v-model="isShow"
      :language="language"
      :curId="curId"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import GalleryPopup from './GalleryPopup'
const { LAZY_IMG } = gbCommonInfo
import { daEventCenter } from 'public/src/services/eventCenter/index'
export default {
  /**
   * 商详负责推荐位- Similar Items 弹窗为一行两图
   */
  name: 'HotPersonZone',
  components: {
    GalleryPopup
  },
  data() {
    return {
      LAZY_IMG,
      viewAllBtnClassName: 'view-all-handler',
      isShow: false,
      curId: '',
      isTouch: false
    }
  },
  computed: {
    ...mapState('newProductDetail/common', ['customerShow',]),
    ...mapState('newProductDetail/CommentPopup', ['galleryPopShowed',]),
    ...mapGetters('newProductDetail/CustomerGallery', [
      'customerShowList',
      'allCustomerGallerySum'
    ]),
    ...mapGetters('newProductDetail', ['fsAbt']),
    ...mapGetters('newProductDetail/common', [
      'language',
    ]),
    showHotPerson() {
      return this.fsAbt?.Reviewchange?.param?.BuyerShow === 'Show' && this.customerShowList?.length > 0
    },
  },
  watch: {
    'customerShow'(newVal) {
      this.isShow = newVal
    }
  },
  methods: {
    ...mapMutations('newProductDetail/common', ['updateCustomerShow']),
    ...mapMutations('newProductDetail/CommentPopup', ['updateCommentInfoDataForImg', 'updateGalleryPopShowed']),
    changeHash (value) {
      location.hash = value ? '#' + value : ''
    },
    // 弹出弹窗
    openHotPerson(id, type) {
      this.curId = id
      this.changeHash('customer-show')
      if(!this.galleryPopShowed) {
        // init
        this.updateCommentInfoDataForImg([])
      }
      this.updateGalleryPopShowed(true)
      this.updateCustomerShow(true)
      daEventCenter.triggerNotice({
        daId: '1-6-2-67',
        extraData: {
          click_type: type ? 3 : id === -1 ? 1 : 2,
        }
      })
    },
    touchChange() {
      if(this.isTouch) return
      this.isTouch = true
      daEventCenter.triggerNotice({
        daId: '1-6-2-68',
      })
    },
    getExpoedData(item, index) {
      const { type = '', comment_id, reportId = '' } = item
      let eId = ''
      let typeNum = -1
      if(type === 'gallery') {
        typeNum = 1
      } else if(reportId) {
        typeNum = 2
        eId = reportId
      } else {
        typeNum = 3
        eId = comment_id
      }
      const img_list =  `${eId}\`${typeNum}\`${index + 1}`
      const data = {
        id: '1-6-2-66',
        code: 'gallery',
        data: {
          img_list
        }
      }
      return data
    }
  },
}
</script>

<style lang="less">
.hot-person {
  background: #fff;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  &__title {
    padding-top: 0.2667rem;
    // padding-bottom: 0.05rem;
    margin: 0 0.32rem;
    .flexbox;
    justify-content: space-between;
    line-height: 0.51rem;
    .font-dpr(28px);
    font-weight: bold;
    /* rw:begin */
    font-family: 'Adieu';

    .view-all-btn {
      .font-dpr(24px);
      font-weight: normal;
      color: @sui_color_gray_dark2;
    }
  }
  &__item_wrapper {
    width: 2.66666rem;
    height: 2.66666rem;
    border-radius: 0.106666rem;
    &:not(:first-child) {
      margin-left: 0.213333rem;
    }
    &:first-child {
      margin-left: 0.32rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__mask {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 2.66666rem;
    height: 2.66666rem;
    background: var(---sui_color_gray_weak2a, #fafafa);
    border-radius: 0.106666rem;
    margin-left: 0.213333rem;
    margin-right: 0.32rem;
  }
}
// 横滑推荐商品栏
.products-box-scroll {
  width: calc(100% + 0.64rem);
  overflow-x: scroll;
  margin-top: 0.16rem;
  display: flex;
  position: relative;
  left: -0.32rem;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .scroll-box {
    display: flex;
    position: relative;
    padding: 0 0.32rem;
  }
}
.products-box-scrollSingleLine {
  .scroll-box {
    flex-wrap: nowrap;
  }
  .product-item-ctn {
    margin-right: 0.213333rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
