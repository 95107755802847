<template>
  <div>
    <s-drawer
      :visible="isShow"
      no-header
      type="full"
      direction="rtl"
      class="gallery"
      append-to-body
    >
      <div
        ref="gallery"
        class="gallery_body"
        da-expose-code="gallery"
      >
        <section
          ref="header"
          class="mshe-header-fixed"
        >
          <s-title-nav class="j-sui-header">
            <template slot="preblock">
              <s-title-nav-item
                class="j-icon-back"
                icon-class="suiiconfont sui_icon_nav_back_24px"
                tabindex="0"
                @keyup.enter.stop.prevent="goBack"
                @click="goBack"
              />
            </template>
            <template slot="default">
              <h1
                class="goods-review__title"
                tabindex="0"
              >
                {{
                  template(allCustomerGallerySum, language.SHEIN_KEY_PWA_29455)
                }}
              </h1>
            </template>
            <template slot="endblock">
              <a
                :href="`${langPath}/cart`"
                class="bag-btn-wrap animated header-bag j-header-bag-is-review"
                :data-cart-num="cartSumQuantity"
                @click="bagClickEvt"
              >
                <i class="suiiconfont sui_icon_nav_shoppingbag_24px"></i>
                <em
                  class="j-header-cart-num"
                  :class="{ 'mshe-hide': !+cartSumQuantity }"
                >
                  {{ cartSumQuantity }}
                </em>
              </a>
            </template>
          </s-title-nav>
        </section>

        <div class="gallery_list">
          <div
            v-infinite-scroll="fetchMoreReviews"
            infinite-scroll-disabled="commentScrollLoading"
            infinite-scroll-nodata="noMoreCommentFromImg"
            infinite-scroll-distance="300"
          >
            <WaterFall
              :items="allCustomerShowList"
              class="water-fall"
            >
              <template
                slot="default"
                slot-scope="{ item, index }"
              >
                <div
                  :ref="'item' + item.comment_id"
                  v-expose="getExposeData(item, index)"
                  class="gallery_list-item"
                  :data-id="item.comment_id"
                >
                  <div class="gallery_list-imgbox">
                    <img
                      :data-src="
                        transformImg({
                          img:
                            item.firstImg ||
                            item.comment_image?.[0]?.member_image_middle
                        })
                      "
                      :src="LAZY_IMG"
                      width="100%"
                      class="lazy lazyload"
                      :data-image-url="
                        item.firstImg || item.comment_image?.[0]?.member_image_middle
                      "
                      @click="showReviewImg(index, item)"
                    />
                  </div>
                  <div class="gallery_list-con">
                    <div
                      v-if="item.type === 'gallery'"
                      class="gals"
                    >
                      <span>{{ language.SHEIN_KEY_PWA_29457 }}</span>
                      <Icon
                        name="sui_icon_hongren_white_14px_2"
                        size="14px"
                        color="#000"
                      />
                    </div>
                    <div
                      v-else
                      class="comment-info"
                    >
                      <h3>
                        {{ item.content }}
                        <!-- 评论引导标签 -->
                        <ul
                          v-if="item.commentTags && item.commentTags.length > 0"
                          class="comment-tag-box"
                        >
                          <li
                            v-for="(tag, key) in item.commentTags"
                            :key="tag.id + key"
                            style="direction: ltr"
                          >
                            <span
                              class="tag-name"
                            >{{ tag.name }}</span>: <span
                              class="tag-content"
                            >{{ tag.content }}</span>
                          </li>
                        </ul>
                      </h3>
                      <div class="comment-bottom">
                        <div class="left">
                          <span class="name">{{
                            item.user_name | userName
                          }}</span>
                          <span
                            v-if="item.reportId"
                            class="report"
                            aria-hidden="true"
                          >
                            {{ language.SHEIN_KEY_PWA_15207 }}
                          </span>
                        </div>
                        <div class="right">
                          <i
                            class="suiiconfont"
                            :class="
                              item.current_member_zan === 1
                                ? 'sui_icon_like_completed_20px'
                                : 'sui_icon_like_20px'
                            "
                            @click="handleLike($event, item)"
                          ></i>
                          <span>{{ item.like_num }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </WaterFall>
          </div>
        </div>
      </div>
    </s-drawer>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { Icon } from '@shein-aidc/icon-vue2'
import WaterFall from 'public/src/pages/components/product/WaterFall'
import commentMixin from 'public/src/pages/goods_detail/components/middle/innerComponents/Reviews/mixin/comment.js'
import {
  TitleNav as STitleNav,
  TitleNavItem as STitleNavItem
} from '@shein/sui-mobile'
import { transformImg, template } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { isLogin } from 'public/src/pages/common/utils/index.js'

const { langPath, LAZY_IMG } = gbCommonInfo
const POSITION = 'gallery'

export default {
  components: {
    STitleNav,
    STitleNavItem,
    Icon,
    WaterFall
  },
  filters: {
    userName(username = '') {
      if (
        username.indexOf('*') === 1 &&
        username.lastIndexOf('*') === username.length - 2
      ) {
        username = username.split('*')
        username = `${username[0]}***${username[username.length - 1]}`
      }
      return username
    }
  },
  mixins: [commentMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    curId: {
      type: [String, Number],
      default: ''
    },
    language: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      LAZY_IMG,
      code: 'gallery-pop',
      langPat: langPath
    }
  },
  computed: {
    ...mapState('newProductDetail/CustomerGallery', [
      'commentScrollLoading',
      'noMoreComment'
    ]),
    ...mapGetters('newProductDetail/CustomerGallery', [
      'customerShowList',
      'allCustomerGallerySum'
    ]),
    ...mapState('newProductDetail/CommentPopup', [
      'commentScrollLoading',
      'noMoreCommentFromImg',
      'commentInfoDataForImg'
    ]),
    isShow: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    cartSumQuantity() {
      return this.$store.state.cartInfo.sum
    },

    allCustomerShowList() {
      const list = [...this.customerShowList, ...this.commentInfoDataForImg]
      list.forEach(item => {
        const { content_tag } = item
        let commentTags = []
        if (Array.isArray(content_tag)) {
          commentTags = content_tag.filter(tag => tag && tag.name && tag.content)
        }
        item.commentTags = commentTags
      })
      const res = list.filter(item => item.firstImg || item.comment_image?.length)
      return res
    },

  },
  watch: {
    curId() {
      this.$nextTick(() => {
        this.scrollToItem(this.curId)
      })
    }
  },
  mounted() {
    this.updateCurPageForImg(2)
    daEventCenter.triggerNotice({
      daId: '1-6-2-63',
      extraData: {
        goods_num: 1
      }
    })

    this.timer = setTimeout(() => this.scrollToItem(this.curId), 0)
  },
  methods: {
    ...mapActions('newProductDetail', ['showLoginModal']),
    ...mapMutations('newProductDetail/CommentPopup', [
      'updateCurPageForImg',
      'updateShowReviewImgbox',
      'changeCommentDataForLike'
    ]),
    ...mapActions('newProductDetail/CommentPopup', ['fetchCommentInfoForImg']),
    transformImg,
    template,
    // 弹出弹窗
    goBack() {
      // this.isShow = false
    },
    bagClickEvt() {
      daEventCenter.triggerNotice({
        daId: '1-6-2-41',
        extraData: {
          review_location: 'page_review',
          num: this.cartSumQuantity
        }
      })
      location.href = `${langPath}/cart`
    },
    fetchMoreReviews: function () {
      if (this.noMoreCommentFromImg) return
      this.fetchCommentInfoForImg()
    },
    showReviewImg(index, comment) {
      this.imageClickHandle(
        {
          report_id: comment.reportId,
          comment_id: comment.comment_id,
          index: 0 // 因为列表展示的都是第一张
        },
        POSITION
      )
      daEventCenter.triggerNotice({
        daId: '1-6-2-69',
        extraData: {
          review_location: 'page_goods_cusgallery',
          img_list: this.getExposeDataImgList(comment, index)
        }
      })
    },
    handleLike($event, item) {
      // 点击登录弹窗的后退按钮或者浏览器的回退要回到当前页
      if (!isLogin()) {
        this.changeHash('customer-show-l')
      }
      this.likeClickHandle($event, item, POSITION)
    },
    changeHash(value) {
      location.hash = value ? '#' + value : ''
    },
    scrollToItem(commentId) {
      if (commentId === -1 || commentId === '') return
      const scrollTop = this.$refs[`item${commentId}`]?.offsetTop
      const headerHeight = this.$refs.header?.offsetHeight
      const dom = this.$refs.gallery
      dom &&
        dom.scrollTo({
          top: scrollTop - headerHeight,
          behavior: 'smooth'
        })

      if (this.timer) clearTimeout(this.timer)
    },
    getExposeData(item, index) {
      const img_list = this.getExposeDataImgList(item, index)
      const data = {
        id: '1-6-2-64',
        code: 'gallery',
        data: {
          img_list
        }
      }
      return data
    },
    // 获取上报参数img_list
    getExposeDataImgList(item, index) {
      const { type = '', comment_id, reportId = '' } = item
      let eId = ''
      let typeNum = -1
      if (type === 'gallery') {
        typeNum = 1
      } else if (reportId) {
        typeNum = 2
        eId = reportId
      } else {
        typeNum = 3
        eId = comment_id
      }
      return `${eId}\`${typeNum}\`${index + 1}`
    }
  }
}
</script>

<style>
.gallery .S-drawer__body {
  background-color: #f6f6f6;
}
</style>
<style lang="less" scoped>
.mshe-header-fixed {
  position: absolute;
  top: 0;
  width: 10rem;
  margin: 0 auto;
  z-index: @zindex-transform3;
}

.gallery {
  font-family: 'SF Pro';

  &_body {
    overflow: auto;
    height: 100%;
  }
  &_list {
    // background: #f6f6f6;
    padding-top: 1.15rem;

    .water-fall {
      display: flex;
      width: 100%;
      padding: 0.16rem;
      align-items: flex-start;
      align-content: flex-start;
      gap: 0.21333rem 0.16rem;
      flex-wrap: wrap;
    }
  }

  &_list-item {
    vertical-align: top;
    width: 4.76rem;
    background: #fff;
    margin-bottom: 0.16rem;
    border-radius: 0.10667rem;
  }

  &_list-imgbox {
    img {
      width: 100%;
    }
  }

  &_list-con {
    padding: 0.16rem 0.21rem;

    .gals {
      color: var(---sui_color_gray_dark2, #666);
      font-size: 0.29333rem;

      span {
        padding-right: 0.05rem;
      }
    }

    .comment-info {
      h3 {
        color: #000;
        font-size: 0.32rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        .tag-name {
          color: @sui_color_gray_dark2;
        }
      }
    }

    .comment-bottom {
      // display: flex;
      // justify-content: space-between;
      padding-top: 0.11rem;

      &:after {
        content: '';
        display: block;
        clear: both;
      }

      .left {
        float: left;
      }

      .report {
        height: 0.32rem;
        // font-size: 0.26667rem;
        .font-dpr(20px);
        display: inline-flex;
        align-items: center;
        padding: 0.026667rem 0.106667rem;
        border: 1px solid #67A58B;
        color: #67A58B;
        margin-left: 0.11rem;
      }

      .right {
        float: right;
        line-height: normal;
        display: flex;
        align-items: center;
        color: #767676;
      }

      .suiiconfont {
        font-size: 0.48rem;
        color: #222222;
        padding-right: 0.11rem;
      }

      .sui_icon_like_completed_20px {
        color: #c44a01;
      }

      span {
        font-size: 0.29333rem;
        font-weight: 400;
        
        color: #666;
      }
    }
  }
}

// .water-fall__new {
//   width: 100%;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
// }
// .recommend__goods-item {
//   vertical-align: top;
//   font-size: 12px;
// }
</style>
