import { getExposeBoxData } from './utils'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import {
  getBundlePrice,
  getCalcBundlePrice,
  getFormatCommonCartProductItem,
  setCommonCartProductSkuInfo,
} from '../CommonCart/utils'
import {
  getComboBuyPopupProductListByGoods,
} from './utils'
import { getImageRatio } from 'public/src/pages/goods_detail/utils/common.js'
import { mapGetters, mapActions, mapState } from 'vuex'

/**
 * 套装价计算状态管理
 */
export const getCombobuyMixins = (options = {}) => {
  const { isPopup } = options

  return {
    data() {
      return {
        loadingPrice: false,
        bundlePrice: {},
        products: []
      }
    },
    computed: {
      ...mapState('newProductDetail/common', [
        'skuInfo'
      ]),
      ...mapGetters('newProductDetail', ['fsAbt']), // 新数据流
      ...mapGetters('newProductDetail/common', [
        'isShowNewComboBuyStyleAbt',
        'goodsId',
      ]),
      // 盒子属性
      cRenderBindData() {
        return getExposeBoxData({
          isPopup,
          poskey: 'buytogether'
        })
      },
      cSelectItems() {
        return this.products.filter(d => d._cartInfo.selectStatus)
      },
      cIsOnlySelectMainGoods() {
        const { cSelectItems, goodsId, skuInfo } = this

        if (cSelectItems?.length !== 1) return false
        const _item = cSelectItems[0]
        if (!_item._cartInfo.isMainGoods || _item.goods_id !== goodsId) return false
        // 如果当前选择的sku和商详不一致，不走主商品价格
        if (skuInfo.sku_code != _item._cartInfo.skuCode) return false

        return true
      },
      cBundlePrice() {
        const {
          language,
          bundlePrice,
          isPaidUser,
          cIsOnlySelectMainGoods,
          cMainGoodsPrice
        } = this
        const price = cIsOnlySelectMainGoods
          ? cMainGoodsPrice
          : getCalcBundlePrice({ bundlePrice, language, config: { isPaidUser } })

        return price
      }
    },
    watch: {
      cSelectItems: {
        async handler(list = [], oldList = []) {
          const { cIsOnlySelectMainGoods, isShowNewComboBuyStyleAbt } = this
          if (!list.length || cIsOnlySelectMainGoods) {
            this.bundlePrice = {}
            return
          }

          this.loadingPrice = true
          try {
            // 计算套装价，当只有一个商品且为主商品时直接使用商详数据
            const res = await getBundlePrice({
              list,
              onlySkc: isShowNewComboBuyStyleAbt || isPopup ? false : true
            })
            this.loadingPrice = false
            this.bundlePrice = res
          } catch (e) {
            const oldSelectGoodIds = oldList
              .filter(d => d._cartInfo.selectStatus)
              .map(d => d.goods_id)
            // 计算套装价失败，恢复勾选
            this.products.forEach(item => {
              item._cartInfo.selectStatus = oldSelectGoodIds.includes(
                item.goods_id
              )
            })
          }

          this.loadingPrice = false

        },
        immediate: true
      }
    },
    methods: {
      ...mapActions('newProductDetail/common', [
        'setGoodsDetailScroll'
      ]),
      ...mapActions('newProductDetail', [
        'updateEstimatedCouponList',
        'openDetailRecommendDrawer'
      ]),
      onSelectChange({ item, selected, target } = {}) {
        if (this.loadingPrice) return

        // 售罄状态不能选中
        if (item._cartInfo.isSoldOut) return

        if (target) {
          daEventCenter.triggerNotice({
            daId: '2-3-39',
            target,
            extraData: {
              click_type: selected ? 'tick' : 'tick_cancel'
            }
          })
        }

        item._cartInfo.selectStatus = selected
        // 如果取消勾选，需要移除警告信息
        if (!selected && item._cartInfo.warnStatus) {
          item._cartInfo.warnStatus = false
        }
      },
      async baseSelectSizeClick(options = {}) {
        const { goodsId } = this
        const { item, index, cItemConfig, onAfterUpdateFn } = options

        const { goods_id, goods_img, mall_code } = item
        const imgRatio = await getImageRatio(goods_img)

        // 只有主商品显示到手价
        const showEstimatedPrice = !!item?._cartInfo?.isMainGoods && goodsId === item.goods_id && !this.isHitComplianceMode

        this.$quickAdd.open({
          goods_id,
          index,
          imgRatio,
          selectedAttrIndex: item._cartInfo.selectedAttrIndex,
          mallCode: mall_code,
          isShowSelectUpdate: true, //  进入销售属性选择模式
          isShowWishBtn: true,
          showSimilarBtn: false, // 不显示相似推荐按钮
          hideQtySelect: true, // 不显示数量选择
          showEstimatedPrice,
          showAbPrice: true, // 展示ab价
          hideFindSimilarFloat: true, // 隐藏相似推荐
          // item_id: this.item.id,
          // quantity: this.item.quantity,
          clickCallBack: {
            // edit: this.editComplete,
            selectUpdateHook: async ({ state, next }) => {
              const { item: quickAddItem, attrs } = state
              const { skuCode } = attrs

              const _isUptSkcOrSku = item.goods_id !== quickAddItem.goods_id || skuCode !== item._cartInfo.skuCode

              let _item = null
              if (_isUptSkcOrSku) {
                const _reqItem = skuCode ? {
                  ...quickAddItem,
                  _cartInfo: {
                    skuCode,
                  }
                } : quickAddItem

                const goods = await getComboBuyPopupProductListByGoods({
                  goods: [_reqItem],
                  itemConfig: cItemConfig,
                  rows: isPopup ? 1 : 3,
                })

                /**
                 * 新样式且弹窗内需要把切换的skc同步到主商品
                 */

                _item = getFormatCommonCartProductItem(goods[0])

                // 同步主商品
                if (item._cartInfo.isMainGoods) _item._cartInfo.isMainGoods = true
              } else {
                _item = item
              }
              const uptSkuItem = setCommonCartProductSkuInfo(skuCode, _item)

              next()
              if (!uptSkuItem) return
              // 自动勾选商品
              uptSkuItem._cartInfo.selectStatus = !uptSkuItem._cartInfo.isSoldOut
              uptSkuItem._cartInfo.warnStatus = false

              onAfterUpdateFn && onAfterUpdateFn(uptSkuItem)
              // this.products.splice(index, 1, uptSkuItem)
  
              // this.$nextTick(() => {
              //   // 切换更新埋点信息
              //   this.$refs[`item_${index}`]?.setElAttr()
              // })
            }
          }
        })
      },
      async baseAfterAddToCart({ ignoreSelectors = [] } = {}) {
        const { openDetailRecommendDrawer, fsAbt, setGoodsDetailScroll } = this
        // 走加车后推荐逻辑
        setTimeout(() => {
          if (fsAbt?.addbagpopup?.param?.addbagpopup === 'show') {
            openDetailRecommendDrawer({
              name: 'addCart'
            }).then(status => {
              if (!status) {               
                setGoodsDetailScroll({ ignoreSelectors })
              }
            })
          } else {
            setGoodsDetailScroll({ ignoreSelectors })
          }
        }, 500)
      }
    }
  }
}
