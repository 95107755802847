<template>
  <div 
    class="goods-review__guidance-wrapper"
    :style="{
      'max-width': !haveDividingLine?'5.00366rem':'3.73333rem',
      'margin-left': !haveDividingLine?'0.106666rem':'0px',
      'margin-top': haveDividingLine?'0.106666rem':'0px'
    }"
  >
    <span
      class="goods-review__guidance"
      @click="goToGuidance"
    >
      <span class="goods-review__guidance-text">{{ language.SHEIN_KEY_PWA_30986 || 'Richtlinie für Kundenbewertungen' }}</span>
      <i class="suiiconfont sui_icon_doubt_12px_2"></i>
    </span>
  </div>
</template>

<script>
const { langPath } = gbCommonInfo
export default {
  name: 'CommentDoubt',
  props: {
    language: {
      type: Object,
      default() {
        return {}
      },
    },
    haveDividingLine: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goToGuidance() {
      window.location.href =  `${langPath}/Review-Guidance-a-478.html?lang=eur`
    },
  }
}
</script>

<style lang="less" scoped>
.goods-review {
  &__guidance-wrapper{
    display: inline-block;
    font-weight: 400;
    flex:1;
    flex-grow:1;
    color:#666666;
    .suiiconfont {
      display: inline-block;
      margin-right: 4px;
    }
  }
  &__guidance {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &__guidance-text {
    color: #666;
    font-size: 0.32rem;
    line-height: 0.42666rem;
    margin-right: 0.10666rem;
    flex:1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
