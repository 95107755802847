<script lang="jsx">
import { mapState, mapGetters, mapMutations } from 'vuex'
import BigPicSlidePage from 'public/src/pages/goods_detail/components/bigPicSlidePage.vue'
import GBDetailAnalysisEvent from 'public/src/pages/goods_detail/analysis/ga.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { transformImg } from '@shein/common-function'
const { WEB_CLIENT, LAZY_IMG } = gbCommonInfo
daEventCenter.addSubscriber({ modulecode: '1-6-3' })

export default {
  name: 'StyleGallery',
  components: {
    BigPicSlidePage
  },
  props: {
    styleMergeGalleyAndReviews: {
      type: Boolean
    }
  },
  data () {
    return {
      shoplookIndex: 0,
      WEB_CLIENT,
      LAZY_IMG,
    }
  },
  computed: {
    ...mapState('newProductDetail/common', [
      'shoplookLightbox',
    ]),
    ...mapGetters('newProductDetail/common', ['lookbookInfo', 'language']),
  },
  watch: {
    lookbookInfo: {
      handler (newVal, oldVal) {
        if (oldVal?.data?.length === 0 || !oldVal) {
          this.exposeStyleGallery = GBDetailAnalysisEvent.exposeStyleGallery()
        }
      }
    }
  },
  destroyed() {
    this.exposeStyleGallery?.destroyInstance()
  },
  methods: {
    ...mapMutations('newProductDetail/common', [
      'updateShoplookLightbox',
    ]),
    openShoplook(index) {
      this.shoplookIndex = index
      this.updateShoplookLightbox(true)
    },
    transformImg
  },
  render () {
    let { lookbookInfo, language } = this
    return (
      <div class="styleGallery view-category j-sa-view-category style-gallery-con j-expose__style-gallery-container">
        <div class="j-expose__style-gallery-con">
          <div class="styleGallery__title">
            { WEB_CLIENT == 'shein' ? language.SHEIN_KEY_PWA_15221 : language.SHEIN_KEY_PWA_16806 } ({ lookbookInfo.length })
          </div>
          <div
            class={[
              'styleGallery__swiper', 'j-expose__style-gallery-target',
              { 'style-merge-galleyandreviews': this.styleMergeGalleyAndReviews }
            ]}
            data-num={lookbookInfo.length}
          >
            <div class="s-swiper-wrapper">
              {lookbookInfo.length ?
                (
                  lookbookInfo.map((item, index) => {
                    return (
                      <a
                        href="javascript:"
                        class="s-swiper-slide"
                      >
                        <img
                          data-src={transformImg({ img: item.img })}
                          src={LAZY_IMG}
                          data-design-width="220"
                          class="lazy j-push-history-hash lazyload"
                          state="lookbook"
                          da-event-click="1-6-3-4"
                          data-image-url={item.img}
                          data-index={index + 1}
                          onClick={() => this.openShoplook(index, item)}
                        />
                      </a>
                    )
                  })
                ) : null
              }       
            </div>
          </div>
        </div>
        <BigPicSlidePage
          value={this.shoplookLightbox}
          vOn:input={v => {
            this.updateShoplookLightbox(v)
          }}
          images={lookbookInfo}
          index={this.shoplookIndex}
          total={lookbookInfo.length}
          analysis={{ next: '1-6-3-1', prev: '1-6-3-2' }}
        />
      </div>
    )
  }
}
</script>


<style lang="less">
.styleGallery {
  margin-top: .22rem;
  .styleGallery__swiper {
    background: #fff;
    padding: .16rem .32rem;
    .s-swiper-wrapper {
      overflow-x: scroll;
      .flexbox();
    }

    &.style-merge-galleyandreviews {
      padding: .16rem .32rem 0 .32rem;
    }
    a {
      .flexbox();
      width: 2.8rem;
      height: 3.84rem;
      .margin-l(.107rem);
      &:first-child {
        margin-left: 0;
      }
    }
    img {
        width: 2.8rem;
        height: 3.84rem;
    }
  }
  &__title {
    margin: .215rem 0 0;
    line-height: .51rem;
    padding: .268rem .32rem 0;
    background: #fff;
    font-weight: bold;
    .font-dpr(28px);
    /* rw:begin */
    font-family: 'Adieu';
  }
}
.style-gallery-con {
  // padding-bottom: .2145rem;
}
.view-category {
  .nav-container {
      background: #fff;
      padding: .32rem 0;
      a {
          .flexbox();
          width: 2.72rem;
          height: 3.84rem;
          .margin-l(.22rem);
      }
      img {
          width: 2.72rem;
          height: 3.84rem;
      }
  }
}

.mgds-goodsd-bg .view-category {
  margin-top: .2145rem;
  .category-t {
      margin: .22rem 0 0;
      line-height: .51rem;
      padding: .32rem .32rem .05rem;
      background: #fff;
      font-weight: bold;
      .font-dpr(28px);
  }
}
</style>
