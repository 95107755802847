<template>
  <!-- TODO 目录要迁移 -->
  <div
    class="goods-material__container j-sa-details-material"
  >
    <div class="goods-material__title">
      {{ language.SHEIN_KEY_PWA_18044 }}
    </div>
    <template v-for="item in pageComponents.materialConf.materialList">
      <div
        v-if="item"
        :key="item.name"
        class="goods-material__li"
      >
        <span class="goods-material__key">
          {{ item.name }}:
        </span>
        <span class="goods-material__val">
          {{ item.value }}
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'GoodsMaterial',
  computed: {
    ...mapGetters('newProductDetail/common', ['language']),
    ...mapGetters('newProductDetail/MiddleAttr', ['pageComponents']),
  },
}
</script>

<style lang="less">
.goods-material {
  &__container {
    padding: 0.2667rem 0 0.32rem 0;
    background-color: #fff;
  }
  &__title {
    margin-bottom: .37rem;
    .font-dpr(28px);
    font-weight: bold;
    line-height: 1.36;
  }
  &__li {
    margin-bottom: .16rem;
    line-height: 1.2;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__val {
    color: #666;
  }
}
</style>
