<template>
  <div
    :class="[className, 'shop-entry__storeFollowBtnBox']"
    @click.stop="onClick"
  >
    <slot name="button"></slot>

    <!-- follow success popup dialog -->
    <SDialog
      :visible.sync="popupShow"
      show-close
      append-to-body
    >
      <template slot="title">
        {{ language?.followTitleLang || langSource.SHEIN_KEY_PWA_24070 }}
      </template>
      <div class="shop-entry__storeFollowTipsContentBox">
        <p>
          {{ language?.followDescriptionLang || langSource.SHEIN_KEY_PWA_24071 }}
        </p>
        <div
          class="shop-entry__imgbox"
          :class="{
            'rotate-x': GB_cssRight
          }"
        >
          <img
            :src="`${PUBLIC_CDN}/pwa_dist/images/store/follow-store-tips-cover_2x-add595a718.png`"
          />
          <div class="shop-entry__followbox">
            <Icon
              name="sui_icon_me_following_24px"
              size=".64rem"
              :is-rotate="GB_cssRight"
            />
            <div>{{ language?.followingLang || langSource.SHEIN_KEY_PWA_24072 }}</div>
          </div>
          <div class="shop-entry__mebox">
            {{ language?.meLang || langSource.SHEIN_KEY_PWA_16036 }}
          </div>
        </div>
       
        <div class="shop-entry__modalBtnBox">
          <SButton
            height="36px"
            width="100%"
            :type="['primary']"
            @click="() => popupShow = false"
          >
            {{ language?.okLang || langSource.SHEIN_KEY_PWA_24059 }}
          </SButton>
        </div>
      </div>
    </SDialog>
  </div>
</template>

<script>
/**
 * @component StoreFollowBtnBox 店铺关注按钮外壳
 * * 处理店铺关注和取消关注逻辑，无vuex依赖
 */
import schttp from 'public/src/services/schttp'
import {
  Dialog as SDialog,
  Button as SButton,
  Toast as $toast,
} from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'

const { language: commonLang, PUBLIC_CDN, GB_cssRight } = typeof window !== 'undefined' ? gbCommonInfo : {}
// 场景对应的key
const SCENE_KEY_MAP = new Map([
  ['StoreDetail', 1],
  ['ProductDetail', 2],
])
// 是否展示过关注成功弹窗的key
const BEEN_POPUP_SHOW_KEY = 'beenPopupStoreFollowSuccess'

export default {
  name: 'StoreFollowBtnBox',
  components: {
    SDialog,
    SButton,
    Icon,
  },
  props: {
    // 盒子类名
    className: {
      type: String,
      default: '',
    },
    // 场景
    // 店铺页: StoreDetail 商品详情页: ProductDetail
    scene: {
      type: String,
      default: '',
    },
    // 店铺关注状态
    followState: {
      type: Boolean,
      default: false,
    },
    // 店铺id
    storeId: {
      type: [String, Number],
      default: '',
    },
    // 未登录时是否自动登录
    autoLogin: {
      type: Boolean,
      default: false,
    },
    // 多语言
    language: {
      type: Object,
      default: () => ({}),
    },
    showLoginModal: {
      type: Function,
      default: null,
    }
  },
  data() {
    return {
      loading: false,
      popupShow: false,
      beenPopupShow: false,
      PUBLIC_CDN,
      GB_cssRight
    }
  },
  computed: {
    langSource() {
      const { language } = this
      return language || commonLang || {}
    },
    // 点击关注按钮时的请求接口
    changeStateApi() {
      const { followState } = this
      return followState
        ? '/api/store/batchChangeFollowState/create'
        : '/api/store/followStore/create'
    },
    // 点击关注按钮时的接口请求参数
    changeStateParams() {
      const { followState, storeId, scene } = this
      // 关注
      if (!followState) return {
        storeId: storeId,
        scene: SCENE_KEY_MAP.get(scene),
      }
      // 取关
      return {
        storeIdSet: [storeId]
      }
    },
  },
  mounted() {
    this.updateBeenPopupShow()
  },
  methods: {
    // 点击关注按钮
    async onClick() {
      const {
        loading,
        storeId,
        followState,
        changeStateApi,
        changeStateParams,
        updateBeenPopupShow,
        autoLogin,
        langSource,
      } = this
      if (loading || !storeId) return

      this.loading = true
      this.$emit('state-changing', true)
      const targetState = !followState
      try {
        const result = await schttp({
          url: changeStateApi,
          method: 'post',
          data: changeStateParams,
        })
        const { code, info } = result
        if (code === '0' && (info?.result === 1)) {
          this.$emit('state-changed', targetState)
          // 首次关注成功，则弹出关注成功弹窗
          updateBeenPopupShow()
          if (targetState && !this.beenPopupShow) {
            this.popupShow = true
            this.beenPopupShow = true
            window.localStorage?.setItem(BEEN_POPUP_SHOW_KEY, '1')
          }
        }  else if (String(code) === '401') {
          // 未登录
          if (
            autoLogin
            && typeof window !== 'undefined'
            && typeof SHEIN_LOGIN !== 'undefined'
          ) {
            const loginCallback = async state => {
              if (String(state?.code) === '0') {
                await this.onClick()
              }
            }
            if (typeof window !== 'undefined' && window?.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD && this.showLoginModal && window?.gbCommonInfo?.WEB_CLIENT === 'shein') {
              this.showLoginModal({
                params: {
                  bi: 'other',
                },
                cb: loginCallback,
              })
            }else {
              SHEIN_LOGIN.show({
                show: true,
                bi: 'other',
                cb: loginCallback,
              })
            }
          } else {
            this.$emit('need-login')
          }
        } else {
          throw result
        }
      } catch (err) {
        const toastContent = targetState
          ? (String(err?.code) === '400429'
            ? langSource.SHEIN_KEY_PWA_24077 // 关注失败，关注数量超出上限
            : langSource.SHEIN_KEY_PWA_24076) // 关注失败，请稍后重试
          : langSource.SHEIN_KEY_PWA_24075 // 取消关注失败，请稍后重试
        this.$nextTick(() => {
          $toast({
            content: toastContent
          })
        })
        this.$emit('state-change-fail', err)
      }
      this.loading = false
      this.$emit('state-changing', false)
    },
    // 更新是否展示过关注成功弹窗状态
    updateBeenPopupShow () {
      if (typeof window === 'undefined') return
      this.beenPopupShow = !!window.localStorage?.getItem(BEEN_POPUP_SHOW_KEY)
    }
  },
  emits: [
    'state-changed',
    'state-changing',
    'need-login',
    'state-change-fail'
  ],
}
</script>

<style lang="less">
.shop-entry__storeFollowBtnBox {
  display: block;
}

.shop-entry__storeFollowTipsContentBox {
  width: 100%;
  text-align: center;

  p {
    margin-top: .32rem;
    color: @sui_color_gray_dark1;
    .font-dpr(28px);
  }

  img {
    display: block;
    width: 100%;
    margin-top: .32rem;
  }

  .shop-entry__modalBtnBox {
    margin-top: .746667rem;
    margin-bottom: .533333rem;
    justify-content: center;
  }

  .shop-entry__imgbox {
    position: relative;
    background: url('/pwa_dist/images/store/follow-store-tips-bg_2x-183364c421.png') no-repeat center;
    background-size: 100%;
    &.rotate-x {
      /* rtl:begin:ignore */
      transform: scaleX(-1);
      /* rtl:end:ignore */
    }
  }

  .shop-entry__followbox {
    position: absolute;
    /* rtl:begin:ignore */
    top: 28/37.5rem;
    right: (51.123/2+4.94)/37.5rem;
    transform: translateX(50%);
     /* rtl:end:ignore */
    font-size: 7px;
  }

  .shop-entry__mebox {
    position: absolute;
    /* rtl:begin:ignore */
    right: (32/2+13)/37.5rem;
    transform: translateX(50%);
    bottom: 19/37.5rem;
    /* rtl:end:ignore */
    font-size: 7px;
  }
}

.rotate-x {
  .shop-entry__followbox {
    div {
      /* rtl:begin:ignore */
      transform: scaleX(-1);
      /* rtl:end:ignore */
    }
  }
  .shop-entry__mebox {
    /* rtl:begin:ignore */
    transform: scaleX(-1);
    /* rtl:end:ignore */
  }
}
</style>
