<template>
  <section
    v-if="list.length > 0"
    class="get-the-look"
    data-floor-tab="getTheLook"
  >
    <GetTheLookA
      pos-key="GetTheLook"
      :inside-drawer="insideDrawer"
      :list="list"
      @throwQuickAddStatus="throwQuickAddStatus"
    />
  </section>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
// import RecommendDataService from 'public/src/pages/goods_detail/components/Recommend/service/index.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
// import { abtservice } from 'public/src/services/abt'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import itemSer from 'public/src/services/goodsItemInfo/interfaceSer'

export default {
  name: 'GetTheLook',
  components: {
    GetTheLookA: () => import('./GetTheLookA')
  },
  props: {
    // 在弹窗内，展示tab完全展开的样式
    insideDrawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showNew: null,
      list: [],
      // recommendDataService: new RecommendDataService(),
    }
  },
  computed: {
    // 'gtlOpen', 
    // ...mapState('productDetail', ['outSiteGoodsDetail']),
    ...mapState('newProductDetail', ['getTheLookPdeInfo']), // 新
    ...mapGetters('newProductDetail', ['unifiedRecommend']),
    ...mapState('newProductDetail/common', ['detailFetchMoreInfoStatus', 'isOutSite']),
    // ...mapGetters('newProductDetail', ['unifiedRecommend']),
    ...mapGetters('newProductDetail/common', ['getTheLookInfoData', 'detail']),
  },
  watch: {
    detailFetchMoreInfoStatus: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.showNew = null
          if (this.isOutSite) return this.showNew = false
          this.list = []
          // this.init()
          this.initNew()
        }
      }
    },
    getTheLookPdeInfo: {
      handler(newVal) {
        if (newVal) {
          this.setLookData()
        }
      }
    },
    showNew: {
      immediate: true,
      handler (val) {
        if (val === false) {
          // 走默认的详情getthelook展示
          this.setLookData()
        }
        
        if (val !== null) {
          this.$nextTick(() => {
            this.$emit('lazyLoaded')
          })
        }
      }
    }
  },
  methods: {
    ...mapMutations('newProductDetail/common', ['updateGetTheLookList']),
    async init() {
      /**
       * 1. 请求abt
       */
      const { GetTheLook: abtInfo, GetTheLookJson, recSwitch } = await getUserAbtData()
      const recSwitch103 = !!recSwitch?.p?.recSwitch?.split('|')?.includes('103')
      // const abtInfo = {
      //   param: 'rule_id_103=rec_ver:S103V1.0'
      // }
      if (!/^rule_id_/.test(abtInfo.param)) return this.showNew = false

      // tsp请求过滤判断
      const flag = await this.tspFilterResult()
      if (!flag) return this.showNew = false

      const abtMatched = abtInfo.param.match(/rule_id_(\d+)=(.*)/)
      const sceneId = abtMatched && abtMatched[1]
      /**
       * 2. 推荐类型
       */
      if (this.unifiedRecommend?.gtlNotShowRecDataByAbt) {
        // 命中不展示gtl中推荐数据则不请求
        return this.showNew = false
      }
      const { goods_sn: sku, goods_id, cat_id: cate_id } = this.detail
      const request = {
        type: 'requestForGroup',
        url: '/api/recommend/facade/get',
        params: {
          scene_id: sceneId,
          pageEnable: 0,
          // rule_id: abtMatched && abtMatched[2],
          cate_id,
          ...(Number(sceneId) === 103 ? { goods_id } : { sku })
        },
        dataType: Number(sceneId) === 103 ? 'own' : 'syte'
      }
      if (recSwitch103) {
        request.params.rule_id = abtMatched && abtMatched[2]
        request.params.location = 'GetTheLook'
      } else {
        request.params.jsonRuleId = JSON.stringify(GetTheLookJson?.p)
        request.params.location = 'GetTheLookJson'
      }
      /**
       * 3. 请求推荐
       */
      const { recommendInfo = {} } = await this.recommendDataService.fetchRecommendData({ request })
      this.handleRecResult(recommendInfo, Number(sceneId))
    },
    async initNew () {
      const { nums = 0 } = this.getTheLookPdeInfo
      if (nums == 0) return this.showNew = false
      // 3.1 结果数上报
      daEventCenter.reportHandler({
        sa: {
          name: 'expose_get_the_look_result',
          param: {
            result_count: Number(this.getTheLookPdeInfo?.nums) || 0
          }
        }
      })
      this.showNew = true
      this.setLookData()
    },
    /**
     * 推荐结果的处理
     */
    async handleRecResult(recommendInfo, sceneId) {
      const { products = [], responseInfo, dataType } = recommendInfo
      const { goods_sn, goods_img, goods_id } = this.detail
      // 3.1 结果数上报
      daEventCenter.reportHandler({
        sa: {
          name: 'expose_get_the_look_result',
          param: {
            result_count: Number(products.length) || 0
          }
        }
      })
      // 3.2 推荐返回搭配数量小于2 不展示
      if (products.length < 2) return this.showNew = false
      this.showNew = true
      const extra_data = responseInfo?.info?.extra_data
      let series_img = ''
      if (Array.isArray(extra_data)) {
        const r = extra_data.find(i => {
          return sceneId === 103 ? i.goodsId == goods_id : i.sku == goods_sn
        })
        series_img = r && r.ctl_image_url || ''
      }

      await itemSer.getAtomicInfo({
        goods: products,
        fields: { stock: 1 },
      })
      this.setLookData({
        series_img: series_img || goods_img,
        related_goods: products,
        dataType
      })
    },

    /**
     * 设置展示的数据
     */
    setLookData () {
      let lookList = this.getTheLookInfoData.length ? this.getTheLookInfoData.map((item) => {
        if(item.dataType) return item
        item['dataType'] = 'operating'
        return item
      }) : []
      const { nums, pdeData } = this.getTheLookPdeInfo
      const onlyPde = lookList.length === 0 && nums
      if (onlyPde) {
        lookList = [pdeData]
      }
      if (!lookList.length) return

      this.list = lookList
      // 存储所有get the look的搭配推荐，用于过滤
      this.updateGetTheLookList(this.list.reduce((acc, cur) => [...acc, ...cur.related_goods], []))
    },

    async tspFilterResult() {
      const { goods_id } = this.detail
      const result = await itemSer.getAtomicInfo({
        goods: [{ goods_id: goods_id }],
        fields: { tspLabels: 'tag' },
        needAssign: false,
      })
      if (!result?.[goods_id]?.tspLabels?.tag) return false
      // 有 60002672 不请求
      return !(result[goods_id].tspLabels.tag.indexOf('60002672') > -1)
    },
    throwQuickAddStatus(status) {
      this.$emit('throwQuickAddStatus', status)
    }
  },
}
</script>
