<script lang="jsx">
import { daEventCenter } from 'public/src/services/eventCenter'
import TrendsLabel from 'public/src/pages/goods_detail/components/TrendsLabel'

const { IS_RW } = gbCommonInfo
export default {
  name: 'DescriptionEnter',
  functional: true,
  props: {
    detailsArr: {
      type: Array,
      default: () => []
    },
    newDetailsArr: {
      type: Array,
      default: () => []
    },
    language: {
      type: Object,
      default: () => {}
    },
    pageComponents: {
      type: Object,
      default: () => {}
    },
    fsAbt: {
      type: Object,
      default: () => {}
    },
    sellingPoints: {
      type: Array,
      default: () => []
    },
    boxStyle: {
      type: Object,
      default: () => {}
    },
    openDescriptionPopFn: {
      type: Function,
      default: () => {}
    },
    cccDescAttrList: {
      type: Array,
      default: () => [],
    },
    trendsInfo: {
      type: Object,
      default: undefined
    },
    trendsImg: {
      type: String,
      default: ''
    },
    goodsId: {
      type: [Number, String],
      default: '',
    }
  },
  render(h, { props }) {
    const { detailsArr, newDetailsArr, language, pageComponents, sellingPoints, openDescriptionPopFn, boxStyle, cccDescAttrList, trendsInfo, trendsImg, goodsId } = props
    const genTrendsInfoPoint = (domAttr = false) => {
      return trendsInfo ? { 
        [`${domAttr ? 'data-' : ''}is_trend`]: trendsInfo?.display_title ? 1 : 0,
        [`${domAttr ? 'data-' : ''}trend_word_id`]: trendsInfo?.trend_word_id,
        [`${domAttr ? 'data-' : ''}goods_id`]: goodsId
      } : {}
    }
    const analysisGoodsDetailDesc = {
      from: 1,
      Sellpoint: sellingPoints.length ? 1 : 0,
      ...genTrendsInfoPoint()
    }
    // const abtSellPointA = fsAbt?.SellPoint?.p?.SellPoint === 'A'
    // const symbol = abtSellPointA ? '•' : '#'
    const symbol = '•'

    const clickSellingPoint = (curSellingPoint) => {
      const { tag_id, tag_val_id, tag_val_name_en } = curSellingPoint
      daEventCenter.triggerNotice({
        daId: '1-6-1-38',
        extraData: {
          tag_id,
          tag_val_id,
          tag_val_name_en
        }
      })
    }
    const openDescriotion = () => {
      daEventCenter.triggerNotice({
        daId: '1-6-4-15',
        extraData: analysisGoodsDetailDesc
      })
      // 加载并展开 description 弹窗
      openDescriptionPopFn()
    }
    // 曝光埋点 desc-title-description
    return <div style={boxStyle}>
      <div
        {...{ directives: [{ name: 'ada', value: { level: 0, pos: 2 } }] }}
        class="description-enter desc-title-description j-push-history-hash j-ga-description"
        data-from={analysisGoodsDetailDesc.from}
        data-sell-point={analysisGoodsDetailDesc.Sellpoint}
        state="description"
        role="text"
        aria-label={language?.SHEIN_KEY_PWA_14986}
        vOn:click={openDescriotion}
        {...{ attrs: genTrendsInfoPoint(true) }}
      >
        <div
          class="description-enter__title"
          aria-hidden="true"
        >
          { pageComponents?.descriptionConf?.descriptionName }
        </div>
        <div class="description-enter__desc-wrap">
          {cccDescAttrList?.length ?
            <div
              class="description-enter__desc"
              aria-hidden="true"
            >
              {/* 卖点-S */}
              {sellingPoints.map(item => {
                return <span
                  key={item.tag_val_id}
                  class='description-enter__desc-brown'
                  vOn:click={() => clickSellingPoint(item)}
                >
                  {symbol}&nbsp;{item.tag_val_name_lang}&nbsp;
                </span>
              })}
              {cccDescAttrList.map((item, index) => {
                return <span key={index}>
                  {symbol}&nbsp;{item?.value || item?.attr_value || ''}&nbsp;
                </span>
              })}
            </div>
            : null}
          {!cccDescAttrList?.length && newDetailsArr?.length ?
            <div
              class="description-enter__desc"
              aria-hidden="true"
            >
              {/* 卖点-S */}
              {sellingPoints.map(item => {
                return <span
                  key={item.tag_val_id}
                  class='description-enter__desc-brown'
                  vOn:click={() => clickSellingPoint(item)}
                >
                  {symbol}&nbsp;{item.tag_val_name_lang}&nbsp;
                </span>
              })}
              {/* 卖点-E */}
              {newDetailsArr.map((item, index) => {
                return <span key={index}>{symbol}&nbsp;{item.attr_name_val}&nbsp;</span>
              })}
            </div>
            : null}
          {!cccDescAttrList?.length && !newDetailsArr?.length ? 
            <div 
              class="description-enter__desc"
              aria-hidden="true"
            >
              {/* 卖点-S */}
              {sellingPoints.map((item, index) => {
                return <span
                  key={index}
                  class='description-enter__desc-brown'
                  vOn:click={() => clickSellingPoint(item)}
                >
                  {symbol}&nbsp;{item.tag_val_name_lang}&nbsp;
                </span>
              })}
              {/* 卖点-E */}
              {detailsArr?.[0] ? <span>{symbol}&nbsp;{detailsArr[0]}&nbsp;</span> : null}
              {detailsArr?.[1] ? <span>{symbol}&nbsp;{detailsArr[1]}&nbsp;</span> : null}
            </div> 
            : null}

          <TrendsLabel 
            language={language} 
            trendsImg={trendsImg} 
            trendsInfo={trendsInfo}
          />
          
          <i
            aria-hidden="true"
            class={['suiiconfont', IS_RW ? 'sui_icon_more_right_16px' : 'sui_icon_more_right2_16px']}
          ></i>
        </div>
      </div>
    </div>
  }
}
</script>

<style lang="less">
.description-enter {
  .flexbox();
  justify-content: space-between;
  position: relative;
  height: 1.12rem;
  line-height: 1.12rem;
  text-transform: capitalize;
  .font-dpr(28px);
  width: 100%;
  text-align: left/*rtl:ignore*/;
  &__title {
    .font-dpr(28px);
    .padding-r(.64rem);
    white-space: nowrap;
    /* rw:begin */
    font-family: 'Adieu';
  }
  &__desc-wrap {
    overflow: hidden;
    padding-right: .54rem;
    display: flex;
    align-items: center;
    .trends-label-wrap {
      margin-left: 4px;
      &__trends-desc {
        max-width: 78px;
      }
    }
  }
  &__desc {
    .text-overflow();
    color: #999;
    font-size: .2933rem;
  }
  &__desc-brown {
    color: #A86104;
  }
  .sui_icon_more_right_16px, .sui_icon_more_right2_16px {
    position: absolute;
    top: 0;
    color:#959595;
    .right(0);
  }
}
</style>
