<template>
  <div
    ref="attrBox"
    class="goods-attr"
    :class="{more: attrMore || attrMore === null}"
  >
    <div
      class="j-sa-detailfpos"
      da-event-click="1-6-4-36"
      data-position="1"
      :data-status="content.showModule == 0 ? 'close' : 'open'"
    >
      <div
        v-if="content.showModule == 0"
        aria-hidden="true"
      >
        <div
          class="goods-attr__opos j-push-history-hash"
          state="detailFPos"
          @click="openDetail"
        >
          {{ content.moduleTitleName }}
          <i class="suiiconfont sui_icon_more_right2_16px"></i>
        </div>
      </div>
      <div v-else>
        <div class="goods-attr__stitle">
          {{ content.moduleTitleName }}
        </div>
        <DetailFPosContent
          ref="attrWrap"
          :content="content"
          :idx="1"
        >
          <template
            v-if="hasSpecialFeature && showEvoluFloor"
            slot="middle"
          >
            <EvoluSheinBar
              :box-style="{ marginTop: 0 }"
              @bar-click="openDescription"
            />
          </template>

          <template
            v-if="!hasSpecialFeature && hasTextSpecialFeature && showEvoluFloor"
            slot="bottom"
          >
            <EvoluSheinBar @bar-click="openDescription" />
          </template>
        </DetailFPosContent>
      </div>
    </div>
    <div
      v-if="attrMore !== null"
      :class="['goods-attr__more', attrMore ? '' : 'goods-attr__more-less']"
      @click="changeAttrMore"
    >
      {{ attrMore ? language.SHEIN_KEY_PWA_18778 : language.SHEIN_KEY_PWA_14654 }}
      <i
        class="iconfont"
        :class="[attrMore ? 'icon-down' : 'icon-shang']"
      ></i>
    </div>
    <DetailFPosDrawer
      v-if="content.showModule == 0"
      :content="content"
      :idx="1"
    />
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import DetailFPosContent from './DetailFPosContent'
import EvoluSheinBar from './EvoluSheinBar'
import { contentIncludeSpecialFeatures } from '../../utils/productDetail'

export default {
  components: {
    DetailFPosContent,
    DetailFPosDrawer: () => import('./DetailFPosDrawer'),
    EvoluSheinBar,
  },
  data () {
    return {
      attrMore: null,
    }
  },
  computed: {
    ...mapGetters('newProductDetail/common', ['language', 'detailFPosContent']),
    ...mapGetters('newProductDetail', ['fsAbt']),
    ...mapGetters('newProductDetail/MiddleAttr', ['showEvoluFloor']),
    content() {
      return this.detailFPosContent
    },
    // 存在special feature属性，做特殊处理
    hasSpecialFeature() {
      const { content = {} } = this
      const { imgAttr = [] } = content
      return contentIncludeSpecialFeatures(imgAttr)
    },
    // 纯文本feature之中存在special feature属性，做特殊处理
    hasTextSpecialFeature() {
      const { content = {} } = this
      const { textAttr = [] } = content
      return contentIncludeSpecialFeatures(textAttr)
    },
  },
  mounted () {
    this.$nextTick(() => {
      if (this.content.showModule == 1) {
        this.attrMore = this.$refs.attrWrap.$el.offsetHeight > this.$refs.attrBox.offsetHeight ? true : null
      }
    })
  },
  methods: {
    ...mapActions('newProductDetail', ['openDescriptionPop']),
    ...mapMutations('newProductDetail/common', ['updateDetailFPos']),
    changeAttrMore () {
      this.attrMore = !this.attrMore
    },
    openDetail () {
      // this.changePageStatus({ detailFPos: true })
      this.updateDetailFPos(true)
    },
    openDescription () {
      this.openDescriptionPop()
    }
  }
}
</script>

<style lang="less">
.goods-attr {
  position: relative;
  overflow-y: scroll;
  &.more {
    max-height: 7.84rem;
  }
  &__more {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1.066667rem;
    background: white;
    text-align: center;
    line-height: 1.066667rem;
    .font-dpr(24px);
    color: #767676;
    vertical-align: middle;
    &::before {
      content: "";
      position: absolute;
      top: -.64rem;
      left: 0;
      right: 0;
      display: block;
      height: .64rem;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    }
  }
  &__more-less {
    position: relative;
    &::before {
      height: 0;
      top: 0;
    }
  }
  &__stitle {
    .font-dpr(28px);
    line-height: 1.14;
    padding: .32rem 0;
  }
}
</style>
