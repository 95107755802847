<template>
  <div 
    v-expose="isFour?{ id: '1-6-4-88', data: storeSignExtraData }:{}"
    class="signboard-slide__swiper j-da-event-box"
    :data-feed_type="storeJumpType === 'three' ? 2 : ''"
  >
    <swiper-container
      ref="swiper"
      init="false"
      class="s-swiper-wrapper"
    >
      <swiper-slide
        v-for="(item, index) in filterProducts"
        :key="`signboard-slide__item-${index}`"
        class="signboard-slide__item s-swiper-slide"
        :class="{ 
          'signboard-slide__item-four': isFour,
          'signboard-slide__item-fourshort': storeJumpType==='fourshort'&& isStore,
          'signboard-slide__item-fourtall': storeJumpType==='fourtall' && isStore
        }"
      > 
        <SProductItemV3
          v-if="productItemsConfig._isNew|| isFour"
          ref="goodsItem"
          :item="item"
          :index="index"
          :goods-item-info="goodsItemInfo"
          :language="itemLanguage"
          :config="getPrdItemConfig(index)"
          :constant-data="constantData"
          :column="isFour? 4.3 : 3.3"
          :cropRate="cropRate"
          @clickItem="isFour ? goToStore(item) : clickItemHandle($event)"
          @openQuickAdd="clickAdd"
        >
          <template #imgTopLeft>
            <FlashProductDiscount
              v-if="item.is_flash_sale > 0 && !isHitComplianceMode"
              :item="item"
            />
          </template>
          <template
            v-if="storeJumpType==='fourtall'&& isStore" 
            #cardPriceTop
          >
            <ProductCardPriceSimple 
              :goodsInfo="item"
              :language="itemLanguage" 
              direction="column"
              :config="cPriceConfig"
              :isPaid="isPaidUser"
            />
          </template>
          <template 
            v-if="storeJumpType==='fourshort'&&!haveMore(index)&& isStore"
            #imgBottom
          >
            <ProductCardPriceSimple 
              :goodsInfo="item"
              :language="itemLanguage" 
              direction="column"
              :config="cPriceConfig"
              :isPaid="isPaidUser"
            />
          </template>
        </SProductItemV3>
        <ProductItem
          v-else
          ref="goodsItem"
          :item="item"
          :index="index"
          :goods-item-info="goodsItemInfo"
          :language="itemLanguage"
          :config="productItemsConfig"
          :constant-data="constantData"
          :column="3.3"
          @clickItem="clickItemHandle"
        >
          <template #imgTopLeft>
            <FlashProductDiscount
              v-if="item.is_flash_sale > 0 && !isHitComplianceMode"
              :item="item"
            />
          </template>
        </ProductItem>
        <a
          v-if="haveMore(index)"
          v-expose="{
            id: productItemsConfig.itemDAEventExposeViewMoreId,
            code: 'DetailStoreListMoreKey',
            data: {
              ...productItemsConfig.sa,
              src_identifier: moreSrcIdentifier
            }
          }"
          class="signboard-slide__mask"
          :aria-label="language.SHEIN_KEY_PWA_22046"
          @click="isFour ? goToStore(item,true) : clickShowMore()"
        >
          <div class="mask-content">
            <i class="iconfont icon-liebiaoviewmore icon"></i>
            <div class="mask-text">{{ language.SHEIN_KEY_PWA_22046 }}</div>
          </div>
        </a>
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<script>
import { SHOW_GOOD_LEN } from '../constants'
import localMixins from '../mixins/index'
const { GB_cssRight } = gbCommonInfo
import { mapGetters } from 'vuex'
import { register } from 'swiper/element'
// 只在客户端环境中注册 swiper
typeof window !== 'undefined' && register()
let signboardSlideSwiper = null
export default {
  name: 'SignBoardSlide',
  mixins: [localMixins],
  computed: {
    ...mapGetters('newProductDetail', ['isPaidUser']), // 新数据流
    ...mapGetters('newProductDetail/common', ['complianceModeDe', 'isHitComplianceMode']),
    filterProducts() {
      /**
       * wiki.pageId=1007833427
       * 若「品牌（自营）/品牌（非自营）/系列/店铺」没有配置跳转对应列表页的入口，且在商详情页有展示对应组件的，则单行右滑的样式去掉「蒙层商品的展示+view more」
       */
      if(this.products.length == SHOW_GOOD_LEN && !this.signData.link) {
        return this.products.slice(0, SHOW_GOOD_LEN - 1)
      }
      return this.products
    },
    cropRate() {
      return this.storeJumpType === 'fourshort' && this.isStore ? '1-1' : ''
    },
    cPriceConfig() {
      const { complianceModeDe } = this
      return {
        hidePriceBottomLabel: true,
        ...(complianceModeDe ? { // de合规价需要设置价格黑色
          priceColor: '#000'
        } : {})
      }
    },
  },
  watch: {
    filterProducts: {
      handler() {
        setTimeout(() => {
          this.localStoreSwiper()
        }, 0)
      },
    }
  },
  destroyed() {
    signboardSlideSwiper?.destroy()
    signboardSlideSwiper = null
  },
  methods: {
    haveMore(index) {
      return this.showMore && index === this.products.length - 1 && this.signData.link
    },
    // 获取商品单项配置
    getPrdItemConfig(index) {
      const { filterProducts, productItemsConfig, fourProdecutItemConfig, storeSignExtraData, complianceModeDe } = this
      let config = {}
      if(this.isFour){
        // 新店招传新的商卡配置
        fourProdecutItemConfig.sa = {
          ...fourProdecutItemConfig.sa,
          ...storeSignExtraData
        }
        config = {
          ...fourProdecutItemConfig,
          ...(complianceModeDe ? { // de合规价需要设置价格黑色
            style: {
              salePriceColor: '#000'
            }
          } : {})
        }
      }else {
        config = productItemsConfig
      }
      // 单行滚动栏最后一个商品，不展示加车按钮，被蒙层蒙住
      if (index === filterProducts.length - 1) {
        return {
          ...config,
          showAddBagBtn: false,
          showAddBagBtnBottomRight: false,
        }
      }
      return config
    },
    localStoreSwiper() {
      const swiperEl = this.$refs.swiper
      if (!swiperEl) return

      const swiperParams = {
        dir: GB_cssRight ? 'rtl' : 'ltr',
        direction: 'horizontal',
        // loop: true,
        slidesPerView: 'auto',
        slidesPerGroup: 3,
        initialSlide: 0,
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true // 修改swiper的父元素时，自动初始化swiper,
      }
      Object.assign(swiperEl, swiperParams)
      swiperEl.initialize()
      signboardSlideSwiper = swiperEl.swiper
    }
  }
}
</script>

<style lang="less">
.signboard-slide {
  &__swiper {
    padding: 0 0 0 0.32rem;
    .s-swiper-wrapper{
      position: relative;
      width: 100%;
      height: auto;
      z-index: 1;
      display: flex;
      box-sizing: content-box;
      overflow: hidden;
    }
  }
  &__item {
    position: relative;
  }
  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    z-index: @zindex-hack;
    font-weight: 700;
    .font-dpr(20px);
    .mask-content {
      margin-top: 1.066rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #222;
      .icon {
        font-weight: 500;
        .font-dpr(60px);
      }
    }
    .mask-text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
    }
  }
}
.signboard-slide__item {
  margin-bottom: 0.346666rem;
  margin-right:0.2133rem;
  &:last-child {
    margin-right: 0.32rem;
  }
  &.s-swiper-slide {
    width: 2.8rem;
  }
  &.signboard-slide__item-four{
    width: 2.08rem;
    .product-card__camel-case-price__number-before-dot{
      font-size: 13px;
    }
    .product-card__camel-case-price__prefix-symbol{
      font-size: 9px;
    }
    .product-card__camel-case-price__number-after-dot{
      font-size: 9px;
    }
   .bottom-wrapper__price-wrapper{
      display: none;
    }
    .product-card__camel-case-price{
      height: 16px;
      line-height: 16px;
    }
    .product-item-is-multi{
      display: none;
    }
    .product-card-is-multi{
      display: none;
    }
  }
  &.signboard-slide__item-fourshort{
    --crop-img-fit: cover;
    .product-card__bottom-wrapper{
      display: none;
    }
    .mask-content{
      margin-top: 0.23rem;
    }
    .product-card__camel-case-price{
      border-radius: 2px;
      background: rgba(255, 255, 255, 0.90);
      padding: 0px 3px;
      margin-right: 4px;
      margin-bottom: 4px;
    }
    .price-content{
      > span{
        display: inline-block;
        background: rgba(255, 255, 255, 0.90);
        margin-bottom: 4px;
        padding: 0px 3px;
        border-radius: 2px;
      }
    }
  }
  &.signboard-slide__item-fourtall{
    --product-card__bottom-wrapper:4px 0 0 0;
    .mask-content{
      margin-top: 0.8rem;
    }

  }
}
</style>
