const { IS_RW } = gbCommonInfo

// 评论未登陆状态下最多显示数量
export const COMMENT_NO_LOGIN_SHOW_LIMIT = 150

// 评论详情查询数量上限
export const COMMENT_DETAIL_COUNT_UPPER_LIMIT = 1000

let showCatId = [
  2203, 2270, 1862, 2191, 2193, 2194, 2291, 1866, 2353, 2354, 2357, 1944, 2228, 1941, 2230, 2231,
  2295, 2184
]
if (IS_RW) {
  showCatId = [
    1077, 1208, 671, 1070, 1072, 1074, 1230, 679, 1296, 1297, 1300, 823, 1103, 820, 1111, 1116,
    1233, 1063
  ]
}

export const SHOW_CAT_ID = showCatId
