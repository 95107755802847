<template>
  <!--look 大图-->
  <S-image-box
    class="fullScreen-swiper"
    :visible.sync="value"
    :class="[value ? 'mshe-visibility1' : '']"
  >
    <div
      class="s-swiper-container fullScreen-swiper__container"
    >
      <swiper-container
        ref="swiper"
        init="false"
        style="height: 100%"
      >
        <swiper-slide
          v-for="(item, imgIndex) in images"
          :key="imgIndex"
          class="fullScreen-swiper__slide"
        >
          <img
            :data-src="transformImg({ img: item.member_image_middle || item.image_url })"
            data-design-width="750"
            :src="LAZY_IMG"
            class="j-clear-history-hash lazyload"
            @click="close"
          />
        </swiper-slide>
      </swiper-container>
      <div class="fullScreen-swiper__top"></div>
      <div class="fullScreen-swiper__bottom">
        <div slot="bottom"></div>
      </div>
      <i
        class="iconfont icon-close swiper-close j-clear-history-hash"
        @click="close"
      ></i>
      <div
        ref="swiper-pagination"
        class="fullScreen-swiper__page swiper-pagination swiper-pagination-fraction"
        style="bottom: auto"
      >
        <span class="swiper-pagination-current">{{ swiperSlideIndex+ 1 }}</span> / <span class="swiper-pagination-total">{{ total || images.length }}</span>
      </div>
    </div>
  </S-image-box>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { transformImg } from '@shein/common-function'
import { ImageBox } from '@shein/sui-mobile'
const { LAZY_IMG, GB_cssRight } = gbCommonInfo
import { register } from 'swiper/element'
// 只在客户端环境中注册 swiper
typeof window !== 'undefined' && register()


export default {
  components: {
    SImageBox: ImageBox,
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    images: {
      type: Array,
      default() {
        return []
      }
    },
    index: {
      type: [Number, String],
      default: 0
    },
    total: {
      type: [Number, String],
      default: 0
    },
    analysis: {
      type: Object,
      default() {
        return {
          next: '',
          prev: ''
        }
      }
    },
  },
  data() {
    return {
      swiperSlideIndex: 0,
      LAZY_IMG
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.swiperSlideIndex = this.index
        this.$nextTick(() => {
          this.swiperInit()
        })
        // this.swiper.slideTo(this.index, 0, false)
      }
    }
  },
  destroyed () {
    this.swiper?.destroy()
    this.swiper = null
  },
  methods: {
    transformImg,
    swiperInit() {
      let _this = this
      const swiperEl = this.$refs.swiper
      if (!swiperEl) return

      const swiperParams = {
        observer: true,
        dir: GB_cssRight ? 'rtl' : 'ltr',
        on: {
          slideNextTransitionStart: function() {
            const isFetchData = _this.images.length - this.realIndex < 3
            if (isFetchData) {
              _this.$emit('fetchPage')
            }
            if (_this.analysis.next) {
              daEventCenter.triggerNotice({ daId: _this.analysis.next })
            }
            _this.$emit('slideNext')
          },
          slidePrevTransitionStart: function() {
            if (_this.analysis.prev) {
              daEventCenter.triggerNotice({ daId: _this.analysis.prev })
            }
            _this.$emit('slidePrev')
          },
          slideChangeTransitionEnd: function() {
            _this.swiperSlideIndex = this.realIndex
          }
        }
      }
      Object.assign(swiperEl, swiperParams)
      swiperEl.initialize()
      this.swiper = swiperEl.swiper
      this.swiper.slideTo(this.swiperSlideIndex, 0, false)
    },
    close() {
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="less">
.fullScreen-swiper {
  .s-swiper-container{
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
  }
  .zindex-translatez(@zindex-transform-review,translate3d(0,0,@tranlatez-transform-review));
  width: 10rem;
  height: 100%;
  &__container {
    height: 100%;
  }
  &__slide{
    width: 10rem;
    height: 100%;
    .flexbox();
    .align-center();
    .pack-center();
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
    }
  }
  &__top {
    height: 1.6rem;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(0,0,0,0.36) 100%);
    width: 100%;
  }
  &__bottom {
    background: linear-gradient(180deg,rgba(0,0,0,0) 0%,rgba(0,0,0,0.36) 100%);
    position: absolute;
    left: 0;
    color: @sui_color_white;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    z-index: @zindex-hack;
  }
  &__page {
    top: .43rem;
    text-align: center;
    .font-dpr(36px);
    color: @sui_color_white;
    font-weight: 700;
  }
  .swiper-close {
      position: absolute;
      top: .43rem;
      color: #000;
      font-size: 16px;
      .left(.53rem);
      -webkit-text-stroke: 0.02rem @sui_color_white;
      font-weight: 100;
      font-size: 0.4rem;
      z-index: @zindex-attrmenusize + 1;
  }
}
</style>
