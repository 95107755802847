<script lang="jsx">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import ShippingHead from './ShippingHead'
import ShippingSheinClub from './ShippingSheinClub'
import schttp from 'public/src/services/schttp'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import { mapState, mapGetters, mapActions } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { template, htmlDecode } from '@shein/common-function'
import ShippingMethodsItem from './ShippingMethodsItem'
import { setCache, isValidCache, shippingPolicySortFn, createComparionFun, computedExpectTime, getLocalStorageAndRemoveCookie } from './util'
import { isLogin } from 'public/src/pages/common/utils/index.js'

dayjs.extend(utc)

const { langPath, lang, IS_RW, SiteUID } = gbCommonInfo
export default {
  name: 'ShippingEnter',
  components: {
    // SlideSwiper,
    // SlideSwiperItem,
    ShippingSheinClub,
    ShippingHead,
    ShippingMethodsItem,
    ShippingDrawer: prefetchResource.importAsyncComponent({
      chunkName: 'ShippingDrawer',
      componentFactory: () => import(/* webpackChunkName: "ShippingDrawer" */ './ShippingDrawer/index')
    }),
    ReturnCodDrawer: prefetchResource.importAsyncComponent({
      chunkName: 'ReturnCodDrawer',
      componentFactory: () => import(/* webpackChunkName: "ReturnCodDrawer" */ './ReturnCodDrawer')
    }),
    ReportDrawer: prefetchResource.importAsyncComponent({
      chunkName: 'ReportDrawer',
      componentFactory: () => import(/* webpackChunkName: "ReportDrawer" */ './ReportDrawer')
    }),
    ReportDrawerOld: prefetchResource.importAsyncComponent({
      chunkName: 'ReportDrawerOld',
      componentFactory: () => import(/* webpackChunkName: "ReportDrawerOld" */ './ReportDrawerOld')
    }),
    ShippingGuarantee: prefetchResource.importAsyncComponent({
      chunkName: 'ShippingGuarantee',
      componentFactory: () => import(/* webpackChunkName: "ShippingGuarantee" */ 'public/src/pages/goods_detail/components/drawer/innerComponents/ShippingGuarantee')
    }),
  },
  data() {
    return {
      lang,
      IS_RW,
      codFeeInfo: {},
      deliveryInfo: {
        tip: 'Please enter pincode and get delivery time.',
        city: '',
        enterPincode: '',
        country: 'India'
      },
      showShipping: false,
      shippingPosition: '',
      showReturnCod: 0,
      shippingDate: {},
      returnPolicy: [],
      codPolicy: [],
      addressList: [],
      showQuickShip: false, // 快速发货abt
      modelCartAddItem: false,
      openReportDrawer: false,
      openReportDrawerOld: false,
      cacheMallCode: null,
      isCollapsed: true, // 是否展开卖家信息
      // 相关弹窗是否准备好
      shippingDrawerReady: false,
      shippingReportReady: false,
      returnCodeReady: false,
      isUpdateAddress: false,
      detailStoreSellerInfo: {},
      showLoading: false,
      goodsIdHadChange: false, // skc改变重新获取数据，根据goodsid判断，
      shippingGuaranteeReady: false,
      showGuaranteeDrawer: false,
    }
  },
  computed: {
    ...mapState('newProductDetail/common', ['quickShip', 'reportStatus', 'reportStatusOld', 'guaranteeStatus', 'realTimeReady', 'codPolicyStatus', 'skuInfo']),
    ...mapState('newProductDetail/ShippingEnter', ['returnPolicyStatus', 'deliveryStatus']),
    ...mapGetters('newProductDetail', ['isPaidUser']),
    ...mapGetters('newProductDetail/common', ['language']),
    ...mapGetters('newProductDetail/ShippingEnter', [
      // cold
      'mallCode',
      'mallName',
      'goods_id',
      'goods_sn',
      'business_model',
      'cat_id',
      'GB_cssRight_rp',
      'storeInfo',
      'labelList',
      'shippingStyleV1',
      'freeShippingInfo',
      // 'detailStoreSellerInfo',
      'showSheinClub',
      'shippingLanguage',
      'showMallSite',
      'shippingAbtState',
      'showStore',
      'hasDetailStoreSellerInfo',
      'quickshipStrongAbt',
      'deliveryStrongTitleSection',
      'quickshipAgingText',
      'isSupportQuickship',
      'deliveryDialogNInfo',
      // hot
      'goodsFreeShippingInfo',
      'freeShippingInfo',
      'lowestPricesShippingMethods',
      'paidDetail',
      'showShippingClub',
      'shippingDetail',
      'sheinClubShppingText'
    ]),
    ...mapGetters('newProductDetail/Price', ['sheinClubInfo', 'isEurUnion']),
    isLogisticsShippinFree() {
      const { isProductFreeShipping, isPromotionFreeShipping, isCouponFreeShipping } = this.freeShippingInfo || {}
      return (isProductFreeShipping || isPromotionFreeShipping || isCouponFreeShipping) && this.shippingAbtState.showFreeinQuire
    },
    shippingAbt() {
      return this.shippingAbtState?.shippingAbt?.p || 'typeA'
    },
    sellerAnalysisData () {
      return {
        goods_id: this.goods_id || '',
        store_code: this.storeInfo?.storeCode || '',
      }
    },
    businessNameValue () {
      const { fieldValues = [], isDirectSaleStore } = this.detailStoreSellerInfo || {}
      if (isDirectSaleStore) {
        // 自营取写死的
        return this.detailStoreSellerInfo?.businessNameValue
      }
      const { SHEIN_KEY_PWA_24730: ERROR } = this.language
      // 取数逻辑 3 => 4 再到 Error 兜底
      let item3 = null, item4 = null
      for (let item of fieldValues) {
        if (item.fieldType === 3) {
          item3 = item
        } else if (item.fieldType === 4) {
          item4 = item
        }
      }
      return item3?.fieldValue || item4?.fieldValue || ERROR
    },
    businessAddressValue () {
      const { fieldValues = [], isDirectSaleStore } = this.detailStoreSellerInfo || {}
      if (isDirectSaleStore) {
        // 自营取写死的
        return this.detailStoreSellerInfo?.businessAddressValue
      }
      const { SHEIN_KEY_PWA_24730: ERROR } = this.language
      const item6 = fieldValues.find(item => item.fieldType === 6)
      return item6?.fieldValue || ERROR
    },
    useStrongStyleType() { // 大于0表示命中quickship增强样式
      // 0 原来icon 1 深色icon 2 空心icon
      const { floorQuickshipStyle, floorNDeliveryStyle } = this.quickshipStrongAbt // 腰带quickship强化样式 || 腰带n天达
      if(floorQuickshipStyle || floorNDeliveryStyle) {
        return this.isSupportQuickship.isSomeSupportQuickship == 1 ? 1 : 2
      }
      return 0
    },
    quickshipStrongOutsideText() {
      const { shippingType } = this.shippingInfo
      const { SHEIN_KEY_PWA_30599, SHEIN_KEY_PWA_30099 } = this.language
      const { hasPartSupportQuickship, hasSelectedSku } = this.isSupportQuickship
      if(hasPartSupportQuickship && !hasSelectedSku) { // 特殊情况优先级最高：skc下的skuquickship状态不一样，且未选中skc  拼接Quickship delivery
        return `${SHEIN_KEY_PWA_30599} ${SHEIN_KEY_PWA_30099} `
      }else if(this.quickShip == 1) { // 第二优先级
        return `${this.shippingLanguage[shippingType + '_detail_dilvery'] || ''} ${SHEIN_KEY_PWA_30099} `
      }
      return `${SHEIN_KEY_PWA_30599} ${SHEIN_KEY_PWA_30099} `
    },
    simplifyAgingText() { // 不支持qs的简化物流文案
      // 展示文案类型desc_type返回的是0（运输时间）就要取运输时间天数（transport_interval）
      // 展示文案类型返回的是1（预计送达），就要取运输时效 文案（expect_time）
      const { type, desc_type, aging_date, date_type, transport_interval } = this.shippingOutsideItem
      const { SHEIN_KEY_PWA_30099, SHEIN_KEY_PWA_23614, SHEIN_KEY_PWA_23615 } = this.language
      let expectTime = ''
      if(desc_type == 0) {
        // date_type (1=工作日，0=自然日)
        const customDayUnit = htmlDecode({ text: date_type == 1 ? SHEIN_KEY_PWA_23614 : SHEIN_KEY_PWA_23615 })
        expectTime = transport_interval ? `${transport_interval} ${customDayUnit}` : ''
      } else if(desc_type == 1) {
        expectTime = this.shippingStyleV1 ? aging_date : computedExpectTime(this.shippingOutsideItem, false, '', true)
      }
      return `${this.shippingLanguage[type + '_detail_dilvery'] || ''} ${SHEIN_KEY_PWA_30099} ${expectTime}`
    },
    tradeRegisterNumberValue () {
      const { isDirectSaleStore, codeRegisterNo } = this.detailStoreSellerInfo || {}
      if (isDirectSaleStore) {
        // 自营取写死的
        return this.detailStoreSellerInfo?.tradeRegisterNumberValue
      }
      return codeRegisterNo || this.language.SHEIN_KEY_PWA_24730
    },
    vatNumberValue () {
      const { taxInfoList = [], isDirectSaleStore } = this.detailStoreSellerInfo || {}
      if (isDirectSaleStore) {
        // 自营取写死的
        return this.detailStoreSellerInfo?.vatNumberValue
      }
      if(taxInfoList?.length) {
        if(taxInfoList.length == 1) {
          return taxInfoList[0].supplierTaxNumber
        } else {
          let vatInfo = taxInfoList.find(item => item.saleCountry == 'FR' && item.supplierTaxNumber) || null
          vatInfo = vatInfo ? vatInfo : taxInfoList.find(item => item.saleCountry == 'DE' && item.supplierTaxNumber) || null
          vatInfo = vatInfo ? vatInfo : taxInfoList.find(item => item.supplierTaxNumber) || null
          return vatInfo ? vatInfo.supplierTaxNumber : ''
        }
      }
      const { SHEIN_KEY_PWA_24731: NOT_APPLICABLE } = this.language
      return NOT_APPLICABLE
    },
    isShowDelayWarn () {
      return this.freeShippingInfo?.isShowDelayWarn || false
    },
    userDefaultAddress() {
      if (this.addressList.length) {
        return this.addressList.find(item => item.isDefault == 1) || this.addressList[0]
      }
      return null
    },
    showPaidVipTips() {
      const { showShippingClub, showSheinClub, isPaidUser } = this
      return showSheinClub && showShippingClub && !isPaidUser
    },
    showDescQuickShipTime () {
      return this.quickShip == 1 && this.shippingAbtState?.showQuickShip && this.descQuickShipTime
    },
    shippingOutsideItem() {
      let result = {}
      this.shippingPolicy.some((item) => {
        if (item.type == this.shippingInfo.shippingType) {
          result = item
          return item
        }
      })
      return result
    },
    returnCodData () {
      return this.showReturnCod === 1 ? this.returnPolicy : this.showReturnCod === 2 ? this.codPolicy : []
    },

    notSupportFree () {
      return this.shippingPolicy.every(i => i.is_support_free_shipping == 0)
    },
    quickShipHighlight(){
      return this.showDescQuickShipTime && this.isHideForLocalShop && !this.IS_RW && (this.shippingAbtState['3Psellerdelivery'].p['3Psellerdelivery'] === 'show')
    },
    // sellorInfoTxt(){
    //   const { language } = this
    //   const { storeType, title, tocPerformParty } = this.storeInfo
    //   const SHEIN = language.SHEIN_KEY_PWA_24376
    //   const isPlatform = storeType === 1
    //   const sortByVal = isPlatform ? title : SHEIN
    //   const shipFromVal = isPlatform ? (tocPerformParty === 1 ? SHEIN : title) : SHEIN
    //   const isSameVal = sortByVal === shipFromVal
    //   return isSameVal ? template(shipFromVal, language.SHEIN_KEY_PWA_24372) : template(sortByVal, shipFromVal, language.SHEIN_KEY_PWA_24371)
    // },
    sellerInfoText () {
      const { language } = this
      const { storeType, title, tocPerformParty } = this.storeInfo
      const hasLabel = this.labelList?.some(item => item.labelCode === 'sold_by_storename')
      const is1PStore = storeType === 6
      const SHEIN = language.SHEIN_KEY_PWA_24376
      const isPlatform = storeType === 1
      let soldByVal = (isPlatform ? title : SHEIN) || ''
      if(is1PStore && hasLabel){
        soldByVal = title || ''
      }
      const shipFromVal = (isPlatform ? (tocPerformParty === 1 ? SHEIN : title) : SHEIN) || ''
      const isSameVal = soldByVal === shipFromVal
      if(isSameVal){
        return {
          isShow: shipFromVal || soldByVal,
          soldByVal: ``,
          shipFromVal: shipFromVal && template(shipFromVal, language.SHEIN_KEY_PWA_24372),
        }
      }
      return {
        isShow: shipFromVal || soldByVal,
        soldByVal: soldByVal && `${language.SHEIN_KEY_PWA_27405} ${soldByVal} ${shipFromVal && '&'} `,
        shipFromVal: shipFromVal && `${language.SHEIN_KEY_PWA_27406} ${shipFromVal}`,
      }
    },
    localWarehouseText () {
      const { language, isUk, isUs, isEurUnion, skuInfo, shippingAbtState, isSupportQuickship, mallCode } = this
      const showLocalwarehoure = shippingAbtState?.showLocalwarehouse
      if(!showLocalwarehoure) return ''

      const { isSomeSupportQuickshipBase } = isSupportQuickship
      // 选择sku的情况下，如果当前sku不支持快速发货，不展示
      // 未选择sku的情况下，如果所有sku都不支持快速发货，不展示
      if(skuInfo?.sku_code) {
        if(!skuInfo?.mall[mallCode]?.sku_quick_ship) return ''
        // if(!currentQuickship) return ''
      } else {
        if(!isSomeSupportQuickshipBase) return ''
      }
      let text = ''
      const { SHEIN_KEY_PWA_31569, SHEIN_KEY_PWA_31570, SHEIN_KEY_PWA_31571 } = language
      if(isEurUnion) text = SHEIN_KEY_PWA_31569
      if(isUk) text = SHEIN_KEY_PWA_31571
      if(isUs) text = SHEIN_KEY_PWA_31570
      return text
    },
    descQuickShipTime () { // 快速发货文案
      let descQuickhipTime = this.shippingDetail?.desc_quick_ship_time || ''
      const { deliveryDialogNTitle } = this.deliveryDialogNInfo || {}
      if(descQuickhipTime) {
        descQuickhipTime = descQuickhipTime.replace(/{QSname}/g, `${deliveryDialogNTitle}`)
      }
      return descQuickhipTime
    },
    // shippingMethods () {
    //   let result = this.shippingDetail?.shippingMethods ? JSON.parse(JSON.stringify(this.shippingDetail?.shippingMethods)) : []
    //   result.forEach(item => {
    //     if (item.starting_price_obj && item.last_price_obj) {
    //       item.lowestShippingPrices = Number(item.starting_price_obj.amount) > Number(item.last_price_obj.amount) ? item.last_price_obj : item.starting_price_obj
    //     }
    //   })
    //   /* 
    //     待解决TODO：
    //     1. 外露使用shippingMethods，物流详情使用shippingPolicy，但是他们俩的排序方式有差异，需要统一
    //     2. 如果存在多个运费相同的，app获取时效最快的，但是这里没有做处理，需要统一
    //   */
    //   result.sort(createComparionFun('lowestShippingPrices', 'amount'))
    //   return result
    // },
    shippingInfo () {
      let result = {}
      let shippingMethods = this.lowestPricesShippingMethods
      if (shippingMethods.length) {
        const freeShippingMethods = this.freeShippingInfo.freeShippingMethods 
        const showFreeinQuire = this.shippingAbtState.showFreeinQuire
        const freeShippingMethod = showFreeinQuire && shippingMethods.filter(item => freeShippingMethods.includes(item.transport_type))
        const agingIsShow = shippingMethods.filter(item => { return item.aging_is_show == 1 })
        // 外露运输方式优先级：优惠券｜商品｜活动免邮 > aging_is_show === 1 > 运费最低
        let curDelivery = freeShippingMethod[0] || agingIsShow[0] || shippingMethods[0]
        let address_id = '', state_province = '', city = '', district = '', country_id = ''
        if (this.shippingDetail.params) {
          address_id = this.shippingDetail.params.address_id
          state_province = this.shippingDetail.params.state_province
          city = this.shippingDetail.params.city
          district = this.shippingDetail.params.district
          country_id = this.shippingDetail.params.country_id
        } else {
          const addressCookie = getLocalStorageAndRemoveCookie('addressCookie')
          if (addressCookie) {
            let cache = typeof addressCookie == 'string' ? JSON.parse(addressCookie) : addressCookie
            if (isValidCache(cache)) {
              address_id = cache.addressId
              state_province = cache.stateId
              city = cache.cityId
              district = cache.districtId
              country_id = cache.countryId
            }
          }
        }
        if (curDelivery && curDelivery.aging_is_show == 1) {
          if (Number(curDelivery.has_zero_threshold) === 1) {
            curDelivery.isFreeShipping = true
          } else {
            if (Number(curDelivery.starting_price) === 0 && Number(curDelivery.step_price) === 0) {
              curDelivery.isFreeShipping = true
            }
          }
        } else if (freeShippingMethod[0]){
          curDelivery.isFreeShipping = true
        }
        result.address_id = address_id
        result.state_province = state_province
        result.city = city
        result.district = district
        result.country_id = country_id
        result.desc_type = curDelivery.desc_type
        if (curDelivery) {
          result.aging_date = curDelivery.aging_date
          result.aging_is_expose = curDelivery.aging_is_expose
          result.aging_is_show = curDelivery.aging_is_show
          result.step_price_obj = curDelivery.step_price_obj || null
          result.is_support_free_shipping = curDelivery.is_support_free_shipping
          if (curDelivery.isFreeShipping) {
            result.isFreeShipping = true
            result.shippingType = curDelivery.type
          } else {
            result.isFreeShipping = false
            if (curDelivery.aging_is_show == 1 && curDelivery.full_amount && Number(curDelivery.full_amount.amount) > 0) {
              result.shippingType = curDelivery.type
              result.full_amount = curDelivery.full_amount
            } else if (curDelivery.aging_is_show === 0) {
              result.shippingType = curDelivery.type
              result.starting_price_obj = curDelivery.starting_price_obj
              result.last_price_obj = curDelivery.last_price_obj
              result.shipping_mold = curDelivery.shipping_mold
              result.shippingPrices = curDelivery.shippingPrices || {}
              result.lowestShippingPrices = curDelivery.lowestShippingPrices || {}
              result.full_amount = {}
            } else {
              result.shippingType = ''
              result.full_amount = {}
              result.shippingPrices = {}
            }
          }

          if (this.userDefaultAddress && !this.isUpdateAddress) {
            const { city, district, addressId, countryId, state } = this.userDefaultAddress
            result.detailedAddress = [district, city, state, curDelivery.shippingCountryName].join(' ')
            result.address_id = addressId
            result.state_province = state
            result.city = city
            result.district = district
            result.country_id = countryId

          } else {
            result.detailedAddress = [district, city, state_province, curDelivery.shippingCountryName].join(' ')
          }

          result.country = curDelivery.shippingCountryName
        }
      }
      return result
    },
    shippingPolicy () {
      let result = []
      let shippingMethods = this.lowestPricesShippingMethods
      if (shippingMethods.length) {
        result = shippingMethods.map((item) => {
          let res = {
            hasZeroThreshold: item.has_zero_threshold,
            title: item.title || '',
            type: item.type,
            fullAmount: item.full_amount,
            currencyCode: item.currency_code,
            expectTime: item.expect_time,
            shippingMold: item.shipping_mold,
            shippingPrices: item.shippingPrices,
            startingPriceObj: item.starting_price_obj,
            stepPriceObj: item.step_price_obj,
            lastPriceObj: item.last_price_obj || '',
            currentTime: item.current_time || '',
            aging_is_show: item.aging_is_show || '',
            aging_is_expose: item.aging_is_expose || '',
            ...item,
          }
          res.timeTip = this.getShippingOutsideText(res, this.shippingAbt === 'quick')
          return res
        })
        // 按运费shippingPrices.amount进行从低到高排序， 当aging_is_show==0（不包邮）时取运费最低的运输方式外漏展示
        result.sort(shippingPolicySortFn(this.shippingAbt))
      }
      return result
    },
    isHideForLocalShop () {
      let shippingMethods = this.shippingDetail?.shippingMethods || []
      return shippingMethods.some(item => item.is_hide_for_local_shop === 1)
    },
    delayWarn () {
      return this.lowestPricesShippingMethods
    },
    // 商详的时效提醒只展示排序后第一条
    firstDelayWarnTip () {
      return this.lowestPricesShippingMethods.slice(0, 1)?.[0] || {}
    },
    isUs() {
      return !!~SiteUID.indexOf('us')
    },
    isUk() {
      return !!~SiteUID.indexOf('uk')
    },
    showSellerIcon() {
      return this.shippingAbtState?.showSellerIcon &&  this.hasDetailStoreSellerInfo
    }
  },
  watch: {
    'deliveryStatus' (newVal) {
      this.showShipping = newVal
    },
    'returnPolicyStatus' (newVal) {
      this.showReturnCod = newVal ? 1 : 0
      if (newVal) {
        setTimeout(() => {
          $('.j-return-policy-back-detail').focus()
        }, 500)
      } else {
        setTimeout(() => {
          $('.j-go-return-policy').focus()
        }, 300)
      }
    },
    'codPolicyStatus' (newVal) {
      this.showReturnCod = newVal ? 2 : 0
    },
    'reportStatus' (newVal) {
      this.openReportDrawer = newVal
    },
    'reportStatusOld' (newVal) {
      this.openReportDrawerOld = newVal
    },
    'guaranteeStatus' (newVal) {
      this.showGuaranteeDrawer = newVal
    },
    'mallCode' () {
      this.getCacheShippingInfo()
    },
    'goods_id' () {
      this.isCollapsed = true
      this.goodsIdHadChange = true
      this.$nextTick(() => {
        this.shippingAbtState.showSellerInfo && daEventCenter.triggerNotice({ id: '1-6-4-66' })
        this.localWarehouseText && daEventCenter.triggerNotice({ id: '1-6-4-98' })
      })
    },
    realTimeReady: {      
      handler(val) {
        if (val) {
          // 等实时数据准备好再获取物流信息，因为物流接口需要传商品免邮
          this.getCacheShippingInfo()
        }
      }
    }
  },
  mounted() {
    this.cacheMallCode = this.mallCode
    this.getCacheShippingInfo()
    if (this.$el && this.$el?.tagName) {
      prefetchResource.listen({
        el: this.$el,
        prefetchList: [
          {
            chunkName: 'ShippingDrawer',
            relType: 'prefetch'
          },
          {
            chunkName: 'ReturnCodDrawer',
            relType: 'prefetch'
          },
          {
            chunkName: 'ReportDrawer',
            relType: 'prefetch'
          },
          {
            chunkName: 'ReportDrawerOld',
            relType: 'prefetch'
          },
          {
            chunkName: 'ShippingGuarantee',
            relType: 'prefetch'
          }
        ],
        delay: 3000,
        prefetchCallback: ({ status, info }) => {
          console.log('prefetchCallback', status, info)      
        },
      })
    }
    this.shippingAbtState.showSellerInfo && daEventCenter.triggerNotice({ id: '1-6-4-66' })
  },
  methods: { 
    ...mapActions('newProductDetail/common', ['getCountryId', 'updateCartFreeInfo']),
    ...mapActions('newProductDetail/ShippingEnter', ['updateShippingDetail', 'getDetailStoreSellerInfo']),
    /**
     * 获取物流详细信息
     */
    async getShippingDetail (shippingParams, is_init) {
      const {
        country_id = '',
        address_id = '',
        addressId, 
        state_province = '',
        city = '',
        district = '',
        goods_sn = '',
        state_id = '',
        district_id = '',
        city_id = '',
        ip_country = '',
      } = shippingParams
      const { mallCode, business_model } = this
      const params = {
        country_id,
        address_id,
        addressId,
        state_province,
        city,
        district,
        goods_sn,
        business_model,
        market_scope: this.storeInfo?.salesArea,
        mall_code: mallCode,
        ip_country
      }
      let productFreeId = this.goodsFreeShippingInfo?.rule_id
      let joinTransportTypes = this.goodsFreeShippingInfo?.join_transport_types
      // TR-13180 回传商品免邮状态
      const freeShippingParams = {
        productFreeId,
        joinTransportTypes
      }
      let shippingMethods = this.lowestPricesShippingMethods
      let countryId = shippingMethods?.filter?.(item => { return item.aging_is_show == 1 })?.[0]?.country_id || shippingMethods?.[0]?.country_id
      let cacheCountryId = ''
      let cache = getLocalStorageAndRemoveCookie('addressCookie')
      if (cache) {
        cache = typeof cache == 'string' ? JSON.parse(cache) : cache
        try {
          cacheCountryId = cache.countryId
        } catch (e) {
          cache = null
        }
      }
      // 如果没有直出数据或者有缓存的国家, 切mall、skc、地址, 需要更新
      if (!params.country_id) {
        params.country_id = countryId
      }
      if (
        !shippingMethods.length ||
        !!cacheCountryId ||
        this.cacheMallCode != mallCode ||
        this.isUpdateAddress || 
        is_init
      ) {
        this.cacheMallCode = mallCode
        const sortType = this.shippingStyleV1 ? 1 : 0
        let data = await this.updateShippingDetail({ ...params, ...freeShippingParams, sortType })
        shippingMethods = data?.shippingMethods || []
        countryId = shippingMethods?.filter?.(item => { return item.aging_is_show == 1 })?.[0]?.country_id || shippingMethods?.[0]?.country_id
      }
      if (countryId) this.getReturnAndCodPolicy(countryId)
      this.$nextTick(() => {
        setCache({
          address_id,
          addressId,
          country_id,
          state_id,
          city_id,
          district_id,
          state_province, 
          city, 
          district,
          country: this.shippingInfo.country, 
        }, is_init ? '0' : '1')
      })
      this.$emit('lazyLoaded')
    },

    getShippingOutsideText(policy, abtControl = false) {
      // date_tpye (1=工作日，0=自然日)
      const isBusiness = policy.date_type == 1
      const customDayUnit = isBusiness ? this.language.SHEIN_KEY_PWA_23614 : this.language.SHEIN_KEY_PWA_23615
      // 卡片式通过abt控制span文案样式
      const customTransportInterval = abtControl ? `<span>${policy.transport_interval}</span>` : policy.transport_interval
      return policy.desc_type == 1 ?
        computedExpectTime(policy, abtControl, this.language.SHEIN_KEY_PWA_16823) : 
        policy.transport_interval ? customTransportInterval + ' ' + htmlDecode({ text: customDayUnit }) : ''
    },
    replaceCodPrice (text = '') {
      const { orderMinimumAmount, orderMaximumAmount, serviceCharge } = this.codFeeInfo
      const placeholders = {
        orderMinimumAmount,
        orderMaximumAmount,
        serviceCharge
      }
      const ressult = text.replace(/\{([^}]+)\}/g, (match, placeholder) => placeholders[placeholder] || match)
      return ressult
    },
    getDayPercentDes(item) {
      const { desc_type, shipping_day_percent_desc, delivery_day_percent_desc, days, percent } = item || {}
      if (+desc_type === 0 && shipping_day_percent_desc && days) {
        return shipping_day_percent_desc
      }
      if (+desc_type === 1 && delivery_day_percent_desc && percent) {
        return delivery_day_percent_desc
      }
      return null
    },
    getCacheShippingInfo() {
      const { goods_sn } = this
      const addressCookie = getLocalStorageAndRemoveCookie('addressCookie')
      let shippingInfo = ''
      let [params, cache] = [{}, null]
      if (addressCookie) {
        cache = typeof addressCookie == 'string' ? JSON.parse(addressCookie) : addressCookie
        if (!isValidCache(cache)) cache = null
      }
      if (cache) {
        const addressCacheStr = window?.localStorage?.getItem('address_cache')
        const addressCache = addressCacheStr && JSON.parse(addressCacheStr)
        let addressId = cache.addressId || cache.address_id || ''
        shippingInfo = {
          address_id: addressId,
          addressId: addressId,
          country_id: cache.countryId,
          state_id: cache.stateId,
          city_id: cache.cityId,
          district_id: cache.districtId,
          state_province: addressCache?.[cache.stateId] || '',
          city: addressCache?.[cache.cityId] || '',
          district: addressCache?.[cache.districtId] || '',
        }
      } else if (window.sessionStorage && this.lang != 'in') { // 旧缓存 后期可能要废掉
        shippingInfo = sessionStorage.getItem(`${langPath.replace('/', '')}_deliveryShippingInfo`)
        shippingInfo = shippingInfo && JSON.parse(shippingInfo)
      }

      if (shippingInfo) {
        params = shippingInfo
        !cache && window?.localStorage?.removeItem('address_cache')
      }
      params.goods_sn = goods_sn
      this.getShippingInfo(params, true)
    },
    async getReturnAndCodPolicy (country_id) {
      const { goods_sn: skc, business_model = 0, goods_id, cat_id } = this
      const { mallCode } = this
      const returnPolicy = []
      const params = {
        skc,
        country_id,
        mall_code: mallCode,
        businessModel: business_model || 0,
        goods_id,
        cat_id
      }
      const result = await schttp({
        method: 'GET',
        url: '/api/productInfo/returnAndCodPolicy/get',
        params,
      })
      // 存在COD
      if (result?.cod_policy && result?.cod_policy?.codTitle) {
        // 实时获取上下限金额
        const { info: codInfo = {} } = await schttp({
          url: '/api/productInfo/getCodConfigFeeCon/get',
          params: {
            countryId: country_id || '226', // 美国 226 兜底
          }
        })
        const { cod_service_fee = {}, cod_amount_limit = {} } = codInfo
        this.codFeeInfo =  {
          orderMinimumAmount: cod_amount_limit?.min_amount?.amountWithSymbol || '',
          orderMaximumAmount: cod_amount_limit?.max_amount?.amountWithSymbol || '',
          serviceCharge: cod_service_fee?.amount?.amountWithSymbol || ''
        }
        const { codTitle, codDescription } = result?.cod_policy
        let codDescriptionRawText = codDescription.replace(/href=\"\//, `href=\"${langPath}\/`)
        this.codPolicy = [{
          title: codTitle,
          content: this.replaceCodPrice(codDescriptionRawText)
        }] || []
      }

      // delivery
      result?.delivery && returnPolicy.push({ ...result?.delivery, type_id: 2 })
      // return policy
      result?.return_policy && returnPolicy.push({ ...result?.return_policy, type_id: 1 })
      returnPolicy.forEach(i => {
        if (i?.content) {
          i.content = i.content.replace(/href=\"\//, `href=\"${langPath}\/`)
        }
      })
      this.returnPolicy = returnPolicy
    },
    /**
     * 获取物流信息
     */
    async getShippingInfo (params, is_init) {
      if(isLogin()) { // 登陆
        let { country_id, ip_country } = await this.getUserAddressList({ ...params }, is_init)
        this.getShippingDetail({ ...params, country_id, ip_country }, is_init)
      } else { // 未登陆
        const shippingParams = Object.assign({}, params)
        if (!shippingParams.country_id) {
          // TODO yidier 这里抽成一个全局服务？
          shippingParams.country_id = await this.getCountryId()
          shippingParams.ip_country = 1
        }
        this.getShippingDetail(shippingParams, is_init)
      }
    },
    // 获取用户地址信息
    async getUserAddressList(params) {
      let { country_id } = params // 这里拿的其实是缓存数据
      let ip_country = ''
      if (!this.addressList.length) {
        const { info = {} } = await schttp({
          method: 'GET',
          url: '/api/user/addressbook/limitAddr/query',
          params: {
            isSiteLimit: 1
          },
        })
        const { address = [] } = info || {}
        this.addressList = address
      }
      const { userDefaultAddress } = this
      if (!country_id) { // 说明缓存里也没有信息了 因为如果存缓存 country_id 不可能为空
        // 没缓存 拿用户默认地址
        // 没默认地址 拿用户地址第一个
        if (userDefaultAddress) {
          country_id = userDefaultAddress?.countryId
        } else {
          country_id = await this.getCountryId()
          ip_country = 1 // 需要知道是不是 ip 兜底的
        }
      }
      return { country_id, ip_country }
    },
    openDeliverySlide (e, index, item = {}) {
      let shippingPosition = ''
      if (index >= 0) {
        const { days = '', transport_type = '' } = item

        if (this.shippingPolicy[index]?.day_percents?.length) {
          shippingPosition = index
        } else {
          shippingPosition = 0
        }

        daEventCenter.triggerNotice({
          daId: '1-6-4-60',
          extraData: {
            days,
            shipping_method: transport_type
          }
        })
      }
      if (!this.shippingDrawerReady) {
        this.shippingDrawerReady = true
      }

      this.$nextTick(() => {
        this.showShipping = !this.showShipping
        this.$nextTick(() => {
          // s-tab 组件下划线不生效处理
          this.shippingPosition = shippingPosition
        })
      })
    },
    updateAddress (data) {
      const { goods_sn } = this
      this.isUpdateAddress = true
      this.getShippingInfo({
        ...data,
        goods_sn
      }, false)
      if (data.country_id) {
        this.updateCartFreeInfo({
          country_id: data.country_id
        })
      }
    },
    handleSlideChangeTransitionEnd() {
      daEventCenter.triggerNotice({
        daId: '1-6-4-26'
      })
    },
    updateDelivery ({ deliveryInfo, goodsDeliveryDate }) {
      this.goodsDeliveryDate = goodsDeliveryDate
      this.deliveryInfo = { ...this.deliveryInfo, ...deliveryInfo }
    },
    clickReport () {
      // us站还是原逻辑
      if(this.isUs) {
        this.$nextTick(() => {
          this.openReportDrawerOld = true
        })
      } else {
        if(!isLogin()) {
          SHEIN_LOGIN.show({
            show: true,
            from: 'other',
            cb: () => {
              this.clickReport()
            }
          })
          return
        }
        this.$nextTick(() => {
          this.openReportDrawer = true
        })
      }
      if (!this.shippingReportReady) {
        this.shippingReportReady = true
      }
      daEventCenter.triggerNotice({
        daId: '1-6-4-68'
      })
    },
    setReturnCode (code) {
      if (!this.returnCodeReady) {
        this.returnCodeReady = true
      }
      this.$nextTick(() => {
        this.showReturnCod = code
      })
    },
    async setCollapse () {
      if (!this.showSellerIcon) {
        return
      }
      this.detailStoreSellerInfo = await this.getSellerInfo()
      if (this.isCollapsed === true) {
        daEventCenter.triggerNotice({
          daId: '1-6-4-81',
          extraData: this.sellerAnalysisData || {}
        })
      }
      this.isCollapsed = !this.isCollapsed
    },
    // 获取卖家信息
    getSellerInfo() {
      return new Promise((resolve) => {
        if(Object.keys(this.detailStoreSellerInfo).length && !this.goodsIdHadChange) {
          return resolve(this.detailStoreSellerInfo)
        }

        const { business_model } = this
        const { sellerSource, sellerId } = this.storeInfo
        const params = { business_model, sellerSource, sellerId }
        this.showLoading = true
        const timeout = ms => new Promise((resolve) => setTimeout(() => resolve(), ms)) // 解决首次请求过快导致loading闪现问题
        Promise.all([this.getSellerInfoHttp(params), timeout(400)]).then(res => {
          this.showLoading = false
          this.goodsIdHadChange = false
          resolve(res[0])
        })
      })
    },
    getSellerInfoHttp(params) {
      return schttp({
        method: 'GET',
        url: '/api/productInfo/sellerInfo/get',
        params,
      })
    },
    template,
    openShippingGuarantee() {
      this.shippingGuaranteeReady = true
      this.$nextTick(() => {
        this.showGuaranteeDrawer = !this.showGuaranteeDrawer
      })
    }
  },
  render () {
    let {
      shippingInfo,
      lang,
      language,
      IS_RW,
      shippingPolicy,
      mallCode,
      sheinClubShppingText,
      isLogisticsShippinFree,
      shippingStyleV1
    } = this
    let hasShippingHead = (this.deliveryInfo.city && lang === 'in') ||
      (shippingInfo.shippingType && lang !== 'in') ||
      shippingInfo.detailedAddress
    let mainDom = () => {
      return (
        <div class="shipping__main">
          {hasShippingHead ?
            <ShippingHead
              language={language}
              lang={lang}
              is-rw={IS_RW}
              mall-code={mallCode}
              delivery-info={this.deliveryInfo}
              is-logistics-shippin-free={isLogisticsShippinFree}
              shipping-info={shippingInfo}
              mall-name={this.mallName}
              show-mall-site={this.showMallSite}
              quickship-strong-abt={this.quickshipStrongAbt}
              use-strong-style-type={this.useStrongStyleType}
              quickship-aging-text={this.quickshipAgingText}
              is-support-quickship={this.isSupportQuickship}
              simplify-aging-text={this.simplifyAgingText}
              quickship-strong-outside-text={this.quickshipStrongOutsideText}
              delivery-strong-title-section={this.deliveryStrongTitleSection}
              shipping-abt={this.shippingAbt}
              show-desc-quick-ship-time={this.showDescQuickShipTime}
              desc-quick-ship-time={this.descQuickShipTime}
              shipping-language={this.shippingLanguage}
              shipping-outside-item={this.shippingOutsideItem}
              shipping-abt-state={this.shippingAbtState}
              show-paid-vip-tips={this.showPaidVipTips}
              shein-club-user-info={this.sheinClubInfo}
              shein-club-shpping-text={sheinClubShppingText}
              shipping-style-v1={shippingStyleV1}
              shipping-outside-text={this.getShippingOutsideText(this.shippingOutsideItem)}
              day-percent-des={this.getDayPercentDes(this.shippingOutsideItem)}
              shipping-policy={shippingPolicy}
              not-support-free={this.notSupportFree}
              quick-ship-highlight={this.quickShipHighlight}
              onOpenDeliverySlide={this.openDeliverySlide}
              onClickCartAddItem={() => this.modelCartAddItem = true}
            /> : null
          }
          {this.isShowDelayWarn && this.firstDelayWarnTip?.supple_desc ?
            <div
              {...{
                directives: [
                  { name: 'expose', value: { id: '1-6-1-155' } },
                ]
              }}
              style="padding-left: 25px;"
              class="shipping__free-tip"
            >
              <span domPropsInnerHTML={this.firstDelayWarnTip?.supple_desc}></span>
            </div> : null
          }          
        </div>
      )
    }

    const sellerNewDom = () => {
      const { 
        sellerAnalysisData,
        isCollapsed,
        language,
        sellerInfoText,
        localWarehouseText,
        businessNameValue,
        businessAddressValue,
        tradeRegisterNumberValue,
        vatNumberValue
      } = this
      return (
        <div>
          <div class="shipping__border-top"></div>
          <div
            class="soldbybox-container"
          >
            <div 
              class="soldbybox__header" 
              onClick={this.setCollapse}
            >
              <p>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.77881 2.34131V16.7838H16.2213V2.34131H1.77881ZM9.27272 10.5825C10.09 10.5008 10.8066 10.0565 11.2501 9.40621C11.7422 10.1279 12.5711 10.596 13.5001 10.596C13.9371 10.596 14.3504 10.4929 14.7166 10.3096L14.7163 15.2785H11.7213V11.9035H6.27881V15.2785H3.28382L3.28407 10.3099C3.65014 10.493 4.06324 10.5961 4.50006 10.5961L4.62617 10.5933L4.76779 10.583L4.77272 10.5825C5.58995 10.5008 6.30662 10.0565 6.75006 9.40621C7.24219 10.1279 8.07109 10.5961 9.00006 10.5961L9.12617 10.5933L9.26779 10.583L9.27272 10.5825ZM14.7022 7.65913L14.7108 7.7553L14.7149 7.81658L14.7163 7.87717C14.7152 8.54791 14.1711 9.09131 13.5001 9.09131C12.8932 9.09131 12.3813 8.64369 12.2962 8.05009L12.2037 7.40434H10.2965L10.2044 8.04695L10.189 8.13314C10.0703 8.68498 9.57824 9.09131 9.00006 9.09131C8.39315 9.09131 7.88129 8.64369 7.79621 8.05009L7.70366 7.40434H5.79645L5.70436 8.04694L5.68904 8.13316C5.57034 8.68498 5.07823 9.09131 4.50006 9.09131C3.82834 9.09131 3.28381 8.54677 3.28381 7.87506C3.28381 7.83633 3.2856 7.79796 3.28913 7.76L3.29879 7.65626L3.28381 7.57573V3.84631H14.7163V7.5781L14.7022 7.65913ZM7.78381 15.2788V13.4088H10.2163V15.2788H7.78381Z" fill="#198055"/>
                </svg>
                <span>
                  { `${sellerInfoText.soldByVal + sellerInfoText.shipFromVal}` }
                  { 
                    <span
                      {...{
                        directives: [
                          { name: 'expose', value: { id: '1-6-4-98' } },
                        ]
                      }}
                      class="soldbybox__header-local"
                      style={{ visibility: localWarehouseText ? 'visible' : 'hidden' }}
                    >{localWarehouseText}</span> 
                  }
                </span>
              </p>
              
              { this.showSellerIcon ?
                this.showLoading ? <s-loading show={this.showLoading} /> :
                  <i
                    {...{
                      directives: [{ 
                        name: 'expose', 
                        value: { 
                          id: '1-6-4-80',
                          data: sellerAnalysisData
                        } 
                      }]
                    }}
                    aria-hidden="true"
                    style="color: #959595;"
                    class={['suiiconfont', isCollapsed ? 'sui_icon_more_down2_16px' : 'sui_icon_more_up2_16px ']}
                  ></i>
                : null
              }
            </div>
            <div
              v-show={!isCollapsed}
              class="soldbybox__content">
              <div class="soldbybox__table">
                <li>
                  <span class="cell-title">{ language.SHEIN_KEY_PWA_24725 }</span>
                  <span class="cell-content">{ businessNameValue }</span>
                </li>
                <li>
                  <span class="cell-title">{ language.SHEIN_KEY_PWA_24721 }</span>
                  <span class="cell-content ltr">{ businessAddressValue }</span>
                </li>
                <li>
                  <span class="cell-title">{ language.SHEIN_KEY_PWA_24727 }</span>
                  <span class="cell-content ltr">{ tradeRegisterNumberValue }</span>
                </li>
                <li>
                  <span class="cell-title">{ language.SHEIN_KEY_PWA_24729 }</span>
                  <span class="cell-content ltr">{ vatNumberValue }</span>
                </li>
              </div>
              <div class="soldbybox__tips">
                <p>{language.SHEIN_KEY_PWA_24734}</p>
                <p>{language.SHEIN_KEY_PWA_24735}</p>
              </div>
            </div>
            {this.shippingAbtState.showReport ?
              <p
                {...{
                  directives: [
                    { name: 'expose', value: { id: '1-6-4-67' } },
                  ]
                }}
                class="soldbybox__report j-push-history-hash"
                state={ this.isUs ? 'reportOld' : 'report' }
                onClick={this.clickReport}
              >
                <span>
                  {this.GB_cssRight_rp ? <i aria-hidden="true" class={['suiiconfont', IS_RW ? 'sui_icon_more_right_16px' : 'sui_icon_more_right2_16px']}></i> : null}
                  { language.SHEIN_KEY_PWA_27417  || 'To Report seller and/or this product'}
                  {!this.GB_cssRight_rp ? <i aria-hidden="true" class={['suiiconfont', IS_RW ? 'sui_icon_more_right_16px' : 'sui_icon_more_right2_16px']}></i> : null}
                </span>
              </p> : null
            }
          </div>

        </div>
      )
    }
    const guaranteeDom = () => {
      return (
        <div>
          <div class="shipping__border-top"></div>
          <div class="shipping-guarantee j-push-history-hash" state="guarantee" onClick={this.openShippingGuarantee}>
            <p class="shipping-guarantee__header">
              <i class={['suiiconfont', 'sui_icon_guarantee_18px']}></i>
              <span>{ language.SHEIN_KEY_PWA_27597 }</span>
              <i
                aria-hidden="true"
                class={['suiiconfont', IS_RW ? 'sui_icon_more_right_16px' : 'sui_icon_more_right2_16px']}
              ></i>
            </p>
            <div class="shipping-guarantee__content">
              <li>
                <span class="shipping-guarantee__dot"></span>
                <span>{ language.SHEIN_KEY_PWA_27580 }</span>
              </li>
              <li>
                <span class="shipping-guarantee__dot"></span>
                <span>{ language.SHEIN_KEY_PWA_27410 }</span>
              </li>
              <li>
                <span class="shipping-guarantee__dot"></span>
                <span>{ language.SHEIN_KEY_PWA_27411 }</span>
              </li>
              <li>
                <span class="shipping-guarantee__dot"></span>
                <span>{ language.SHEIN_KEY_PWA_27412 }</span>
              </li>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div class="shipping">
        {mainDom()}
        {/* {sellerDom()} */}
        {this.codPolicy.length ?
          <div>
            <div class="shipping__border-top"></div>
            <div
              class="shipping__returnpolicy j-push-history-hash j-sa-cod"
              state="cod-policy"
              onClick={() => this.setReturnCode(2)}
            >
              <p
                {...{
                  directives: [
                    { name: 'ada', value: { level: 0, pos: 1 } },
                  ]
                }}
                aria-label={this.codPolicy[0].title}
                role="text"
              >
                <i aria-hidden="true" class={['suiiconfont', IS_RW ? 'sui_icon_cod_18px' : 'sui_icon_COD_green_18px']}></i>
                <span aria-hidden="true" domPropsInnerHTML={this.codPolicy[0].title}></span>
              </p>
              <i aria-hidden="true" class={['suiiconfont', IS_RW ? 'sui_icon_more_right_16px' : 'sui_icon_more_right2_16px']}></i>
            </div>
          </div>
          : null
        }
        {this.returnPolicy.length ?
          <div>
            <div class="shipping__border-top"></div>
            <div
              class="shipping__returnpolicy j-push-history-hash j-go-return-policy ly"
              state="return-policy"
              da-event-click="1-6-4-5"
              onClick={() => this.setReturnCode(1)}
            >
              {
                this.returnPolicy.filter(item => item.type_id == '1').map(item => {
                  let title = ''
                  if (item.is_return == 1) title = item.title
                  else if (item.is_refund_able) title = item.refund_out_title
                  else if (item.is_new_out_title) title = language.SHEIN_KEY_PWA_31477
                  else title = language.SHEIN_KEY_PWA_20503

                  return (
                    <p
                      {...{
                        directives: [
                          { name: 'ada', value: { level: 0, pos: 2 } },
                        ]
                      }}
                      aria-label={(title)?.replace?.(/<[^>]+>|&amp;|amp;/g, '')}
                      role="text"
                    >
                      <i aria-hidden="true" class={[item.is_return == 1 ? (IS_RW ? 'suiiconfont sui_icon_return_18px' : 'suiiconfont sui_icon_return_green_18px') : (IS_RW ? 'suiiconfont sui_icon_caution_18px' : 'iconfont icon-juxingx3')]}></i>
                      <span aria-hidden="true" domPropsInnerHTML={title}></span>
                    </p>
                  )
                })
              }
              <i aria-hidden="true" class={['suiiconfont', IS_RW ? 'sui_icon_more_right_16px' : 'sui_icon_more_right2_16px']}></i>
            </div>
          </div>
          : null
        }
        { this.shippingAbtState.showShippingGuarantee && !this.IS_RW ? guaranteeDom() : null }
        { this.shippingAbtState.showSellerInfo && this.sellerInfoText.isShow ? sellerNewDom() : null}
        {this.shippingDrawerReady && shippingInfo.shippingType ?
          <ShippingDrawer
            language={language}
            shipping-policy={shippingPolicy}
            v-model={this.showShipping}
            show-desc-quick-ship-time={this.showDescQuickShipTime}
            desc-quick-ship-time={this.descQuickShipTime}
            quick-ship-highlight={this.quickShipHighlight}
            shipping-language={this.shippingLanguage}
            goods-delivery-date={this.shippingDate}
            delivery-info={this.deliveryInfo}
            shipping-info={this.shippingInfo}
            return-policy={this.returnPolicy}
            delay-warn={this.delayWarn}
            address-list={this.addressList}
            free-shipping-info={this.freeShippingInfo}
            is-store={this.showStore}
            mall-name={this.mallName}
            show-mall-site={this.showMallSite}
            shipping-position={this.shippingPosition}
            abt-config={this.shippingAbtState}
            shipping-style-v1={this.shippingStyleV1}
            onUpdateDelivery={this.updateDelivery}
            onUpdateAddress={this.updateAddress}
          /> : null
        }
        {this.returnCodeReady && (this.codPolicy.length || this.returnPolicy.length) ?
          <ReturnCodDrawer
            v-model={this.showReturnCod}
            language={language}
            return-cod-data={this.returnCodData}
          /> : null
        }
        {this.shippingReportReady && this.shippingAbtState.showReport ?
          <ReportDrawer
            v-model={this.openReportDrawer}
            language={language}
            skc={this.goods_sn}
            has-call={this.shippingAbtState.showReportCall}
            store-store-type={this.storeInfo?.storeStoreType}
            store-code={this.storeInfo?.storeCode}
            business-model={this.business_model}
          /> : null
        }
        {this.shippingReportReady && this.shippingAbtState.showReport ?
          <ReportDrawerOld
            v-model={this.openReportDrawerOld}
            language={language}
            skc={this.goods_sn}
            has-call={this.shippingAbtState.showReportCall}
            store-store-type={this.storeInfo?.storeStoreType}
            store-code={this.storeInfo?.storeCode}
            business-model={this.business_model}
          /> : null
        } 
        {
          this.shippingGuaranteeReady ? 
            <ShippingGuarantee 
              v-model={this.showGuaranteeDrawer}
              language={language}
            /> : null
        }
      </div>
    )
  }
}
</script>

<style lang="less">
/* stylelint-disable selector-max-specificity  */
.shipping {
  min-height: 2.76rem;
  div {
    &:first-child {
      border-top: 0px solid #e5e5e5;
    }
  }
  &__main{
    padding-bottom: 0.32rem;
  }
  &__bottom {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;
  }
  &__bottom-ani {
    max-height: 300px;
    overflow: initial;
  }
  &__free-tip {
    color: #767676;
    font-size: 0.32rem;
    line-height: normal;
    font-weight: 400;
    margin: 0.1333rem 0 0 0;
    display: flex;
    // 超出两行省略
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &__border-top {
    background: #e5e5e5;
    margin-left: 26px;
    height: 1px;
  }
  &__returnpolicy {
    position: relative;
    .flexbox();
    .align-center();
    flex-wrap: wrap;
    height: auto;
    // max-height: 1.12rem;
    line-height: .45rem;
    padding: .32rem 0;
    .padding-r(.32rem);
    .font-dpr(28px);
    >p {
      .flexbox();
      .align-center();
      width: 100%;
    }
    .sui_icon_return_green_18px,
    .sui_icon_return_18px,
    .icon-juxingx3,
    .sui_icon_caution_18px,
    .sui_icon_guarantee_18px,
    .sui_icon_COD_green_18px {
      display: inline-block;
      .font-dpr(36px);
      .margin-r(.21rem);
      // margin-bottom: .06rem;
      height: 18px;
      vertical-align: text-bottom;
    }
    .sui_icon_return_18px, .sui_icon_return_green_18px, .sui_icon_cod_18px, .sui_icon_COD_green_18px, .sui_icon_guarantee_18px {
      color: @sui_color_lightgreen;
    }
    .sui_icon_caution_18px {
      margin-bottom: 0;
    }

    .icon-juxingx3,
    .sui_icon_caution_18px {
      color: #666;
    }
    .sui_icon_more_right2_16px, .sui_icon_more_right_16px {
      position: absolute;
      .right(0);
      color: #959595;
    }

    span {
      .line-camp(2);
      max-width: 90%;
    }
  }
  .shipping-guarantee{
    padding: 0.32rem 0;
    &__header{
      display: flex;
      align-items: center;
      position: relative;
      font-size: 14px;
      height: 0.45333rem;
      .sui_icon_guarantee_18px {
        color: @sui_color_lightgreen;
        height: 18px;
        line-height: 18px;
        .font-dpr(36px);
        .margin-r(.21rem);
      }
      .sui_icon_more_right2_16px {
        position: absolute;
        .right(0);
        color: #959595;
      }
    }
    &__content{
      display: flex;
      flex-wrap: wrap;
      padding: 0.11rem 0.6933rem 0;
      font-size: 12px;
      color: #666;
      li{
        display: flex;
        align-items: center;
        margin: 0.21rem 0.64rem 0 0;
        height: 0.32rem;
      }
    }
    &__dot{
      display: inline-block;
      background: @sui_color_lightgreen;
      width: 0.10667rem;
      height: 0.10667rem;
      border-radius: 50%;
      margin-right: 0.11rem;
    }
  }
  .soldbybox-container {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 0.4rem;
    .soldbybox__header {
      margin-top: 0.2133rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      .sui_icon_shop_16px_2 {
        color: #198055;
      }
      p {
        display: flex;
        width: 100%;
      }
      span {
        max-width: 90%;
        margin-left: 0.2133rem;
      }
      .iYRVdP.S-loading__pull-up .S-loading__common-inner {
        margin: 0 auto;
      }
      
    }
    .soldbybox__content {
      display: flex;
      margin: 0.2133rem 0.6667rem 0 0.6667rem ;
      flex-direction: column;
      justify-content: center;
      gap: 8px;
      overflow: hidden;
    }
    .soldbybox__header-local{
      color: #198055;
      font-size: 14px;
    }
    .soldbybox__table {
      display: flex;
      width: 8.0267rem;
      padding: 0.32rem;
      background: var(--sui-color-gray-weak-2, #F6F6F6);
      flex-direction: column;
      font-size: 12px;
      color: var(--sui-color-black-alpha-60, rgba(0, 0, 0, 0.60));
      gap: 0.2133rem;
      line-height: 0.3733rem; /* 116.667% */   
      .cell-title {
        width: 3.2267rem;
        display: inline-block;
        color: #767676;
        word-wrap: break-word;
      }
      .cell-content {
        width: 3.8933rem;
        margin-left: 0.1067rem;
        display: inline-block;
        word-wrap: break-word;
        vertical-align: top;
        color: rgba(0, 0, 0, 0.6);
      }
      .ltr {
        direction: ltr/*rtl:ignore*/;
      }
    }
    .soldbybox__tips {
      display: flex;
      flex-direction: column;
      gap: 0.2133rem;
      color: var(--sui-color-gray-light-1, #959595);
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.2;
    }
    .soldbybox__report {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.2133rem  0.6933rem 0 0.6933rem;
      gap: 2px;
      color: #767676;
      font-size: 12px;
      font-weight: 400;
      line-height: 0.4267rem; 
      span {
        .line-camp(2);
      }
      i {
        vertical-align: middle;
      }
    }
  }
  // .j-go-return-policy {
  //   padding: 0 0.32rem 0 0;
  //   height: 1.123rem;
  //   max-height: 1.123rem;
  // }

  &__returnpolicy-text {
    +p {
      margin-top: .23rem;
    }
  }

  .c-aside.c-aside-full {
    .left(0);
  }
  &__swiper {
    padding: 0.16rem 0 0 25px;
  }
  
  .shipping-swiper-slide {
    &.only-one{
      width: 100%;
    }
    background: #F6F6F6;
    font-size: 12px;
    color: #767676;
    padding: 0.32rem;
    width: 6.09rem;
    height: initial;
    flex-shrink: 0;
    position: relative;
    transition-property: transform;
  }

  &__methods {
    display: flex;
    overflow: hidden;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    & > div {
      margin-left: 8px;
      &:first-child{
        margin-left: 0;
      }
    }
  }

  &__inner-addItem {
    display: flex;
    align-items: center;
    margin-top: .213333rem;
    height: .373333rem;
    font-size: 12px;
    color: #767676;
  }
  &__seller-info {
    margin-top: .213333rem;
    // padding-bottom: .32rem;
    color: #666666;
    margin-left: 25px;
    font-size: 12px;
    display: flex;
  }
  &__report {
    color: @sui_color_link;
    white-space: nowrap;
    margin-left: .2667rem;
  }
}
</style>
