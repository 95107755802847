<template>
  <div
    v-ada="{ level: 1, pos: 0 }"
    class="goods-review__rate"
    :class="{ 'bottom-space': localRatingShow }"
    role="text"
    :aria-label="language.SHEIN_KEY_PWA_17960 + ' ' + commentRankAverage"
  >
    <!-- 总评分 -->
    <div
      v-if="commentRankAverage || currentMultiDimeRate.length"
      aria-hidden="true"
      class="rate-box"
      :class="{ 'is-pop-win': !isDetail }"
    >
      <div>
        <!-- 左侧星级块 -->   
        <div v-if="commentRankAverage">
          <div 
            class="star-box"
            :style="{'flex-wrap': showReviewsGuidance ? 'wrap' : 'nowrap'}"
          >
            <div class="star-num">
              {{ commentRankAverage }}
            </div>
            <div
              class="star-html"
              v-html="ratingStarHTML"
            ></div>
            <div
              v-if="!isDetail && !showReviewsGuidance"
              v-expose="{ id: '1-6-2-55' }"
              class="star-info"
              @click.stop.prevent="jumpToRateExplanation"
            >
              <i class="suiiconfont sui_icon_doubt_16px_2"></i>
            </div>
            <CommentDoubt 
              v-if="!isDetail && showReviewsGuidance"
              :haveDividingLine="haveDividingLine"
              :language="language"
            />
          </div>
        </div>
        <!-- 排名 下方 -->
        <div
          v-if="isShowRank && currentMultiDimeRate.length"
          class="rank-box"
        >
          <div
            class="rank-text" 
            v-html="rankText"
          ></div>
          <span class="rank-progress">
            <span 
              class="rank-progress-rate" 
              :style="{ width: progress }"
            >
              <span class="icon">
                <i class="suiiconfont sui_icon_like_completed_16px"></i>
              </span>
            </span>
          </span>
        </div>
      </div>
      <!-- 中间分割线 -->
      <div
        v-if="haveDividingLine"
        class="vert-split-box"
      >
        <div class="vert-split-line"></div>
      </div>

      <!-- 右侧多维评分块 -->
      <ul
        v-if="currentMultiDimeRate.length"
        v-expose="getRateTagAnalysisData()"
        class="multi-rate-box"
        :class="{ 'full-width': !commentRankAverage }"
      >
        <li
          v-for="(tag, index) in currentMultiDimeRate"
          :key="index"
          class="multi-rate-item"
        >
          <span>{{ tag.nameLocal }}</span>
          <span class="num">{{ tag.score }}</span>
        </li>
      </ul>
      <!-- 排名 右侧 -->
      <div
        v-if="isShowRank && !currentMultiDimeRate.length"
        class="rank-box rank-box-right"
      >
        <div
          class="rank-text" 
          v-html="rankText"
        ></div>
        <span class="rank-progress">
          <span 
            class="rank-progress-rate" 
            :style="{ width: progress }"
          >
            <span class="icon">
              <i class="suiiconfont sui_icon_like_completed_16px"></i>
            </span>
          </span>
        </span>
      </div>
    </div>

    <!-- 中间分割线 右 下 模块都有的时候才出现-->
    <div 
      v-if="(currentMultiDimeRate.length || (isShowRank && !currentMultiDimeRate.length)) && (hasFit && hasTrueToSize)" 
      class="middle-line"
    ></div>
    <!-- 尺码匹配评分 -->
    <div
      v-if="hasFit && hasTrueToSize"
      aria-hidden="true"
      :class="'fit-box'"
    >
      <p class="title">
        {{ language.SHEIN_KEY_PWA_15004 }}
      </p>

      <ul class="fit-rate-box">
        <li
          v-for="(fitItem, index) in fitRates"
          :key="index"
          class="fit-rate-item"
        >
          <em>{{ fitItem.label }}</em>
          <div class="fit-rate-proress">
            <span>
              <i
                :style="{ width: fitItem.percentage }"
                class="fit-small"
              ></i>
            </span>
            <b v-if="rtl">%{{ fitItem.roundPercentage }}</b>
            <b v-else>{{ fitItem.roundPercentage }}%</b>
          </div>
        </li>
      </ul>
    </div>

    <!-- 底部本地评分栏 -->
    <div
      v-if="localRatingShow"
      class="local-rating-box"
      :class="localReviewBarClassName"
      @click="clickLocalHandle"
    >
      <!-- 分割线 -->
      <div class="split-line"></div>

      <div class="star-box">
        <p>{{ language.SHEIN_KEY_PWA_22254 }}</p>
        <em>{{ localRating }}</em>
        <div v-html="localRatingStarHTML"></div>
      </div>
      <div class="view-button">
        <span>{{ language.SHEIN_KEY_PWA_22255 }}</span>
        <i class="suiiconfont sui_icon_more_right_12px_2"></i>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { markPoint } from 'public/src/services/mark/index.js'
import schttp from 'public/src/services/schttp'
import CommentDoubt from '../CommentDoubt'
import { template } from '@shein/common-function'

/* globals GB_SHEIN_counting_star_suiicon */

const { GB_cssRight, SiteUID } = gbCommonInfo

export default {
  name: 'RatePanel',
  components: {
    CommentDoubt
  },
  props: {
    hasTrueToSize: {
      type: Boolean,
      default: false
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    localReviewBarClassName: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    SiteUID,
    rtl: !!GB_cssRight,
    commentRankBaseline: 0, // 切线
  }),
  computed: {
    ...mapGetters('newProductDetail', ['commentOverView', 'fsAbt']),
    ...mapGetters('newProductDetail/common', ['detail', 'language', 'hasFit', 'tsp', 'currentCat']),
    ...mapGetters('newProductDetail/Reviews', [
      'reviewsBaseInfo',
      'commentOverViewLocal',
      'contentLabel',
      'rateExplanationLink',
      'showLocalReviews'
    ]),
    ...mapGetters('newProductDetail/RatePanel', [
      'showLocalRating',
      'localRating',
      'hasLocalComments'
    ]),
    ...mapState('newProductDetail/CommentPopup', ['onlyLocalStatus']),
    localRatingShow() {
      return this.showLocalRating && this.isDetail
    },
    haveDividingLine(){
      return (this.commentRankAverage && !!this.currentMultiDimeRate.length) || (this.isShowRank && !this.currentMultiDimeRate.length)
    },
    globalRating() {
      return this.commentOverView?.comment_rank_average || null
    },
    commentRankAverage() {
      const { commentOverView, localRating, isDetail } = this
      if (!isDetail && this.onlyLocalStatus) {
        return localRating || null
      }
      return commentOverView?.comment_rank_average || null
    },
    // 星级html
    ratingStarHTML() {
      const IS_RW = this.reviewsBaseInfo?.isRW
      const { commentRankAverage } = this
      return GB_SHEIN_counting_star_suiicon(commentRankAverage, IS_RW ? 20 : 15, IS_RW ? '' : 2)
    },
    // 本地评分星级html
    localRatingStarHTML() {
      const IS_RW = this.reviewsBaseInfo?.isRW
      const { localRating } = this
      if (!localRating) {
        return ''
      }
      return GB_SHEIN_counting_star_suiicon(localRating, IS_RW ? 20 : 15, IS_RW ? '' : 2)
    },
    currentComment() {
      const { commentOverView, isDetail } = this
      if (!isDetail && this.onlyLocalStatus) {
        return this.commentOverViewLocal
      }
      return commentOverView
    },
    // 尺码匹配分数项
    fitRates() {
      const { hasFit, hasTrueToSize, currentComment, getFitPercentageRoundNum, language } = this
      const percentOverallFit = currentComment?.percent_overall_fit
      if (!hasFit || !hasTrueToSize || !percentOverallFit) {
        return []
      }

      return [
        {
          label: language.SHEIN_KEY_PWA_15002,
          percentage: percentOverallFit.small,
          roundPercentage: getFitPercentageRoundNum(percentOverallFit.small)
        },
        {
          label: language.SHEIN_KEY_PWA_15001,
          percentage: percentOverallFit.true_size,
          roundPercentage: getFitPercentageRoundNum(percentOverallFit.true_size)
        },
        {
          label: language.SHEIN_KEY_PWA_15003,
          percentage: percentOverallFit.large,
          roundPercentage: getFitPercentageRoundNum(percentOverallFit.large)
        }
      ]
    },
    // 当前多维评论分数（本地or全域）
    currentMultiDimeRate() {
      const { currentComment = {}, contentLabel } = this
      const { sel_tag_score } = currentComment
      if (!contentLabel || !Array.isArray(sel_tag_score)) {
        return []
      }

      const rates = []
      sel_tag_score.forEach(tag => {
        const { id = '', tag_score = '' } = tag || {}
        const label = contentLabel[id] || {}
        const { language_name = '', name_en = '' } = label
        if (id && tag_score && name_en) {
          rates.push({
            id,
            score: tag_score,
            name: name_en,
            nameLocal: language_name
          })
        }
      })

      return rates.slice(0, 3)
    },
    
    rankRate() {
      return this.tsp?.['4448'] ?? ''
    },
    rankText() {
      // const category = this.tsp?.['4447'] ?? ''
      const category = this.currentCat?.cat_name ?? ''
      let text = ''
      if(+this.commentRankAverage === 5 || this.rankRate === '0') {
        text = `<span class="r-color">${this.language.SHEIN_KEY_PWA_29381}</span> in ${category}`
      } else if(4 <= +this.commentRankAverage < 5) {
        text = `<span class="r-color">${template(this.rankRate, this.language.SHEIN_KEY_PWA_29382)}%</span> ${template(category, this.language.SHEIN_KEY_PWA_29384)}`
      }
      return text
    },

    progress() {
      return (+this.commentRankAverage === 5 ? 100 : 100 - this.rankRate ) + '%'
    },

    rankAbt() {
      return this.fsAbt?.Reviewchange?.param?.Raterank === 'Show'
    },
    showReviewsGuidance() {
      return ['pwde', 'mpl'].includes(this.SiteUID)
    },
    // 是否展示排名文案
    isShowRank() {
      if(
        this.rankAbt && 
        (this.rankRate && this.rankRate <= this.commentRankBaseline) &&
        (this.commentRankAverage >= 4)
      ) {
        return true
      }
      
      return false
    }
  },
  mounted() {
    this.exposeLocalReviews()
    this.getApolloConfig()
  },
  methods: {
    getFitPercentageRoundNum(text) {
      if (typeof text !== 'string' || !text) {
        return 0
      }
      const num = text.replace('%', '')
      if (isNaN(num)) {
        return 0
      }
      return Math.round(num)
    },
    clickLocalHandle() {
      daEventCenter.triggerNotice({
        daId: '1-6-7-43',
        extraData: {
          status: this.hasLocalComments ? 'on' : 'unable'
        }
      })
    },
    exposeLocalReviews() {
      if (!this.showLocalReviews || !this.isDetail) return
      const { globalRating, localRating } = this
      daEventCenter.triggerNotice({
        daId: '1-6-7-42',
        extraData: {
          global_rating: globalRating,
          local_rating: localRating
        }
      })
    },
    // 跳转至评分说明页
    jumpToRateExplanation() {
      const { rateExplanationLink } = this
      if (!rateExplanationLink) {
        return
      }
      // 埋点上报
      daEventCenter.triggerNotice({ daId: '1-6-2-56' })
      markPoint('toNextPageClick', 'public')
      // 跳转
      window.location.href = rateExplanationLink
    },
    // 多维评分标签曝光事件
    getRateTagAnalysisData() {
      const catId = this.detail?.cat_id || ''
      if (!catId) {
        return {}
      }
      return { id: this.isDetail ? '1-6-7-53' : '1-6-2-53', data: { cat_id: catId } }
    },
    async getApolloConfig() {
      let res = await schttp({
        method: 'POST',
        url: '/api/config/apolloConfig/query',
        data: { apolloKeys: 'SIZE_TIPS_CONFIG' }
      })
      const { SIZE_TIPS_CONFIG } = res?.info ?? {}
      this.commentRankBaseline = SIZE_TIPS_CONFIG?.commentRankValue || 20

      this.$emit('getIsShowRank', this.isShowRank)
    },
  }
}
</script>

<style lang="less">
/* stylelint-disable  selector-max-specificity, selector-max-type */
.goods-review__rate {
  .flexbox();
  flex-flow: row wrap;
  padding: 0.32rem 0.426667rem;
  background-color: #f6f6f6;
  margin: 0.16rem 0.32rem 0.375rem 0.32rem;
  justify-content: space-between;
  position: relative;

  &.bottom-space {
    padding-bottom: 1.386667rem;
    box-shadow: 0 0.053333rem 0.106667rem 0 rgba(144, 144, 144, 0.3);
    /* rw:begin */
    box-shadow: none;
  }

  [class*='icon_star'] {
    display: inline-block;
    color: @sui_color_honor;
  }

  [class*='sui_icon_star'] {
    /* rw:begin */
    font-size: 0.4rem !important; /* stylelint-disable-line declaration-no-important */
  }

  // 总评分
  .rate-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    // 左侧星级块
    .star-box {
      display: flex;
      align-items: center;
    }
    .star-num {
      .font-dpr(44px);
      font-weight: 700;
      line-height: 1.1;
      color: #222;
      margin-right: 0.16rem;
      /* rw:begin */
      color: @sui_color_honor;
      font-family: 'Adieu';
    }
    .star-html {
      white-space: nowrap;
    }
    .star-info {
      margin-left: 0.16rem;
      .font-dpr(28px);
      color: @sui_color_gray_light2;
    }
    // 排名百分比
    .rank-box {
      color: #000;
      font-family: "SF Pro";
      font-size: 0.26667rem;
      line-height: normal;
      padding-top: 0.11rem;
      .rank-text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .r-color {
        color: #a86104;
        font-weight: 700;
      }
      .rank-progress {
        display: block;
        width: 3.52rem;
        height: 0.10667rem;
        flex-shrink: 0;
        border-radius: 0.05333rem;
        background: #E5E5E5;
        margin: 0.21rem 0 ;
        .rank-progress-rate {
          height: 100%;
          display: block;
          position: relative;
          background: linear-gradient(90deg, #EAC99C 0%, #CC8C39 100%);
          border-radius: 0.05333rem;
          .icon {
            position: absolute;
            top: 0;
            right: 0;
            transform: translateY(calc(-50% + 0.10667rem/2));
            width: 0.42667rem;
            height: 0.42667rem;
            background: #A86104;
            border-radius: 50%;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #fff;
            .suiiconfont {
              font-size: 10px;
            }
          }
        }
      }
    }
    .rank-box-right {
      width: 3.70667rem;
    }
    // 中间分割线
    .vert-split-box {
      height: 100%;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0.64rem;
      .vert-split-line {
        height: 100%;
        width: 0;
        border-left: 0.013333rem solid @sui_color_gray_weak1;
      }
    }

    // 右侧多维评分块
    .multi-rate-box {
      overflow: hidden;
      &.full-width {
        width: 100%;
        padding-left: 0;
      }
    }
    .multi-rate-item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .font-dpr(24px);
      color: @sui_color_gray_dark2;
      margin-top: 0.16rem;
      &:first-child {
        margin-top: 0;
      }

      span {
        display: inline-block;
        width: 2.5333rem;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .num {
        text-align: right;
        padding-left: 0.426667rem;
        font-weight: 590;
        color: #000;
      }
    }
  }

  .rate-box.is-pop-win {
    .vert-split-box {
      /* rw:begin */
      display: none;
    }

    .star-html {
      transform: perspective(400px);
    }
  }

  // 中间分割线
  .middle-line {
    width: 100%;
    border-bottom: .013333rem solid #e5e5e5;
    margin-top: 0.32rem;
  }
  // 尺码匹配评分
  .fit-box {
    flex: 1;
    color: @sui_color_gray_dark2;
    margin-top: 0.32rem;
    padding-bottom: 0.106667rem;
    .font-dpr(20px);

    .fit-rate-box {
      display: flex;
      align-items: flex-end;
    }

    li {
      flex: 1;
      margin-left: 0.64rem;
      &:first-child {
        margin-left: 0;
      }
    }

    .fit-rate-proress {
      display: flex;
      align-items: center;
      font-family: "SF Pro";
    b {
      display: block;
      width: 24px;
      font-weight: normal;
      .font-dpr(20px);
      color:#000;
      font-family: "SF Pro";
      font-size: 0.26667rem;
      font-style: normal;
      font-weight: 590;
      line-height: normal;
      padding-left: 0.11rem;
    }

    span {
      display: block;
      width: 100%;
      height: 0.106667rem;
      margin-top: 0.05rem;
      background: #e5e5e5;
      border-radius: 0.053333rem;
      overflow: hidden;

      i {
        height: 0.106667rem;
        display: block;
        background: #222;
        /*rw:begin*/
        background: @sui_color_honor;
      }
    }
  }

    em {
      display: block;
      width: 2rem;
      margin-top: 0.106667rem;
      .font-dpr(20px);
      white-space: normal;
      color: #000;
      font-family: "SF Pro";
    }

  }
  // TODO-新样式全量后删除
  // 尺码匹配评分-旧样式
  .fit-box-vertical {
    margin-top: 0.32rem;
    padding-bottom: 0.106667rem;
    width: 100%;

    .title {
      color: #767676;
      padding-bottom: 0.11rem;
    }

    .fit-rate-box {
      width: 100%;
    }
    .fit-rate-proress {
      display: flex;
      align-items: center;
      font-family: "SF Pro";
    li {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      color: #333;
      padding-bottom: 0.106667rem;
    }
    em {
      display: inline-block;
      width: 2rem;
      color: #333 /*rw:#222*/;
      .font-dpr(22px);
      white-space: normal;
      vertical-align: middle;
      color:#000;
    }
    span {
      // width: calc(100% - 2rem - 24px);
      width: 100%;
      height: 0.21rem;
      background: #e5e5e5;

      i {
        height: 0.21rem;
        display: block;
        background: #222;
        /*rw:begin*/
        background: @sui_color_honor;
      }
    }
    b {
      width: 24px;
      font-weight: normal;
      color: #333;
      margin-left: 0.21333rem;
    }
  }
  }

  // 底部本地评分栏
  .local-rating-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 0.32rem;
    height: 1.066667rem;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    .font-dpr(24px);
    background-color: #fff;

    .star-box {
      display: flex;
      align-items: center;

      em {
        display: block;
        margin: 0 0.106667rem;
        font-weight: 700;
      }
    }

    .view-button {
      font-weight: 600;
      color: @sui_color_gray_dark2;
    }

    .split-line {
      display: none;
      /* rw:begin */
      display: block;
      position: absolute;
      top: 0;
      left: 0.32rem;
      width: calc(100% - 0.64rem);
      height: 0.013333rem;
      background-color: @sui_color_gray_weak1;
    }
  }

  /* rw:begin */
  background-color: #fff;
  border-radius: 0.266667rem;
  border: 0.013333rem solid rgba(0, 0, 0, 0.08);
}
</style>
