<template>
  <div
    class="product-middle j-direct-box"
    :class="[showAllDetail ? 'product-middle__show' : 'product-middle__fold']"
  >
    <BuyBox v-if="allDataReady && showBuyBoxSellers" />
    <div
      v-if="isShowNewStoreAndBrand"
      class="product-middle__wrap"
      :class="{ 'no-margin-bottom': showCatSelectionTagMiddle }"
    >
      <!-- 品牌集成店移到顶部  -->
      <BrandSeriesEnter
        :class="{ 'brand-border-bottom': showCatSelectionTagMiddle }"
        :data="brandMapInfo"
        is-new-store-brand
        hide-jump-text
      />
    </div>
    <CatSelectionTagEnter
      v-if="showCatSelectionTagMiddle"
      :tag-data="cccCatSelectionTag"
      :page-common-info="pageCommonInfo"
      :show-selection-drawer="showSelectionDrawer"
      :language="language"
      @handleSelectionDrawer="handleSelectionDrawer"
    />
    <!-- <PromotionEnter v-if="showPromotionEnterLayer" /> -->
    <!-- <DesignInfoEnter
      v-if="showMiddleDesignerInfo"
      :language="language"
      :designer-info="designerInfo"
      position-style="single"
      @goToDesignerPage="goToDesignerPage"
    /> -->
    <div 
      v-if="shipAndDetailSortType == 2 || (shipAndDetailSortType != 2 && notIN && hasShippingData)"
      class="product-middle__wrap"
    >
      <!-- <Subscription v-if="detail.is_subscription === '1'" /> -->
      <ShippingEnter v-if="shipAndDetailSortType != 2 && notIN" />
      <MiddleAttr v-if="shipAndDetailSortType == 2" />
    </div>
    <template v-if="goodsReady && shipAndDetailSortType == 2">
      <template v-if="MAIN_BFF_APOLLO.v1">
        <NewParallelImport
          v-if="show_parallel_import"
          :language="language_v2"
          :router="$router"
          :exposeInfo="{
            goods_id: goods_id,
            mall_code: mall_code,
          }"
        />
      </template>
      <template v-else>
        <Parallelimport 
          v-if="showParallelimport"
          :language="language"
          :click="parallelimportClick"
          :exposeInfo="parallelimportExposeInfo" 
        />
      </template>
    </template>
    <!-- ccc推荐位 -->
    <!-- <RecommendCcc
      v-if="showRecommendCccGoods2 && goodsReady"
      :goods-item-config="{
        showLocalSeller: false
      }"
      :hide-holder="true"
      poskey="ProductDetailBelowPolicyFloor"
      position-code="GOODS-2"
    /> -->
    <div class="j-direct-box">
      <div 
        v-if="shipAndDetailSortType != 2 || (shipAndDetailSortType == 2 && notIN && hasShippingData)"
        class="product-middle__wrap"
      >
        <ShippingEnter v-if="shipAndDetailSortType == 2 && notIN" />
        <MiddleAttr v-if="shipAndDetailSortType != 2" />
      </div>
      <template v-if="goodsReady && shipAndDetailSortType != 2">
        <template v-if="MAIN_BFF_APOLLO.v1">
          <NewParallelImport
            v-if="show_parallel_import"
            :language="language_v2"
            :router="$router"
            :exposeInfo="{
              goods_id: goods_id,
              mall_code: mall_code,
            }"
          />
        </template>
        <template v-else>
          <Parallelimport 
            v-if="showParallelimport"
            :language="language"
            :click="parallelimportClick"
            :exposeInfo="parallelimportExposeInfo" 
          />
        </template>
      </template>
      <DetailsPictures
        v-if="pageComponents.moreDetailConf && pageComponents.moreDetailConf.partList && pageComponents.moreDetailConf.partList.length"
        :language="language"
      />

      <!-- Sign Board: Store/Brand/Series -->
      <!-- 依赖推荐位B类型时(p1) Store/Brand/Series 放置于 Reviews 下方 -->
      <DetailSignBoard
        v-if="allDataReady && detailSignBoardPosition === 'p1' && signData"
        :config="signConfig"
        :sign-data="signData"
      />

      <!-- ccc推荐位 -->
      <RecommendCcc
        v-if="showRecommendCccGoods3 && goodsReady"
        poskey="ProductDetailBelowModelFloor"
        position-code="GOODS-3"
      />

      <StyleGallery
        v-if="lookbookInfo && lookbookInfo.length && !showHotPerson"
        :style-merge-galley-and-reviews="cStyleMergeGalleyAndReviews"
      />
      <!-- 上方相似推荐 -->
      <!-- <SimilarItems
        v-if="allDataReady && similarItemsLayout[0]"
        :multi-line="similarItemsMultiLine"
      /> -->
      <!-- 上方组合购 -->
      <!-- <FrequentBoughtTogether v-if="allDataReady && frequentlyBoughtLayout[0]" /> -->
      <Reviews
        v-if="showReviews"
        ref="reviews"
        class="product-middle__reviews"
        :class="{
          'review-lookbook': lookbookInfo.length > 0,
          no_margin: cStyleMergeGalleyAndReviews
        }"
      />

      <CartEntry v-if="isReviewBelow && allDataReady" />
      
      <!-- 下方相似推荐 -->
      <!-- <SimilarItems
        v-if="allDataReady && similarItemsLayout[1]"
        :multi-line="similarItemsMultiLine"
      /> -->
      <!-- Sign Board: Store/Brand/Series -->
      <DetailSignBoard
        v-if="allDataReady && detailSignBoardPosition === 'p2' && signData"
        :config="signConfig"
        :sign-data="signData"
      />
      <!-- CCC Banner 位 -->
      <CccBanner />

      <!-- 新组合购，和旧组合购互斥 -->
      <ComboBuy v-if="allDataReady && isShowNewComboBuy" />
      <!-- 下方组合购 -->
      <!-- <FrequentBoughtTogether v-if="allDataReady && frequentlyBoughtLayout[1]" /> -->
      <GetTheLook v-if="allDataReady && !hideOldGtlAndOutfit && (gtlAloneByAbt || !recommendWithSoleType)" />
      <StyleToMatchGroups v-if="matchingStyleBlockShow" />
      <CartEntry v-if="isRecommendAbove && allDataReady" />
    </div>
    <div
      v-if="!showAllDetail"
      class="product-middle__more"
      da-event-click="1-6-4-20"
      @click="viewMore"
    >
      {{ language.SHEIN_KEY_PWA_15698 }}
      <i class="suiiconfont sui_icon_more_down_16px"></i>
    </div>

    <!-- 以下为各类弹窗 ⬇️ 后续优化方向为 弹窗都放到顶级组件 container 中作用 如果不涉及页面交互的话 最好做成实例调用形式（js指令调用） -->
    <template v-if="sizeGuidePopReady && !quickAddState">
      <SizeGuideDrawer
        v-model="showSizeGuideDrawerProxy"
        :show-size-guide-drawer="showSizeGuideDrawerProxy"
        :language="language"
        :pageComponents="pageComponents"
        :sizeGuidPlanA="sizeGuidPlanA"
        :modelContent="model"
        :currentLocalCountry="currentLocalCountry"
        :moduleRenderConfig="moduleRenderConfig"
        :localsize="localsize"
        :country="currentCountry"
        :skc-sale-attr="skcSaleAttr"
        :size-info-des="sizeInfoDes"
        :sizepriority="sizeConfig?.sizepriority"
        :size-guide-fit="sizeConfig?.sizeguidefit"
        :detail="detail"
        :is-new-size-local="isNewSizeLocal"
        @changeSizeUnit="updateCurrentLocalUnit"
        @changeLocalSize="updateLocalSize"
      />
    </template>
    <!-- 评论弹窗  切换商品重新渲染 -->
    <template v-if="viewCommentReady">  
      <CommentPop />
      <CommentImgPop />
    </template>
    <!-- 鞋子尺码推荐 -->
    <template v-if="sizeRecommandPopReady">
      <SizeRecommend
        v-model="sizeRecommand"
        :country="currentCountry"
        :is-new-size-local="isNewSizeLocal"
      />
    </template>
    <!--内衣自有尺码推荐-->
    <template v-if="braSizeRecommendPopReady">
      <BraSizeRecommend v-model="braSizeRecommend" />
    </template>
    <!--check my size和size guide混合入口推荐-->
    <template v-if="checkSizeAndSizeGuidePopReady">
      <checkSizeAndSizeGuide v-model="checkSizeAndSizeGuide" />
    </template>
    <CartBag
      v-if="showFloatCartIcon"
      ref="detailCartBag"
      :goods-id="detail.goods_id"
      :class="{ 'product-middle__cart-bag': existEstimatedPrice }"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'

import ShippingEnter from '../middle/innerComponents/ShippingEnter'
import MiddleAttr from './innerComponents/MiddleAttr/index.vue'
import BrandSeriesEnter from 'public/src/pages/goods_detail/middlect/components/DetailSignBoard/components/BrandSeriesEnter'
// import PromotionEnter from './innerComponents/PromotionEnterLayer'
// import DesignInfoEnter from './innerComponents/MiddleAttr/components/DesignInfoEnter.vue'
// import Subscription from 'public/src/pages/goods_detail/middlect/components/Subscription.vue'
import RecommendCcc from './innerComponents/RecommendCccEntry'
import StyleGallery from './innerComponents/MiddleAttr/components/StyleGallery'
// import SimilarItems from 'public/src/pages/goods_detail/RecommendRelatives/SimilarItems'
// import FrequentBoughtTogether from 'public/src/pages/goods_detail/RecommendRelatives/FrequentBoughtTogether.vue'
import DetailsPictures from './innerComponents/DetailsPictures'
import DetailSignBoard from 'public/src/pages/goods_detail/middlect/components/DetailSignBoard'
import Reviews from './innerComponents/Reviews/index.vue'
import CccBanner from 'public/src/pages/goods_detail/components/cccBanner.vue'
import GetTheLook from './innerComponents/GetTheLook/GetTheLook.vue'
import StyleToMatchGroups from './innerComponents/MiddleAttr/components/StyleToMatchGroups.vue'
import ComboBuy from 'public/src/pages/goods_detail/RecommendRelatives/ComboBuy/index.vue'
import BuyBox from 'public/src/pages/goods_detail/RecommendRelatives/BuyBox/BuyBoxIndex.vue'
import CatSelectionTagEnter from 'public/src/pages/goods_detail/components/middle/innerComponents/MiddleAttr/components/CatSelectionTagEnter'
import CartBag from 'public/src/pages/product_list_v2/components/CartBag/index.vue'
// todo 优化 不挂在进this 每个使用实例单独引用
import { selectionPopup } from 'public/src/pages/components/product/select_quick_add'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import Parallelimport from 'public/src/pages/goods_detail/components/middle/innerComponents/Parallelimport/index.vue'
import NewParallelImport from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/Parallelimport/index.vue'
if (typeof window !== 'undefined') {
  Vue.prototype.$selectionPopup = selectionPopup
}

export default {
  name: 'MiddleContent',
  components: {
    ComboBuy,
    BuyBox,
    Parallelimport,
    NewParallelImport,
    ShippingEnter,
    MiddleAttr,
    BrandSeriesEnter,
    // PromotionEnter,
    // DesignInfoEnter,
    // Subscription,
    RecommendCcc,
    StyleGallery,
    // SimilarItems,
    // FrequentBoughtTogether,
    DetailsPictures,
    DetailSignBoard,
    Reviews,
    CccBanner,
    GetTheLook,
    StyleToMatchGroups,
    CatSelectionTagEnter,
    CartBag,
    // 下面为弹窗
    SizeGuideDrawer: prefetchResource.importAsyncComponent({
      chunkName: 'SizeGuideDrawer',
      componentFactory: () =>
        import(
          /* webpackChunkName: "SizeGuideDrawer" */ 'public/src/pages/goods_detail/components/drawer/innerComponents/SizeGuide/SizeGuideDrawer.vue'
        ) //'./components/SizeGuideDrawer'
    }),
    CommentPop: prefetchResource.importAsyncComponent({
      chunkName: 'CommentPop',
      componentFactory: () =>
        import(
          /* webpackChunkName: "CommentPop" */ './innerComponents/Reviews/components/Pop/CommentPop.vue'
        )
    }),
    CommentImgPop: prefetchResource.importAsyncComponent({
      chunkName: 'CommentImgPop',
      componentFactory: () =>
        import(
          /* webpackChunkName: "CommentImgPop" */ './innerComponents/Reviews/components/Pop/CommentImgPop.vue'
        )
    }),
    SizeRecommend: () =>
      import(
        /* webpackChunkName: "SizeRecommend" */ './innerComponents/SizeGuide/components/SizeRecommend.vue'
      ),
    BraSizeRecommend: () =>
      import(
        /* webpackChunkName: "BraSizeRecommend" */ './innerComponents/SizeGuide/components/BraSizeRecommend.vue'
      ),
    checkSizeAndSizeGuide: () =>
      import(
        /* webpackChunkName: "checkSizeAndSizeGuide" */ './innerComponents/SizeGuide/components/CheckSizeAndSizeGuide/index.vue'
      ),
    CartEntry: () =>
      import(
        /* webpackChunkName: "CartEntry" */ 'public/src/pages/goods_detail/components/CartEntry.vue'
      )
  },
  data() {
    const { lang } = gbCommonInfo
    const notIN = lang != 'in' // 印度地区不现实shippingEnter
    return {
      differencePrice: 0,
      showSelectionDrawer: false,
      notIN,
      showSizeGuideDrawerProxy: false
    }
  },
  computed: {
    // BFF新链路
    ...mapState('productDetail', ['MAIN_BFF_APOLLO']),
    ...mapGetters('productDetail/Middle', ['show_parallel_import']),
    ...mapGetters('productDetail/common', ['language_v2', 'goods_id', 'mall_code']),
    
    ...mapState('newProductDetail/common', [
      'isFoldDetail',
      'goodsReady',
      'quickAddState',
      'currentLocalCountry'
    ]),
    ...mapState('newProductDetail', [
      'sizeGuideReady',
      'checkSizeAndSizeGuideReady',
      'sizeRecommandReady',
      'braSizeRecommendReady'
    ]),
    ...mapState('newProductDetail/SizeGuide', [
      'showSizeGuideDrawer',
      'checkSizeAndSizeGuide',
      'braSizeRecommend',
      'sizeRecommand'
    ]),
    ...mapGetters('newProductDetail/SizeBox', ['sizeConfig']),
    ...mapGetters('newProductDetail', ['fsAbt', 'cccCatSelectionTag', 'unifiedRecommend']),
    ...mapGetters('newProductDetail/common', [
      'mallCode',
      'hideOldGtlAndOutfit',
      'goodsId',
      'language',
      'showStoreAndBrand',
      'moduleRenderConfig',
      'brandMapInfo',
      'detail',
      'signBoardStyle',
      'currentCountry',
      'saleAttrList',
      'lookbookInfo',
      'isNewSizeLocal',
      'showAuthenticbrand',
      'pageCommonInfo',
      'isBrandHasTargetTag',
      'isStoreBusinessBrand',
      'allDataReady',
      'showBuyBoxSellers',
      'isShowNewComboBuy',
    ]),
    ...mapGetters('newProductDetail/Middle', [
      'designerInfo',
      // 'showMiddleDesignerInfo',
      // 'showRecommendCccGoods2',
      'showRecommendCccGoods3',
      // 'frequentlyBoughtLayout',
      'similarItemsLayout',
      'signConfig',
      'signData',
      'showReviews',
      'matchingStyleBlockShow',
      'showParallelimport',
      'showCatSelectionTagMiddle',
      'recommendWithSoleType'
    ]),
    ...mapGetters('newProductDetail/Price', ['estimatedInfo']),
    // ...mapGetters('newProductDetail/PromotionEnter', ['showPromotionEnterLayer']),
    ...mapGetters('newProductDetail/ShippingEnter', ['shippingDetail']),
    ...mapGetters('newProductDetail/SizeGuide', ['localsize']),
    ...mapGetters('newProductDetail/MiddleAttr', ['sizeInfoDes', 'model', 'pageComponents']),
    // 弹窗状态
    ...mapState('newProductDetail/CommentPopup', ['viewCommentReady']),
    ...mapGetters('newProductDetail/BagData', ['isReviewBelow', 'isRecommendAbove']),
    parallelimportExposeInfo() {
      return {
        goods_id: this.goodsId,
        mall_code: this.mallCode,
      }
    },
    showAllDetail() {
      return !this.isFoldDetail
    },
    shipAndDetailSortType() {
      return this.pageComponents?.modulesSortConf?.shipAndDetailSortType || null
    },
    detailSignBoardPosition() {
      return this.signBoardStyle.position
    },
    sizeGuidPlanA(){
      return this.fsAbt?.sizeguideyouhua520?.param?.sizeguideyouhua520 === 'planA'
    },
    showFloatCartIcon() {
      return this.fsAbt?.hovercart?.p?.hovercart === 'show'
    },
    showHotPerson() {
      return this.fsAbt?.Reviewchange?.param?.BuyerShow === 'Show'
    },
    existEstimatedPrice() {
      const { estimatedCompProps = {} } = this.estimatedInfo || {}
      const poskey = this.fsAbt?.EstimatedNothreShowType?.p?.EstimatedNothreShowType || ''
      return (
        ['New1', 'New2'].includes(poskey) &&
        estimatedCompProps?.price?.addOnAmountWithSymbol &&
        estimatedCompProps?.price?.needPrice
      )
    },
    hasShippingData() {
      return !!this.shippingDetail?.shippingMethods?.length
    },
    gtlAloneByAbt() {
      return this.unifiedRecommend?.gtlAloneByAbt || false
    },
    skcSaleAttr() {
      return this.saleAttrList?.skcSaleAttr || []
    },
    cStyleMergeGalleyAndReviews() {
      // 只有图片和评论组件中间不存在其他模块，才合并样式
      return (
        this.lookbookInfo?.length > 0 &&
        !this.similarItemsLayout?.[0]
      )
    },
    // 以下状态待优化 为弹窗状态 ⬇️
    sizeGuidePopReady() {
      return this.sizeGuideReady
    },
    sizeRecommandPopReady() {
      return this.sizeRecommandReady
    },
    braSizeRecommendPopReady() {
      return this.braSizeRecommendReady
    },
    checkSizeAndSizeGuidePopReady() {
      return this.checkSizeAndSizeGuideReady
    },
    // 判断是否展示独立品牌-新样式（位于物流模块上方）
    isShowNewStoreAndBrand() {
      return this.brandMapInfo && this.showAuthenticbrand && /** 只有命中tag或品牌集成店 */(this.isBrandHasTargetTag || this.isStoreBusinessBrand)
    },
    // // 相似购是否使用两行样式
    // similarItemsMultiLine() {
    //   return this.unifiedRecommend?.similarRows === 'two'
    // },
  },
  watch: {
    goodsId: {
      handler() {
        this.handleHeelHeightRange()
        this.$nextTick(() => {
          // this.showPromotionEnterLayer && daEventCenter.triggerNotice({ daId: '1-6-4-40' })
          if(this.showFloatCartIcon) {
            this.$refs?.detailCartBag?.exposeDetailCarIcon?.()
          }
        })
      },
      immediate: true
    },
    showSizeGuideDrawerProxy(newVal) {
      this.updateShowSizeGuideDrawer(newVal)
    },
    showSizeGuideDrawer(newVal) {
      this.showSizeGuideDrawerProxy = newVal
    },
  },
  mounted() {
    this.prefetchResource()
    if (this.isShowDesignerInfo) {
      daEventCenter.triggerNotice({ daId: '1-6-4-69' })
    }
    window.vBus && window.vBus.$on('triggerAddCompletedFloatCart', this.triggerAddCompletedFloatCart)
  },
  destroyed() {
    window.vBus &&
      window.vBus.$off('triggerAddCompletedFloatCart', this.triggerAddCompletedFloatCart)
  },
  methods: {
    ...mapMutations('productDetail', ['assignState']),
    ...mapMutations('newProductDetail/common', [
      'updateIsFoldDetail',
      'updateCurrentLocalCountry',
      'updateCurrentLocalUnit'
    ]),
    ...mapMutations('newProductDetail/SizeBox', [
      'updateHeelHeightStatus',
      'updateHeelHeight',
      'updateCmInchInfo'
    ]),
    ...mapMutations('newProductDetail/SizeGuide', ['updateShowSizeGuideDrawer']),
    parallelimportClick() {
      daEventCenter.triggerNotice({ daId: '1-6-1-175', bindData: this.parallelimportExposeInfo })
      const { langPath } = gbCommonInfo
      this.$router?.push?.(`${langPath}/product/article/2008`)
    },
    prefetchResource() {
      // 预加载资源 评论弹窗
      if (this.showReviews) {
        prefetchResource.listen({
          el: this.$el,
          prefetchList: [
            {
              chunkName: 'CommentPop',
              relType: 'prefetch'
            }
          ],
          delay: 3000,
          prefetchCallback: ({ status, info }) => {
            console.log('prefetchCallback', status, info)
          }
        })
      }
    },
    goToDesignerPage() {
      if (!this.designerInfo.sheinx_design_id) return
      const { langPath } = gbCommonInfo
      daEventCenter.triggerNotice({ daId: '1-6-4-70' })
      const href = `${langPath}/designer/${this.designerInfo.sheinx_design_id}`
      this.$router.push(href)
    },
    viewMore() {
      this.updateIsFoldDetail(false)

      // todo: 老链路完全改造后删除此
      // this.assignState({
      //   isFoldDetail: false
      // })
    },
    updateLocalSize(val) {
      // this.isNewSizeLocal && 
      this.updateCurrentLocalCountry(val)
    },
    handleSelectionDrawer(status) {
      if (status) {
        daEventCenter.triggerNotice({ daId: '1-6-4-72' })
        daEventCenter.triggerNotice({ daId: '1-6-4-73' })
      }
      this.showSelectionDrawer = status
    },
    triggerAddCompletedFloatCart({ animation }) {
      if (!this.showFloatCartIcon) return
      if (animation) {
        this.$refs?.detailCartBag?.drop?.(document.querySelector('#banner-detail'))
      } else {
        this.$refs?.detailCartBag?.updateCartNum?.()
      }
    },
    async handleHeelHeightRange() {
      // 指定的shein和romwe类目，命中ABT，且含有跟高(Heel Height)属性时，才展示Heel Hight强化
      const sheinRomweCateArr = [
        '1751',
        '1750',
        '1748',
        '3181',
        '2517',
        '3182',
        '3193',
        '1749',
        '715',
        '700',
        '699',
        '1981',
        '1486',
        '1985',
        '2065',
        '749'
      ]
      const { cat_id } = this.detail
      const descriptionList = this.pageComponents?.descriptionConf?.descriptionList ?? {}

      let dimensionAttrInfo = this.sizeInfoDes?.dimensionAttrInfo ?? []
      const heelHeightMap = dimensionAttrInfo.find(item => {
        // {attr_name:"Heel Height"attr_name_en:"Heel Height"},通过attr_name的值（多语言）去尺码表拿数据
        return item?.attr_name_en?.includes('Heel Height')
      })
      let showSizeRange = true // 返回的尺码数据是否本身包含范围，如果有，则不拼接tips展示
      // 处理尺寸范围
      const getSizeInfoRange = (sizeArr = [], separator = '') => {
        const result = []
        sizeArr.forEach(item => {
          Object.keys(item).some(key => {
            if (key.includes(heelHeightMap?.attr_name)) {
              let heelHeight = item[key]?.trim()?.split(separator)?.[0]?.trim()
              if (isNaN(heelHeight)) {
                showSizeRange = false
              }
              heelHeight && result.push(heelHeight)
            }
          })
        })
        if (result.length === 0) showSizeRange = false
        let min = Math.min(...result)
        const max = Math.max(...result)
        return (min === max ? min : `${min}-${max}`) + separator
      }
      const sizeInfoRange = getSizeInfoRange(this.sizeInfoDes?.sizeInfo, 'cm')
      const sizeInfoInchRange = getSizeInfoRange(this.sizeInfoDes?.sizeInfoInch, 'inch')
      const heelHeight = descriptionList['45']?.value ?? ''
      // 外露场景heel height
      const textAttr = this.pageComponents?.descPosConf?.fPosData?.textAttr ?? []
      textAttr.forEach(item => {
        if (heelHeightMap?.attr_name?.includes(item.name)) {
          item.value += ` (${sizeInfoRange}/${sizeInfoInchRange})`
        }
      })
      // attr_id: 45代表跟高属性
      let hasHeelHeight = Object.keys(descriptionList).some(key => key.includes('45'))
      // 实验值为Heelhight = true时才展示
      if (
        sheinRomweCateArr.includes(cat_id) &&
        hasHeelHeight
      ) {
        showSizeRange && this.updateHeelHeightStatus(true)
      }
      this.updateHeelHeight(heelHeight)
      this.updateCmInchInfo(` (${sizeInfoRange}/${sizeInfoInchRange})`)
    }
  }
}
</script>

<style lang="less">
.product-middle {
  position: relative;
  height: 2rem;
  overflow: hidden;
  margin: 0.213333rem 0;

  &__reviews {
    margin-top: 0.213333rem;
    &.review-lookbook {
      padding-top: 0.22rem;
    }
    // 合并间距
    &.no_margin {
      margin: 0;
    }
  }

  &__show {
    height: auto;
    overflow: visible;
  }
  &__fold {
    height: 4rem;
  }
  &__wrap {
    .padding-l(0.32rem);
    .padding-r(0.32rem);
    background: #fff;
    margin-bottom: 0.213333rem;
  }
  &__more {
    position: absolute;
    left: 0 /*rtl:ignore*/;
    right: 0 /*rtl:ignore*/;
    bottom: 0;
    height: 1rem;
    line-height: 1rem;
    color: #666;
    text-align: center;
    background: #fff;
    -webkit-box-shadow: 0 -18px 15px 0 #fff;
    box-shadow: 0 -18px 15px 0 #fff;
    font-weight: 700;
    z-index: @zindex-hack;
    [class*='iconfont'] {
      display: inline-block;
      margin-left: 0.11rem /*rtl:ignore*/;
      vertical-align: middle;
      color: #999;
      font-weight: 400;
      .font-dpr(32px);
    }
  }
  &__cart-bag {
    .cart-bag-wrapper {
      bottom: 2.8267rem;
    }
  }
  .no-margin-bottom {
    margin-bottom: 0;
  }
  .brand-border-bottom {
    border-bottom: 1px solid #e5e5e5;
  }
}
</style>
