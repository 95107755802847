<template>
  <div class="j-expose__detailops-container">
    <div
      v-for="(item, idx) in content"
      :key="idx"
      aria-hidden="true"
      class="product-middle__has-border j-expose__detailops-content"
      :data-expose-id="'detailops' + idx"
    >
      <div
        class="goods-attr__opos j-push-history-hash j-expose__detailops-target"
        state="detailOPos"
        da-event-click="1-6-4-36"
        :data-position="idx + 2"
        data-status="close"
        @click="openDrawer(item, idx)"
      >
        {{ item.moduleTitleName }}
        <i class="suiiconfont sui_icon_more_right2_16px"></i>
      </div>
    </div>
    <DetailOPosDrawer
      v-model="detailOPos"
      :content="subContent"
      :idx="index"
    />
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import DetailOPosDrawer from './DetailOPosDrawer'
import GBDetailAnalysisEvent from '../../analysis/ga'
export default {
  components: {
    DetailOPosDrawer
  },
  data () {
    return {
      subContent: {},
      title: '',
      index: -1
    }
  },
  computed: {
    ...mapGetters('newProductDetail/MiddleAttr', ['pageComponents']),
    ...mapState('newProductDetail/common', ['detailOPos']),
    content () {
      return this.pageComponents.descPosConf.oPosData
    },
  },
  mounted () {
    this.$nextTick(() => {
      if (this.content.length) {
        this.exposeDetailOPos = GBDetailAnalysisEvent.exposeDetailOPos()
      }
    })
  },
  destroyed() {
    this.exposeDetailOPos?.destroyInstance()
  },
  methods: {
    // ...mapMutations('productDetail', ['changePageStatus']),
    ...mapMutations('newProductDetail/common', ['updateDetailOPos']),
    openDrawer (item, idx) {
      this.subContent = item
      this.title = item.moduleTitleName
      this.index = idx + 2
      // this.changePageStatus({ detailOPos: true })
      this.updateDetailOPos(true)
    }
  }
}
</script>
<style lang="less">
.goods-attr{
  &__opos {
    position: relative;
    height: 1.28rem;
    line-height: 1.28rem;
    text-transform: capitalize;
    display: block;
    width: 100%;
    .font-dpr(28px);
    text-align: left;
    .sui_icon_more_right2_16px {
      position: absolute;
      top: 0;
      right: 0;
      color: #959595;
    }
  }
}
</style>
