<template>
  <div
    v-expose="{
      id: '1-6-1-120',
      data: {
        from: 2
      }
    }"
    class="view-review j-sa-view-review j-push-history-hash"
    data-floor-tab="reviews"
    state="comment-all"
    @keydown.enter.stop.prevent="viewAll"
    @click="viewAll"
  >
    <HotPersonZone />
    <!-- 有一般评论 or 试用报告时的标题 -->
    <div
      v-if="allCommentSum || fsComments?.buyBoxTotalNum"
      class="view-review__title"
      :aria-label="language.SHEIN_KEY_PWA_15008"
      tabindex="1"
      :role="language.SHEIN_KEY_PWA_15202"
    >
      <div class="view-review__title-left">
        {{ onlyShowSameItem ? language.SHEIN_KEY_PWA_31288 : language.SHEIN_KEY_PWA_15008 }}
      </div>
      <div
        v-expose="viewmoewData('1-6-2-72','scorecard')"
        v-tap="viewmoewData('1-6-2-73','scorecard')"
        class="view-all-btn"
        :class="viewAllBtnClassName"
      >
        <span>{{ onlyShowSameItem ? '' : language.SHEIN_KEY_PWA_22253 }}</span>
        <i class="suiiconfont sui_icon_more_right_12px_2"></i>
      </div>
    </div>
    <!-- 仅有站外评论时的提示 -->
    <div
      v-else
      class="view-review__only-store-tip"
    >
      <h3>{{ language.SHEIN_KEY_PWA_24443 }}</h3>
      <h2 class="view-review__only-store-tipContent">
        <span>{{ language.SHEIN_KEY_PWA_24442 }}</span>
        <i
          class="iconfont icon-_px product-intro__question"
          @click.stop="storeCommentTipShow = true"
        ></i>
      </h2>
      <SDialog
        :visible.sync="storeCommentTipShow"
        :append-to-body="true"
      >
        <div class="view-review__only-store-tipDialog">
          {{ language.SHEIN_KEY_PWA_24444 }}
        </div>
        <template #footer>
          <div class="view-review__only-store-tipDialogBtnBox">
            <s-button
              :type="['primary', 'H72PX']"
              @click.stop="storeCommentTipShow = false"
            >
              {{ language.SHEIN_KEY_PWA_15146 }}
            </s-button>
          </div>
        </template>
      </SDialog>
    </div>
    <!-- 评论真实性说明文案 -->
    <div
      v-if="isShowValuableReviews"
      v-expose="{id: '1-6-2-87'}"
      class="view-review__valuable-reviews"
    >
      <Icon
        name="sui_icon_certification_16px"
        size="16px"
        color="#198055"
      />
      <span class="view-review__valuable-tips">{{ language.SHEIN_KEY_PWA_31069 || 'SHEIN encourages genuine and valuable reviews.' }}</span>
    </div>
    <RatePanel
      v-if="showRatePanel && !onlyShowSameItem"
      class="goods-review__rate-detail"
      :has-true-to-size="hasCommentsToSize"
      :is-detail="true"
      :local-review-bar-class-name="localReviewBarClassName"
      @getIsShowRank="getIsShowRank"
    />
    <ReviewLabel
      v-if="reviewsTags.length && !onlyShowSameItem"
      style-type="main"
      reviewTagLine="one"
      :tag-list="reviewsTags"
      :free-trail-info-total="fsComments.reportTotal"
      @reviewTagIdChange="handleReviewTagClick"
    />
    <div
      v-if="firstReview.comment_id"
      class="j-expose__reviews__list"
      :data-expose-id="getExposeId(firstReview.comment_id)"
    >
      <div class="j-expose__reviews__item">
        <div
          class="j-expose__reviews__item-con"
          :data-comment-id="firstReview.comment_id"
          :data-comment-page="1"
          :data-index="1"
          :data-out-store-review="firstReview.isOutStoreReview ? '1' : ''"
          data-review-location="page_goods_detail"
          :data-review-rank="isShowRank"
          :data-same-flag="firstReview.same_flag"
        >
          <div
            class="review__contaienr"
            :class="{'reivew-slide__container': isSlideStyle}"
          >
            <div
              v-for="(comment, index) in commentList"
              :key="comment.id"
              v-expose="getAnalysisData({
                member_size: comment.member_size,
                member_cat_size: comment.member_cat_size,
                content_tag: comment.content_tag,
                comment_image: comment.comment_image,
                review_type: 2,
                language_flag: comment.language_flag,
                commentId: comment.comment_id, 
                index: Number(index) + 1,
                isOutStoreReview: comment.isOutStoreReview,
              })"
              v-tap="{
                id: '1-6-2-71',
                data: {
                  review_count: index + 1
                }
              }"
              class="review__item"
            >
              <Comment
                :reviewPhotoType="reviewPhotoType"
                :watermark-config="watermarkConfig"
                :has-fit="hasFit"
                :language="language"
                :comment-data="comment"
                :show-bra-size="isShowBraSize"
                :size-config="commentMemberSizeConfig"
                :comment-translate-config="commentTranslateConfig"
                :translate-loading="translateloaing"
                :is-batch-translate="isBatchTranslate"
                :is-auto-translate="isAutoTranslate"
                :curr-translate-lang="currTranslateLang"
                :hide-more-text="true"
                :skc="detail.goods_sn"
                :cat-id="detail.cat_id"
                :show-report="true"
                :show-translate="!isSlideStyle"
                :show-member-size="!isSlideStyle"
                :is-slide-style="isSlideStyle"
                :show-point-program="showPointProgram && !isSlideStyle"
                :analysis="{ activity_from: 'page_goods_detail', location: 1 }"
                :comment-position="'1'"
                :mark-single-local-comment="markSingleLocalComment"
                :comment-date-display-new="commentDateDisplayNew"
                :isDetailBffv1="isDetailBffv1"
                @sizeShowAll="sizeShowAllHandle"
                @contentShowAll="contentShowAllHandle($event, 'outer')"
                @showOriginComment="showOriginComment($event, 'outer')"
                @closeTranslate="closeTranslateHandle($event, 'outer')"
                @imageClick="imageClickHandle($event, 'outer')"
                @clickTranslate="clickTranslateHandle($event, 'outer')"
                @likeClick="likeClickHandle($event, comment, 'outer')"
                @changeTranslateLanguage="changeTranslateLanguage($event, 'outer')"
              />
            </div>
            <div
              v-if="isSlideStyle && allReviewsSum > showCount"
              class="review__more j-sa-good-detial-review-all"
            >
              <div
                v-expose="viewmoewData('1-6-2-72','slicecard')"
                v-tap="viewmoewData('1-6-2-73','slicecard')"
                class="review__more-box"
              >
                <i class="iconfont icon-liebiaoviewmore icon"></i>
                <span>{{ language.SHEIN_KEY_PWA_15040 }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="allReviewsSum > showCount"
      v-expose="viewmoewData('1-6-2-72','bottom')"
      v-tap="viewmoewData('1-6-2-73','bottom')"
      class="view-review__all view-review__all-hook waves-effect j-sa-good-detial-review-all"
      tabindex="1"
      :aria-label="language.SHEIN_KEY_PWA_17256"
      :role="language.SHEIN_KEY_PWA_17952"
    >
      {{ language.SHEIN_KEY_PWA_15040 }}
      <i
        class="view-review__all-hook"
        :class="['suiiconfont', IS_RW ? 'sui_icon_more_right_16px_2' : 'sui_icon_more_right2_16px']"
      ></i>
    </div>
  </div>
</template>
<script>

import Comment from './components/Comment'
import RatePanel from './components/RatePanel'
import ReviewLabel from './components/ReviewLabel'
import commentMixin from './mixin/comment.js'
import HotPersonZone from '../CustomerGallery/HotPersonZone'
import { Dialog as SDialog } from '@shein/sui-mobile'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import GBDetailAnalysisEvent from 'public/src/pages/goods_detail/analysis/ga'
import { SHOW_CAT_ID } from './constants'
import { Icon } from '@shein-aidc/icon-vue2'
import { isDetailBffApi } from 'public/src/pages/goods_detail/utils/bffUtil/index.js'

const { IS_RW, langPath, SiteUID } = gbCommonInfo
daEventCenter.addSubscriber({ modulecode: '1-6-1' })

export default {
  name: 'ReviewsPanel',
  components: {
    Icon,
    ReviewLabel,
    Comment,
    RatePanel,
    SDialog,
    HotPersonZone
  },
  mixins: [commentMixin],
  data() {
    return {
      IS_RW,
      SiteUID,
      isBack: false,
      // 查看全部评论按钮类名
      viewAllBtnClassName: 'view-all-handler',
      // 本地评论栏的类名
      // * 因点击事件代理到本组件，评论栏需要用一个单独的类名在点击事件中识别
      localReviewBarClassName: 'local-review-box-handler',
      // 本地评论栏组件dom深度
      // * 在点击事件中用以向上检索父级是否匹配本地评论栏类名，判断target是否在本地评论栏中
      localReviewBarDeep: 3,
      // 仅有站外评论提示框展示状态
      storeCommentTipShow: false,
      isShowRank: 0
    }
  },
  provide() {
    return {
      RELATIVE_SPU: this.detail?.productRelationID || '',
      PAGE_FROM: 'goods_detail'
    }
  },
  computed: {
    ...mapGetters('newProductDetail', ['fsAbt']),
    reviewPhotoType() {
      return this.fsAbt?.Reviewchange?.param?.Reviewphoto || 'None' 
    },
    isShowValuableReviews() {
      // 站外评论不显示
      return this.fsAbt?.Reviewchange?.param?.Comment_des === 'Yes' && this.allCommentSum
    },
    ...mapState('productDetail', ['status']),
    ...mapGetters('newProductDetail/common', [
      'productRelationID',
      'language',
      'detail',
      'watermarkConfig',
      'hasFit',
      'isBatchTranslate'
    ]),
    ...mapGetters('newProductDetail/Middle', [
      'fsComments',
      'allCommentSum',
      'commentMemberSizeConfig',
      'commentTranslateConfig',
      'showPointProgram'
    ]),
    ...mapGetters('newProductDetail/Reviews', [
      'displayCommentSum',
      'hasCommentsToSize',
      'showRatePanel',
      'reviewsTags',
      'firstReview',
      'top4Review',
      'markSingleLocalComment',
      'commentDateDisplayNew'
    ]),
    ...mapGetters('newProductDetail/CommentPopup', [
      'languageConfigSet',
      'onlyShowSameItem'
    ]),
    ...mapState('newProductDetail/Reviews', ['commentInfo']),
    ...mapState('newProductDetail/CommentPopup', [
      'viewComment',
      'translateloaing',
      'currTranslateLang',
      'isAutoTranslate',
      'onlyLocalStatus'
    ]),
    isDetailBffv1() {
      return isDetailBffApi('v1')
    },
    isShowBraSize() {
      const { detail } = this
      return detail && detail.cat_id && SHOW_CAT_ID.includes(Number(detail.cat_id))
    },
    // Show_count_1 外露1条
    // Show_count_2 外露2条
    // Show_count_3 外露3条 (默认)
    // Show_count_3_slidecard 外露3条,横滑卡片交互
    reviewStyleAbt() {
      return this.fsAbt?.Reviewchange?.param?.Review_show_count || 'Show_count_3'
    },
    // 限制只有走到了小icon样式的用户，再进一步判断以下4个分支
    showCount() {
      if (this.reviewPhotoType !== 'Smallicon' || this.allReviewsSum < 2) return 1 // 防止一条评论出现卡片式

      const map = {
        Show_count_1: 1,
        Show_count_2: 2,
        Show_count_3: 3,
        Show_count_4: 4,
        Show_count_3_slidecard: 3,
      }
      return map[this.reviewStyleAbt] || 1
    },
    // 所有评论数之和 评论 + 报告数+站外评论
    allReviewsSum() {
      const { outStoreCommentsTotal = 0, buyBoxTotalNum = 0 } = this.fsComments || {}
      return  this.allCommentSum + buyBoxTotalNum + outStoreCommentsTotal
    },
    isSlideStyle() {
      // 限制只有走到了小icon样式的用户，再进一步判断以下4个分支
      if (this.reviewPhotoType !== 'Smallicon') return false
      return this.reviewStyleAbt === 'Show_count_3_slidecard' && this.allReviewsSum > 1 // 起码2条才用卡片式
    },
    commentList() {
      return this.top4Review.slice(0, this.showCount)
    }
  },
  watch: {
    viewComment() {
      this.$nextTick(() => {
        if (!this.isBack) return
        this.isBack = false
      })
    },
    productRelationID() {
      this.$nextTick(() => {
        this.updateOnlyLocalStatus(false)

        // 检测如果是批量翻译的话 自动更新批量翻译相关内容
        if (this.isBatchTranslate) {
          this.fetchBatchTranslateForFirstComment()
        }
      })
    }
  },
  mounted() {
    this.exposeDetailReviews = GBDetailAnalysisEvent.exposeDetailReviews()

    this.initConfigData() // 初始化语言配置

    // 检测如果是批量翻译的话 自动更新批量翻译相关内容
    if (this.isBatchTranslate) {
      this.fetchBatchTranslateForFirstComment()
    }
  },
  destroyed() {
    this.exposeDetailReviews?.destroyInstance()
  },
  methods: {
    ...mapMutations('newProductDetail/CommentPopup', [
      'updateCommentFilterData',
      'openViewCommentPop',
      'updateOnlyLocalStatus',
      'updateLocalTop',
      'updateDefaultLang'
    ]),
    ...mapActions('newProductDetail/Reviews', ['fetchBatchTranslateForFirstComment']),
    overFitValue(comment) {
      return (
        this.hasFit &&
        (+comment?.member_cat_size?.member_overall_fit ||
          +comment?.member_size?.member_overall_fit)
      )
    },
    miniMemberSize(comment) {
      let miniMemberSize = []
      let arrLength = this.overFitValue(comment) ? 1 : 2
      Object.keys(comment.member_size).forEach(sizeKey => {
        if (
          (sizeKey != 'member_overall_fit' &&
            sizeKey != 'member_brasize' &&
            sizeKey != 'member_bra_size' &&
            comment.member_size[sizeKey]) ||
          ((sizeKey == 'member_brasize' || sizeKey == 'member_bra_size') &&
            this.showBraSize &&
            comment.member_size[sizeKey])
        ) {
          if (miniMemberSize.length < arrLength) {
            miniMemberSize.push({
              sizeKey: sizeKey,
              sizeValue: comment.member_size[sizeKey]
            })
          }
        }
      })
      return miniMemberSize
    },
    miniSizeInfo(comment) {
      let miniSizeInfo = []
      let arrLength = this.overFitValue(comment) ? 1 : 2
      if (comment.member_cat_size?.size_info?.length) {
        for (let i = 0; i < comment.member_cat_size.size_info.length; i++) {
          if (comment.member_cat_size.size_info[i]) {
            miniSizeInfo.push(comment.member_cat_size.size_info[i])
            if (miniSizeInfo.length === arrLength) return miniSizeInfo
          }
        }
        return miniSizeInfo
      } else {
        return []
      }
    },
    getAnalysisData({ commentId = '', index, isOutStoreReview, sameFlag, language_flag, comment_image, content_tag, member_size, member_cat_size }) {
      const { commentTranslateConfig, showPointProgram } = this
      const commentIdStr = String(commentId || '')
      const isReport = commentIdStr.indexOf('rp') > -1
      const reviewTypeStr = isOutStoreReview ? 1 : isReport ? 2 : 0
      const contentTag = (() => {
        if (!Array.isArray(content_tag)) {
          return []
        }
        return content_tag.filter(tag => tag && tag.name && tag.content)
      })()

      const isSizeInfoExpand = (() => {
        if(isOutStoreReview) return false
        return this.miniMemberSize({ member_size, member_cat_size }).length > 0 || this.miniSizeInfo({ member_size, member_cat_size }).length > 0
      })()

      return {
        id: '1-6-7-5',
        data: {
          review_id: commentId, // 评论 ID
          position: index, // 位置序号，从 1 递增
          page: 1, // 页码序号，从 1 递增
          review_type: reviewTypeStr, //评论类型，0=站内评论，1=站外评论，2=试用报告
          review_source: sameFlag || 0, //评论来源，0=主商品评论，1=同款评论（包括保留款 + buybox最低价款）
          is_translate: commentTranslateConfig[language_flag]?.length > 0 ? 1 : 0, // 是否有单条翻译，0=无翻译按钮展示，1=有翻译按钮展示
          is_point_rating: +showPointProgram, // 是否有 Point Program，0=无Point Program展示，1=有Point Program展示
          image_num: comment_image?.length ?? 0, //评论中图片数量，0=评论无图，有图的情况下有几张图片报几张图片
          is_size_info_expand: +isSizeInfoExpand, //是否有尺码信息展开，0=没有尺码信息展开，1=有尺码信息展开
          is_guide_label: contentTag.length ? 1 : 0, //是否有 引导标签，0=没有引导标签，1=有引导标签
        }
      }
    },
    viewmoewData(id, postion) {
      return {
        id, 
        data: {
          viewmore_location: postion
        }
      }
    },
    goToGuidance() {
      window.location.href =  `${langPath}/Review-Guidance-a-478.html?lang=eur`
    },
    initConfigData() {
      const languageConfigSet = this.languageConfigSet || []
      const defaultLang =
        localStorage.getItem('defaultLang') ||
        (languageConfigSet.includes(navigator.language) && navigator.language) ||
        (languageConfigSet.includes(gbCommonInfo.lang) && gbCommonInfo.lang) ||
        languageConfigSet[0]
      this.updateDefaultLang(defaultLang)
    },
    getIsShowRank(res) {
      this.isShowRank = res ? 1 : ''
    },
    getExposeId(id) {
      return `detail_review${id}_${getSaPageInfo.tab_page_id}`
    },
    handleReviewTagClick(id) {
      this.updateCommentFilterData({
        sort: '',
        tag_id: id,
        curPage: 1,
        onlyShowTrialReport: false,
        commentFilterAttrSizeId: '',
        // commentFilterColorId: '',
        commentFilterGoodsId: '',
        currSizeIndex: -1,
        withPicture: '',
        comment_rank: ''
      })
      this.changeHash('comment-all')
      this.openViewCommentPop({ from: 'tag' })
    },
    changeHash(value) {
      location.hash = value ? '#' + value : ''
    },
    // 检测dom是否在本地评论栏中
    hitLocalReviewBar(target) {
      if (!(target instanceof HTMLElement)) {
        return false
      }
      const { localReviewBarClassName, localReviewBarDeep } = this
      if (target.className.indexOf(localReviewBarClassName) > -1) {
        return true
      }

      let currentTarget = target
      for (let i = 0; i < localReviewBarDeep; i++) {
        currentTarget = currentTarget.parentElement
        if (!currentTarget || currentTarget === document.body) {
          return false
        }
        if (currentTarget.className.indexOf(localReviewBarClassName) > -1) {
          return true
        }
      }

      return false
    },
    viewAll({ target = {} }) {
      let viewLocal = false // 是否自动打开本地评论开关
      if (target?.className?.indexOf?.(this.viewAllBtnClassName) === -1) {
        viewLocal = this.onlyLocalStatus || this.hitLocalReviewBar(target)
      } // 如果是点击本地评论的进去 要自动打开本地评论开关 并置顶
      this.isBack = true
      this.updateOnlyLocalStatus(viewLocal)
      this.updateLocalTop(viewLocal)
      this.openViewCommentPop()
      this.viewAllEvent(target)
    },
    viewAllEvent(target) {
      const isHot = !~target?.className?.indexOf('view-review__all-hook') // 是否热区点击
      if (!isHot) {
        daEventCenter.triggerNotice({
          daId: '1-6-7-50'
        })
      }
      daEventCenter.triggerNotice({
        daId: '1-6-1-121',
        extraData: {
          from: 2
        }
      })
    },
    sizeShowAllHandle() {
      daEventCenter.triggerNotice({
        daId: '1-6-7-33'
      })
    }
  }
}
</script>

<style lang="less">
.view-review {
  background: #fff;
  position: relative;

  &__title {
    padding-top: 0.2667rem;
    // padding-bottom: 0.05rem;
    margin: 0 0.32rem;
    .flexbox;
    justify-content: space-between;
    line-height: 0.51rem;
    .font-dpr(28px);
    font-weight: bold;
    /* rw:begin */
    font-family: 'Adieu';

    .view-all-btn {
      .font-dpr(24px);
      font-weight: normal;
      color: @sui_color_gray_dark2;
    }
  }
  &__title-left {
    .flexbox;
    .align-center;
    gap:4px;
  }
  &__valuable-reviews{
    margin: 0.21rem 0.32rem 0;
    padding: 0.16rem 0.21333rem;
    background: #F5FCFB;
    display: flex;
    align-items: flex-start;
  }
  &__valuable-tips{
    .font-dpr(22px);
    color: #198055;
    margin-left: 0.10667rem;
  }
  .view-review-null {
    .page-inner {
      padding: 2rem 0;
      text-align: center;
      > i {
        font-size: 50px;
        margin-bottom: 0.5rem;
      }
      > p {
        .font-dpr(32px);
        color: #666;
      }
    }
  }
  &__sort-list {
    background: #fff;
    width: 10rem;
    margin: 0 auto;
    padding: 0 0.32rem;
    box-sizing: border-box;
    overflow-y: auto;
    // height: 4.2664rem;
    // height: 3.18rem;
    > li {
      .flexbox();
      .space-between();
      .align-center();
      height: 1.06rem;
      line-height: 1.06rem;
      border-top: 1px solid #e5e5e5;
    }
  }

  &__report-tab {
    height: 1.28rem;
    .flexbox();
    .space-between();
    .align-center();
    padding: 0 0.32rem;
    font-weight: bold;
    position: relative;
    &.open {
      .border-dpr(border-bottom, 2px, transparent);
    }
    &.noborder {
      border: none;
    }
    .tabname {
      font-size: 0.374rem;
    }
    span {
      color: #999;
    }
  }
  .view-review__report-tab{
    &:not(:first-child){
      .border-dpr(border-top, 2px, #e5e5e5);
    }
  }
  &__all {
    width: 100%;
    text-align: center;
    height: 0.96rem;
    line-height: 0.96rem;
    border-top: 1px solid #f6f6f6;
    background: #fff;
    color: #999;
    /* rw:begin */
    .font-dpr(28px);
    color: @sui_color_gray_dark3;
    [class*='iconfont'] {
      display: inline-block;
      .margin-l(0.026667rem);
      .font-dpr(28px);
      color: #999;
      vertical-align: top;
      /* rw:begin */
      color: @sui_color_gray_dark3;
    }
  }
}

.review{
  &__item {
    border-bottom: 1px solid #f6f6f6;
    &:last-child{
      border: none;
    }
  }
}
/*
Show_count_3_slidecard： 横滑卡片交互
*/
.reivew-slide__container{
  display: flex;
  overflow: scroll;
  margin-top: 0.37rem;
  padding-bottom: 0.37rem;
  /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
  -ms-overflow-style: none;
  /*火狐下隐藏滚动条*/
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  /*Chrome下隐藏滚动条，溢出可以透明滚动*/
  &::-webkit-scrollbar {
    display: none;
  }
  .review {
    &__item{
      width: 7.62667rem;
      flex-shrink: 0;
      min-height: 4rem;
      border-radius: 0.21333rem;
      border: 1px solid #F0F0F0;
      margin-right: 0.21rem;
      &:first-child{
        margin-left: 0.32rem;
      }
    }
    &__more {
      min-height: 4rem;
      flex-shrink: 0;
      width: 2.02667rem;
      border-radius: 0.21333rem;
      margin-right: 0.32rem;
      border: 1px solid #F0F0F0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &__more-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .icon {
        color: #222222;
        font-size: 40px;
      }
      color: #000;
      font-family: "SF Pro";
      font-size: 0.32rem;
      font-style: normal;
      font-weight: 400;
    }
  }
}
.view-review__only-store-tip {
  width: 100%;
  padding: 0.32rem 0.32rem 0;

  h3 {
    .font-dpr(28px);
    font-weight: 400;
    line-height: 0.64rem;
    color: @sui_color_gray_dark2;
  }

  .view-review__only-store-tipContent {
    margin-top: 0.32rem;
    .flexbox();

    span,
    i {
      display: inline-block;
    }
    span {
      .font-dpr(24px);
      font-weight: 600;
    }
    i {
      padding: 0 0.16rem;
      color: #959595;
    }
  }
}

.view-review__only-store-tipDialog {
  color: @sui_color_gray_dark1;
  .font-dpr(28px);
  font-weight: 400;
  margin: 0.213333rem 0;
  text-align: center;
}
.view-review__only-store-tipDialogBtnBox {
  padding: 0.533333rem 0;

  button {
    width: 100%;
    color: #fff;
    .font-dpr(28px);
    font-weight: 700;
  }
}

</style>
