var render, staticRenderFns
import script from "./TrendsLabel.vue?vue&type=script&lang=jsx&"
export * from "./TrendsLabel.vue?vue&type=script&lang=jsx&"
import style0 from "./TrendsLabel.vue?vue&type=style&index=0&id=97523504&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.8.1_ejs@2.7.2_lodash@4.17.21_vue-template-compiler@2.7.14_webpack@5.86.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97523504",
  null
  
)

export default component.exports