<template>
  <div class="signboard-list__container">
    <div 
      class="signboard-list__wrap j-da-event-box"
      :data-feed_type="storeJumpType === 'three' ? 2 : ''"
    >
      <div
        v-for="(item, index) in products"
        :key="`signboard-list__item-${index}`"
        class="signboard-list__item"
      >
        <SProductItemV3
          v-if="productItemsConfig._isNew"
          ref="goodsItem"
          :item="item"
          :index="index"
          :goods-item-info="goodsItemInfo"
          :language="itemLanguage"
          :config="productItemsConfig"
          :constant-data="constantData"
          :column="3"
          @clickItem="clickItemHandle"
          @openQuickAdd="clickAdd"
        />
        <ProductItem
          v-else
          ref="goodsItem"
          :item="item"
          :index="index"
          :goods-item-info="goodsItemInfo"
          :language="itemLanguage"
          :config="productItemsConfig"
          :constant-data="constantData"
          :column="3"
          @clickItem="clickItemHandle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import localMixins from '../mixins/index'
export default {
  name: 'SignBoardSList',
  mixins: [localMixins]
}
</script>

<style lang="less">
.signboard-list {
    &__container {
        padding: 0 .32rem;
        width: 100%;
    }
    &__wrap {
        display: inline-flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    &__item {
        margin-bottom: 0.2667rem;
        width: 2.9733rem;
    }
}
</style>
