

export const baseExposeData = {
  activity_from: 'buy_box',
  reportCode: 'buy_box',
}
export const getExposeBoxData = (options = {}) => {
  const { isPopup, poskey, feed_type = '' } = options
  const _bindData = {
    module: 'module',
    'review-location': '-',
    location: isPopup ? 'popup' : 'page',
    style: 'popup',
    tab_list: '-',
    feed_type,
    'active-from': baseExposeData.activity_from
  }
  if (poskey) {
    _bindData.poskey = poskey
  }
  const bindData = {}
  for (let key in _bindData) {
    bindData[`data-${key}`] = _bindData[key]
  }
  return bindData
}


const LabelsKey = 'mobileBroadwiseView'
// const FreeShippingTagId = '600021126'

const textDataTag = ({ text, type = 0 }) => {
  return {
    text,
    type, // 0: 默认 1: quickShip
  }
}

const getItemServiceLabels = (item = {}) => {

  const { serviceLabels = [] } = item?.pretreatInfo?.[LabelsKey] ?? {}

  const textTag = []

  serviceLabels.forEach(({
    localMall,
    quickship,
    promotionLabel,
    universalLabel
  }) => {
    // 快速发货
    if (quickship?.labelLang) {
      textTag.push(textDataTag({ 
        text: quickship.labelLang,
        type: 1,
      }))
    }

    // 本地卖家
    if (localMall?.labelLang) {
      textTag.push(textDataTag({ text: localMall.labelLang, className: '' }))
    }

    // 活动
    if (promotionLabel) {
      textTag.push(textDataTag({ text: promotionLabel }))
    }
    // tsp角标 通用角标
    if (universalLabel?.length) {
      universalLabel.forEach(({ labelLang }) => {

        // const type = labelId == FreeShippingTagId ? 1 : 0 // todo
        // textTag.push(textDataTag({ text: labelLang, type }))
        textTag.push(textDataTag({ text: labelLang }))
      })
    }
  })

  if (!textTag.length) return

  return textTag
}


// 销量信息
const getItemSalesLabel = (item = {}) => {
  const { salesLabels } = item?.[LabelsKey] ?? {}
  if (!salesLabels?.labelLang) return
  return textDataTag({ text: salesLabels.labelLang })
}

/**
 * 获取销量最高标签
 */
const getItemBestSellerLabel = (item = {}, language = {}) => {
  if (!item._formatInfo.isTopSales) return

  return textDataTag({ text: language.SHEIN_KEY_PWA_30389 })
}

// 优先级：销量最高＞服务类标签>销量标签
export const setBuyboxProductLabelList = (list, language = {}) => {

  list.forEach(item => {

    let labelList = []
    const bestSellerLabel = getItemBestSellerLabel(item, language)
    if (bestSellerLabel) {
      labelList.push(bestSellerLabel)
    }

    const serviceLabels = getItemServiceLabels(item)
    if (serviceLabels?.length) {
      labelList = [...labelList, ...serviceLabels]
    }

    const saleLabel = getItemSalesLabel(item)
    if (saleLabel) {
      labelList.push(saleLabel)
    }
  
    item._formatInfo.labelList = labelList
  
  })
}
