<template>
  <div
    v-if="firstLabelItem"
    :class="{
      'buybox-itemlabel': true,
      'green-type': firstLabelItem.type === 1,
    }"
  >
    {{ firstLabelItem.text }}
  </div>
</template>
<script>
export default {
  name: 'BuyBoxIndexLabel',
}
</script>
<script setup>
import { defineProps, computed } from 'vue'

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  },
  buyBoxFloorQuickshipTitle: {
    type: String,
    default: ''
  }
})
const firstLabelItem = computed(() => {
  const res = props.item?._formatInfo?.labelList?.[0]
  if (res?.type === 1) {
    return {
      text: props.buyBoxFloorQuickshipTitle || res?.text,
      type: 1
    }
  }
  return res
})

</script>
<style lang="less" scoped>
.buybox-itemlabel {
  border-radius: 0.05333rem;
  background: #FFF8EB;
  color: #CC6E00;
  display: inline-block;
  padding: 0 0.1rem;
  max-width: 96px;
  font-size: 10px;
  font-weight: 400;
  .text-overflow();

  &.green-type {
    background: #ECFCF3;
    color: #198055;
  }
}
</style>
