<template>
  <div 
    v-if="priceProp.priceInfo" 
    class="price-simple-suggested"
  >
    <div class="sale-price-container">
      <ProductCardPriceSimple
        :goodsInfo="goodsInfo"
        :direction="direction"
        :isPaid="isPaid"
        :config="simpleComponentConfig"
        :camelPriceStyle="camelPriceStyle"
        :suggested-sale-price-config-info="suggestedSalePriceConfigInfoComputed"
      />

      <SuggestedSalePriceContainer 
        v-if="suggestedPositionData.priceRight.show"
        :goods-info="goodsInfo"
        :special-data="priceProp"
      />
    </div>

    <!-- 价格下方 -->
    <SuggestedSalePriceVue
      v-if="suggestedPositionData.priceBottom.show"
      :value="suggestedPositionData.priceBottom.priceData"
      :label-text="suggestedPositionData.priceBottom.labelText"
      :flip-text="suggestedPositionData.priceBottom.flipText"
    />
  </div>
</template>

<script name="ProductCardPriceSimpleSuggested" setup>
// 外部使用 不依赖config 简易版价格组件
// 只有价格以及价格二期需求下方文案 如Estimated

import { toRefs, computed } from 'vue'

import { priceDisplayTheResults, isSpecialSuggestedSalePrice, isDeSuggestedSalePrice, isNomalSuggestedSalePrice } from '../../js/priceDisplayTheResults.js'

import SuggestedSalePriceContainer from './SuggestedSalePriceContainer.vue'
import ProductCardPriceSimple from './ProductCardPriceSimple.vue'
import SuggestedSalePriceVue from './SuggestedSalePrice.vue'

const props = defineProps({
  /**
   * @param {Object} 商卡预处理后的数据
   */
  goodsInfo: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },
  /**
   * @param {Object} 商卡多语言
   */
  language: {
    type: Object,
    default() {
      return {}
    },
  },
  /**
   * @param {Boolean} 是否付费会员
   */
  isPaid: {
    type: Boolean,
    default: false
  },

  /** 外部自定义合规价配置，给没经过中间层预处理的场景用 */
  suggestedSalePriceConfigInfo: {
    type: Object,
    default: () => ({}),
  },

  /**
   * @param {String: 'column' | 'row' } 价格与文字标签的排列方式  column竖向  row横向
   */
  direction: {
    type: String,
    default: 'column'
  },

  /** 透传 */
  camelPriceStyle: {
    type: Object,
    default: () => {},
  },

  /**
   * @typedef {Object} SceneConfig
   * @property {boolean} hidePriceBottomLabel - 是否隐藏价格旁边的标签
   * @property {boolean} noCamelCasePrice - 是否不启用大小字价格
   * @property {string|undefined} priceColor - 自定义价格颜色
   *
   * @param {SceneConfig} 具体场景自定义配置项
   */
  config: {
    type: Object,
    default() {
      return {
        hidePriceBottomLabel: false,
        noCamelCasePrice: false,
      }
    }
  }
})

const { goodsInfo, language, isPaid, config } = toRefs(props)

const simpleComponentConfig = computed(() => {
  // 任意一个合规价展示，都不要展示价格说明文字
  if (suggestedPositionData.value.priceRight.show || suggestedPositionData.value.priceBottom.show) {
    return Object.assign({}, config.value, {
      hidePriceBottomLabel: true
    })
  } else {
    return config.value
  }
})

// 取数优先级
const priceProp = computed(() => {
  return priceDisplayTheResults(goodsInfo.value, language.value, {
    isPaid: isPaid.value,
    suggestedSalePriceConfigInfo: props.suggestedSalePriceConfigInfo,
  })
})

const suggestedSalePriceConfigInfoComputed = computed(() => {
  const susaConfig = props.suggestedSalePriceConfigInfo?.type ?
    props.suggestedSalePriceConfigInfo :
    goodsInfo.value?.pretreatInfo?.suggestedSalePriceConfigInfo
  const suggestedSalePriceConfigInfo = susaConfig || {}

  return suggestedSalePriceConfigInfo
})

const suggestedPositionData = computed(() => {
  const { retailPrice } = goodsInfo.value

  const isSpecial = isSpecialSuggestedSalePrice(suggestedSalePriceConfigInfoComputed.value?.type)
  const isDe = isDeSuggestedSalePrice(suggestedSalePriceConfigInfoComputed.value?.type)
  const isNomal = isNomalSuggestedSalePrice(suggestedSalePriceConfigInfoComputed.value?.type)

  const { pricePopoverLang, lowestPriceLabelLang } = suggestedSalePriceConfigInfoComputed.value

  const priceRight = {
    show: false,
    flipText: false,
    priceData: {},
    labelText: '',
    popoverLang: ''
  }
  const priceBottom = {
    show: false,
    flipText: false,
    priceData: {},
    labelText: ''
  }
  if (!priceProp.value?.needSusaPrice) {
    return {
      priceRight,
      priceBottom
    }
  }

  // 波兰 isSpecial 只显示一个划线价 放价格下边
  if (isSpecial && !priceProp.value.isInversion) {
    priceBottom.show = priceProp.value?.showSusaPrice
    priceBottom.priceData = priceProp.value?.suggestedSalePrice
    priceBottom.labelText = lowestPriceLabelLang
    priceBottom.flipText = false
  } else if (isDe) {
    // 德国 价格右边显示原价  价格下边显示划线价
    priceRight.show = priceProp.value?.showSusaPrice
    priceRight.priceData = retailPrice
    priceRight.labelText = ''
    priceRight.flipText = false
    priceRight.popoverLang = priceProp.value?.showRetailPricePopover ? pricePopoverLang : ''

    priceBottom.show = priceProp.value?.show30DaysLowestPrice
    priceBottom.priceData = priceProp.value?.suggestedSalePrice
    priceBottom.labelText = lowestPriceLabelLang
    priceBottom.flipText = true
  } else if (isNomal && !priceProp.value.isInversion) {
    // 法国 价格右边显示划线价 不显示labelText
    priceRight.show = priceProp.value?.showSusaPrice
    priceRight.priceData = priceProp.value?.suggestedSalePrice
    priceRight.labelText = ''
    priceRight.flipText = false
  }

  return {
    priceRight,
    priceBottom
  }
})

</script>

<style lang="less" scoped>
.price-simple-suggested {
  max-width: 100%;
}
.sale-price-container {
  max-width: 100%;
  display: flex;
  align-items: baseline;
}
/deep/ .prices-info__suggested-sale-price {
  flex: 1;
  min-width: 0;
}

</style>
