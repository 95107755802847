<template>
  <div 
    v-if="priceProp.priceInfo" 
    class="product-card__price-simple"
    :class="containerCls"
  >
    <div
      class="price-content"
      :style="priceContentStyle"
    >
      <CamelCasePrice
        :camel-price-style="camelPriceStyle"
        :origin-string="priceProp.priceInfo.amountWithSymbol"
        :currency-symbol-prefix="camelCasePricePrice.currencySymbolPrefix"
        :number-before-dot="camelCasePricePrice.numberBeforeDot"
        :number-after-dot="camelCasePricePrice.numberAfterDot"
        :currency-symbol-suffix="camelCasePricePrice.currencySymbolSuffix"
        :show-camel-price="!noCamelCasePrice"
      />
      <slot name="priceRight"></slot>
    </div>
    <template v-if="!hidePriceBottomLabel">
      <slot 
        :display-price="priceProp.priceInfo"
        :price-type="priceProp.priceType"
      >
        <!-- 标签插槽 -->
        <div 
          v-if="priceProp.labelText && !hideTargetBySuggestedSalePrice" 
          class="product-card__text-label"
        >
          {{ priceProp.labelText }}
        </div>
      </slot>
    </template>
  </div>
</template>

<script name="ProductCardPriceSimple" setup>
// 外部使用 不依赖config 简易版价格组件
// 只有价格以及价格二期需求下方文案 如Estimated

import { toRefs, computed, ref } from 'vue'
import { handlePriceHtml } from 'public/src/pages/components/product/item_v3/js/utils.js'

import { priceDisplayTheResults, isDeSuggestedSalePrice } from '../../js/priceDisplayTheResults.js'

import CamelCasePrice from './CamelCasePrice.vue'

const props = defineProps({
  /**
   * @param {Object} 商卡预处理后的数据
   */
  goodsInfo: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },
  /**
   * @param {Object} 商卡多语言
   */
  language: {
    type: Object,
    default() {
      return {}
    },
  },
  /**
   * @param {Boolean} 是否付费会员
   */
  isPaid: {
    type: Boolean,
    default: false
  },
  /**
   * @param {String: 'column' | 'row' } 价格与文字标签的排列方式  column竖向  row横向
   */
  direction: {
    type: String,
    default: 'column'
  },

  /** 透传 */
  camelPriceStyle: {
    type: Object,
    default: () => {},
  },

  /** 外部自定义合规价配置，给没经过中间层预处理的场景用 */
  suggestedSalePriceConfigInfo: {
    type: Object,
    default: () => ({}),
  },

  /**
   * @typedef {Object} SceneConfig
   * @property {boolean} hidePriceBottomLabel - 是否隐藏价格旁边的标签
   * @property {boolean} noCamelCasePrice - 是否不启用大小字价格
   * @property {string|undefined} priceColor - 自定义价格颜色
   *
   * @param {SceneConfig} 具体场景自定义配置项
   */
  config: {
    type: Object,
    default() {
      return {
        hidePriceBottomLabel: false,
        noCamelCasePrice: false,
      }
    }
  }
})

const { goodsInfo, language, isPaid } = toRefs(props)

// props.config
const hidePriceBottomLabel = props?.config?.hidePriceBottomLabel ?? false
const noCamelCasePrice = props?.config?.noCamelCasePrice ?? false

const priceContentStyle = ref({
  color: props.config?.priceColor,
})

// 取数优先级
const priceProp = computed(() => {
  return priceDisplayTheResults(goodsInfo.value, language.value, {
    suggestedSalePriceConfigInfo: props.suggestedSalePriceConfigInfo,
    isPaid: isPaid.value,
  }) || {}
})


const isDeSuggested = computed(() => {

  const susaConfig = props.suggestedSalePriceConfigInfo || goodsInfo.value?.pretreatInfo?.suggestedSalePriceConfigInfo
    
  const suggestedSalePriceConfigInfo = susaConfig || {}
  const suggestedSalePriceType = suggestedSalePriceConfigInfo?.type

  const isDe = isDeSuggestedSalePrice(suggestedSalePriceType)

  return isDe
})

const containerCls = computed(() => {

  const { color, show30DaysLowestPrice, priceInfoAndretailPriceEquivalent } = priceProp.value

  const deSusaPriceColor = isDeSuggested.value && (show30DaysLowestPrice || priceInfoAndretailPriceEquivalent)

  return {
    [color]: !isDeSuggested.value || deSusaPriceColor,
    [props.direction]: true,
  }
})

const hideTargetBySuggestedSalePrice = computed(() => {
  const susaConfig = props.suggestedSalePriceConfigInfo || goodsInfo.value?.pretreatInfo?.suggestedSalePriceConfigInfo
  return susaConfig?.config?.hideTargetBySuggestedSalePrice ?? false
})

const camelCasePricePrice = computed(() => {
  let resPriceInfo = priceProp?.value?.priceInfo ?? {}

  const { currencySymbolPrefix, numberBeforeDot, numberAfterDot, currencySymbolSuffix } = handlePriceHtml(resPriceInfo?.amountWithSymbol ?? '', resPriceInfo?.amount ?? '')

  return { currencySymbolPrefix, numberBeforeDot, numberAfterDot, currencySymbolSuffix }
})

</script>

<style lang="less" scoped>
.product-card__price-simple {
  display: flex;
  max-width: 100%;

  &.column {
    flex-direction: column;
  }

  &.row {
    margin-left: 0;
    margin-right: 0;

    flex-direction: row;
    align-items: baseline;
  }

  &.brown {
    color: var(--simple-brown-color, @sui_color_club_rosegold_dark1);
  }

  &.origin {
    color: var(--simple-origin-color, @sui_color_discount);
  }

  .price-content {
    display: flex;
    align-items: baseline;
    padding-right: .106rem;
    font-weight: bold;

    /deep/ .product-card__camel-case-price {
      direction: ltr /*rtl:ignore*/;
    }
    /deep/ .product-card__price-suggested {
      margin-left: 6px;
    }
  }
}

.product-card__text-label {
  font-size: 0.2667rem;
  line-height: 0.32rem;
}
</style>
