<template>
  <s-drawer
    :visible.sync="isShow"
    direction="btt"
    custom-class="c-drawer-bot-panel ingredients-drawer"
  >
    <header class="ingredients-drawer__header">
      <i
        class="iconfont icon-close j-clear-history-hash"
        @click="isShow = false"
      ></i>
      {{ language.SHEIN_KEY_PWA_17425 }}
    </header>
    <div
      class="ingredients-drawer__content"
      v-html="beautyGoodsDesc.goodsComponent || ''"
    ></div>
  </s-drawer>
</template>

<script>
export default {
  name: 'IngredientsDrawer',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    language: {
      type: Object,
      default () {
        return {}
      }
    },
    beautyGoodsDesc: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isShow: false
    }
  },
  watch: {
    value (newVal) {
      this.isShow = newVal
    },
    isShow (newVal) {
      this.$emit('input', newVal)
    }
  },
}
</script>

<style lang="less">
.ingredients-drawer {
  max-height: 80vh;
  .left(0);
  &__header {
      position: relative;
    width: 100%;
    height: 1.07rem;
    line-height: 1.07rem;
    color: #333;
    .font-dpr(28px);
    text-align: center;
  }
  &__content {
    max-height: calc(80vh - 1.07rem);
    overflow-y: scroll;
    padding: .293333rem .32rem 1.28rem;
    word-break: break-word;
  }
  [class*="iconfont"].icon-close {
    position: absolute;
    left: 0/*rtl:ignore*/;
    top: 0;
    width: 1.07rem;
    height: 1.07rem;
    font-weight: 400;
    .font-dpr(24px);
  }
}
</style>
