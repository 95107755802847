<script lang="jsx">
/**
 * @component 物流头信息
 */
import { template } from '@shein/common-function'
import ShippingSheinClub from './ShippingSheinClub'
import { Icon } from '@shein-aidc/icon-vue2'

export default {
  name: 'ShippingHead',
  functional: true,
  components: {
    ShippingSheinClub,
    Icon,
  },
  props: {
    language: {
      type: Object,
      default: () => ({})
    },
    deliveryInfo: {
      type: Object,
      default: () => ({})
    },
    lang: {
      type: String,
      default: ''
    },
    shippingInfo: {
      type: Object,
      default: () => ({})
    },
    mallName: {
      type: String,
      default: ''
    },
    showMallSite: {
      type: Boolean,
      default: false
    },
    shippingAbt: {
      type: String,
      default: ''
    },
    useStrongStyleType: {
      type: Number,
      default: 0
    },
    deliveryStrongTitleSection: {
      type: String,
      default: ''
    },
    quickshipAgingText: {
      type: String,
      default: ''
    },
    quickshipStrongOutsideText: {
      type: String,
      default: ''
    },
    simplifyAgingText: {
      type: String,
      default: ''
    },
    isSupportQuickship: {
      type: Object,
      default: () => ({})
    },
    quickshipStrongAbt: {
      type: Object,
      default: () => ({})
    },
    isRw: {
      type: Boolean,
      default: false
    },
    showDescQuickShipTime: {
      type: [Boolean, String],
      default: false
    },
    descQuickShipTime: {
      type: String,
      default: ''
    },
    shippingLanguage: {
      type: Object,
      default: () => ({})
    },
    shippingOutsideItem: {
      type: Object,
      default: () => ({})
    },
    shippingAbtState: {
      type: Object,
      default: () => ({})
    },
    showPaidVipTips: {
      type: Boolean,
      default: false
    },
    isLogisticsShippinFree: {
      type: Boolean,
      default: false
    },
    sheinClubUserInfo: {
      type: Object,
      default: () => ({})
    },
    shippingOutsideText: {
      type: String,
      default: ''
    },
    dayPercentDes: {
      type: String,
      default: ''
    },
    shippingPolicy: {
      type: Array,
      default: () => []
    },
    notSupportFree: {
      type: Boolean,
      default: false
    },
    quickShipHighlight: {
      type: Boolean,
      default: false
    },
    sheinClubShppingText: {
      type: String,
      default: ''
    },
    shippingStyleV1: { // 物流优化样式
      type: Boolean,
      default: false
    }
  },
  render(h, { props, listeners }) {
    let {
      language,
      deliveryInfo,
      lang,
      shippingInfo,
      mallName,
      showMallSite,
      // shippingAbt,
      isRw,
      showDescQuickShipTime,
      descQuickShipTime,
      shippingLanguage,
      shippingOutsideItem,
      shippingAbtState,
      showPaidVipTips,
      sheinClubUserInfo,
      shippingOutsideText,
      dayPercentDes,
      // shippingPolicy,
      // notSupportFree,
      isLogisticsShippinFree,
      quickShipHighlight,
      sheinClubShppingText,
      quickshipStrongAbt, // quickship强化abt
      useStrongStyleType, // quickship强化样式实验icon样式
      deliveryStrongTitleSection, // quickship强化文案
      quickshipAgingText, // quickship时效内容文案
      quickshipStrongOutsideText, // quickship时效标题文案
      isSupportQuickship, // 支持quickship的状态
      simplifyAgingText, // 不支持qs的简化物流时效文案
      shippingStyleV1
    } = props
    const { floorQuickshipStyle, floorNDeliveryStyle, floorNDeliveryNumber } = quickshipStrongAbt // 物流楼层quickship强化样式 || 物流楼层n天达
    let titleText = ''
    let { starting_price_obj = {}, lowestShippingPrices = {}, full_amount = {} } = shippingInfo
    let shippingAsLowAstext =
      shippingInfo.shipping_mold == 1
        ? template(
          starting_price_obj.amountWithSymbol || full_amount.amountWithSymbol,
          language.SHEIN_KEY_PWA_17566
        )
        : template(
          lowestShippingPrices.amountWithSymbol || full_amount.amountWithSymbol,
          language.SHEIN_KEY_PWA_17566
        )
    const shippingTitle = showMallSite
      ? template(mallName, language['SHEIN_KEY_PWA_20781'])
      : language['SHEIN_KEY_PWA_16266']

    const shippingHeadTitleDom = () => {
      if (deliveryInfo.city && lang === 'in') {
        titleText =
          language.SHEIN_KEY_PWA_16266 +
          ' ' +
          deliveryInfo.city +
          ',' +
          deliveryInfo.enterPincode +
          ',' +
          deliveryInfo.country
        return <p class="shipping-head__title">{titleText}</p>
      } else {
        titleText = shippingTitle + '' + shippingInfo.detailedAddress
        const address = (
          <span
            class="medium"
            domPropsInnerHTML={shippingInfo.detailedAddress}
          ></span>
        )
        return (
          <p class="shipping-head__title">
            {shippingTitle} {address}
          </p>
        )
      }
    }
    const strongIcon = () => {
      const solidIcon = (<Icon
        class="strong-shipping-icon"
        color="#198055"
        size="18px"
        name="sui_icon_qucikship_flat_18px" 
      />)
      const hollowIcon = (<Icon
        class="strong-shipping-icon"
        color="#198055"
        size="18px"
        name="sui_icon_freeshipping_flat_24px" 
      />)
      return useStrongStyleType === 1 ? solidIcon : hollowIcon
    }
    const deliveryOld = () => {
      if (!shippingInfo.shippingType) return null
      const carIcon = (// 物流模块icon
        useStrongStyleType === 0 ? <Icon
          size="18px"
          color={`${isRw ? '#2bcacc' : '#198055'}`}
          class="sui_icon_freeshipping_flat_24px"
          name="sui_icon_freeshipping_flat_24px"/>
          : strongIcon()
      )
      // qucikship提示区域  命中qs强化样式不展示
      const descQuickShipTimeDmo = showDescQuickShipTime && descQuickShipTime && !(floorQuickshipStyle || floorNDeliveryStyle) ? (
        <div
          {...{
            directives: [{ name: 'expose', value: { id: '1-6-4-57' } }]
          }}
          class="shipping-head__quickship-tip no-ml"
        >
          <span domPropsInnerHTML={descQuickShipTime}></span>
          <i class="suiiconfont sui_icon_doubt_15px_2"></i>
        </div>
      ) : null

      const contentText = () => {
        let freeStrongStyle = ''
        if(floorQuickshipStyle || floorNDeliveryStyle) { // 物流楼层quickship强化样式 || 物流楼层n天达 免邮文案
          freeStrongStyle = deliveryStrongTitleSection ? `${language.SHEIN_KEY_PWA_28850} & ${deliveryStrongTitleSection}` : language.SHEIN_KEY_PWA_28850
        }
        const freeText = freeStrongStyle ? freeStrongStyle : shippingLanguage[shippingInfo.shippingType + '_detail_free']
        const freeDom = <span
          class={[shippingStyleV1 || floorQuickshipStyle || floorNDeliveryStyle ? 'green' : '']}
          domPropsInnerHTML={freeText}
        ></span>

        if(isLogisticsShippinFree){ // 商品免邮 活动免邮
          return freeDom
        }
        // 不包邮 但支持quickship 展示qs文案
        if((shippingInfo.is_support_free_shipping == 0) && deliveryStrongTitleSection) {
          return (<span
            class="green"
            domPropsInnerHTML={deliveryStrongTitleSection}
          ></span>)
        }
        // 不包邮 也不支持quickship Shipping as low as
        if((shippingInfo.is_support_free_shipping == 0) && (floorQuickshipStyle || floorNDeliveryStyle) && !deliveryStrongTitleSection) {
          return <span domPropsInnerHTML={shippingAsLowAstext}></span>
        }
        if (shippingInfo.is_support_free_shipping == 0) { // 1免邮 0不免邮
          return language.SHEIN_KEY_PWA_18722 // 不支持免邮的文案
        }

        // Free express shipping on orders over {0}
        if (shippingInfo.aging_is_show == 1) { //免邮时效  免邮  门槛免邮
          if(shippingInfo.isFreeShipping) return freeDom
          // 门槛免邮 必有full_amount
          let thresholdText = template(
            shippingInfo.full_amount.amountWithSymbol,
            shippingLanguage[shippingInfo.shippingType + '_detail']
          )
          if(floorQuickshipStyle || floorNDeliveryStyle){ // 命中强化样式
            let firstHalfText = `${language.SHEIN_KEY_PWA_28850}(${language.SHEIN_KEY_PWA_30825}&ge;${shippingInfo.full_amount.amountWithSymbol})`
            thresholdText = deliveryStrongTitleSection ? `${firstHalfText} & ${deliveryStrongTitleSection}` : firstHalfText
          }
          return ( 
            <span
              class={[floorQuickshipStyle || floorNDeliveryStyle ? 'green' : '']}
              domPropsInnerHTML={thresholdText}
            ></span>
          )
        }
        // Shipping as low as
        if (
          shippingInfo.aging_is_show === 0 &&
          shippingInfo.lowestShippingPrices
        ) {
          let text =
            shippingInfo.shipping_mold == 1
              ? template(
                shippingInfo.starting_price_obj.amountWithSymbol,
                language.SHEIN_KEY_PWA_17566
              )
              : template(
                shippingInfo.lowestShippingPrices.amountWithSymbol,
                language.SHEIN_KEY_PWA_17566
              )
          return <span domPropsInnerHTML={text}></span>
        }
      }

      const outsideDom =
        shippingInfo.aging_is_expose == 1 && !quickShipHighlight ? (
          <p class="shipping-head__outside">{shippingOutsideText}</p>
        ) : null
        
      const dayPercentDesIcon =
        shippingOutsideItem.day_percents &&
        shippingOutsideItem.day_percents.length ? (
            <i
              aria-hidden="true"
              class="suiiconfont sui_icon_doubt_15px_2"
            ></i>
          ) : null

      const dayPercentDesDom =
        shippingAbtState.showShippingDayPercent && dayPercentDes ? (
          <span
            {...{
              directives: [
                {
                  name: 'expose',
                  value: {
                    id: '1-6-4-59',
                    data: {
                      days: shippingOutsideItem.days || '',
                      shipping_methods: shippingOutsideItem.transport_type || ''
                    }
                  }
                }
              ]
            }}
            class="shipping-head__day-des"
            onClick={e => {
              listeners['openDeliverySlide'](e, 0, shippingOutsideItem)
              e.stopPropagation()
            }}
          >
            <span domPropsInnerHTML={dayPercentDes}></span>
            {dayPercentDesIcon}
          </span>
        ) : null

      // 时效到达区域
      const contentDom = () => {
        if(floorQuickshipStyle || floorNDeliveryStyle) { // 物流楼层quickship强化样式 || 物流楼层n天达 时效
          // 支持quickship的物流时效文案 
          const { isSomeSupportQuickship, currentQuickship, hasSelectedSku, hasSelectedSkuSurpportQuickship, hasPartSupportQuickship } = isSupportQuickship
          const { SHEIN_KEY_PWA_30808 } = language
          const nDeliveryTip = template(floorNDeliveryNumber, SHEIN_KEY_PWA_30808) 
          const hasDayPercentDes = !!dayPercentDesDom && !isSomeSupportQuickship
          const formatSimplifyAgingText = `${simplifyAgingText}${hasDayPercentDes ? ',' : ''}`
          const showShipAsLowAsDom = isSomeSupportQuickship && shippingInfo.is_support_free_shipping == 0 && !isLogisticsShippinFree // 支持qs 不免邮 并且不满足活动免邮
          const shipAsLowAsDom = showShipAsLowAsDom ? (<span>{shippingAsLowAstext},</span>) : null
          const qsTimeDom = (<div class="time-text-area">{shipAsLowAsDom}{quickshipStrongOutsideText}<span class="green">{quickshipAgingText}</span><Icon class="qs-icon-top" name="sui_icon_doubt_16px_2" size="16px" color="#959595" /></div>)
          const showQsTimeDom = (hasSelectedSku && hasSelectedSkuSurpportQuickship) || (isSomeSupportQuickship && !hasSelectedSku) // 选中的sku支持quickship || skc下的skuquickship状态不一样，且未选中sku 只要求存在支持qs的sku 文案title已经处理了
          const supportQuickshipDom = (<p class="shipping-head__outside">
            {showQsTimeDom ? qsTimeDom : null}
            {!currentQuickship || (hasPartSupportQuickship && !hasSelectedSku) ? (<div>{formatSimplifyAgingText}</div>) : null}
            {floorNDeliveryStyle && currentQuickship ? (<div>{nDeliveryTip}</div>) : null}
          </p>)
          const notSupportQuickshipDom = (<p class="shipping-head__outside time-text-area">
            {formatSimplifyAgingText}{hasDayPercentDes ? <span domPropsInnerHTML={dayPercentDes}></span> : null}<Icon class="qs-icon-top" name="sui_icon_doubt_16px_2" size="16px" color="#959595"/>
          </p>)
          return (
            isSomeSupportQuickship ? supportQuickshipDom : notSupportQuickshipDom
          )
        } else if (shippingStyleV1 && shippingInfo.desc_type == 1) { // 	展示文案类型
          const hasDayPercentDes = !!dayPercentDesDom
          const split = hasDayPercentDes ? ',' : ''
          const content = !quickShipHighlight ? `${
            shippingInfo.aging_is_expose == 1 ? shippingInfo.aging_date + split : ''
          }` : null
          const label = !quickShipHighlight && (content || dayPercentDesDom) ? <span class="gray">{language.SHEIN_KEY_PWA_30099} </span> : null
          return (
            <p class="shipping-head__outside">
              {label}
              {content}
              {dayPercentDesDom}
            </p>
          )
        } else {
          return (
            <div>
              {outsideDom}
              {dayPercentDesDom}
            </div>
          )
        }
      }

      return (
        <div
          aria-hidden="true"
          class="shipping-head__inner"
          da-event-click="1-6-4-4"
          onClick={listeners['openDeliverySlide']}
        >
          {carIcon}
          <div class="shipping-head__inner-content">
            {descQuickShipTimeDmo}
            {contentText()}
            {contentDom()}
            {showPaidVipTips ? (
              <ShippingSheinClub
                is={ShippingSheinClub}
                shein-club-user-info={sheinClubUserInfo}
                shein-club-shpping-text={sheinClubShppingText}
              />
            ) : null}
          </div>
        </div>
      )
    }
    return (
      <section
        class="shipping-head j-push-history-hash"
        {...{
          directives: [{ name: 'ada', value: { level: 0, pos: 0 } }]
        }}
        state="delivery"
        da-event-click="1-6-4-4"
        aria-label={titleText}
        role="text"
        onClick={listeners['openDeliverySlide']}
      >
        <div
          aria-hidden="true"
          class="j-sa-delivery shipping-head__to"
        >
          {shippingHeadTitleDom()}
        </div>
        {deliveryOld()}
        {/* {deliveryNew()} */}
        <i
          aria-hidden="true"
          class={[
            'suiiconfont',
            isRw ? 'sui_icon_more_right_16px' : 'sui_icon_more_right2_16px',
            'shipping-head__inner-dextroversion'
          ]}
        ></i>
      </section>
    )
  }
}
</script>

<style lang="less">
.shipping-head {
  position: relative;

  &__to {
    padding-top: 0.2667rem;
    margin-bottom: 0.3rem;
    position: relative;
    .flexbox();
    .align-center();
    .space-between();
  }

  &__title {
    color: #222;
    .font-dpr(28px);
    line-height: 0.51rem;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 8.6rem;
    /* rw:begin */
    font-family: 'Adieu';
  }

  &__inner {
    color: #222;
    position: relative;
    font-size: 0;

    .sui_icon_freeshipping_flat_24px {
      display: inline-block;
      color: @sui_color_safety;
      .font-dpr(36px);
      .margin-r(0.21rem);
      vertical-align: top !important;
      color: @sui_color_lightgreen !important; /* stylelint-disable-line declaration-no-important */
    }
    .strong-shipping-icon {
      .margin-r(0.21rem);
      vertical-align: top!important; /* stylelint-disable-line declaration-no-important */
    }
  }

  &__inner-content {
    .font-dpr(28px);
    display: inline-block;
    max-width: 90%;
  }

  &__quickship-tip {
    margin-left: 0.7rem;
    .font-dpr(24px);
    line-height: 18px;
    color: @sui_color_gray_dark2;

    &.highlight {
      margin-left: 0;
    }

    &.popup {
      margin-left: 0;
      padding: 0.213333rem 0 0.32rem;
      .border-dpr(border-bottom, 2px, @sui_color_gray_weak1);
    }

    &.no-ml {
      margin-left: 0;
      margin-bottom: 0.16rem;
    }
  }

  &__outside {
    margin-top: 0.1rem;
    font-size: 0.32rem;
  }

  &__day-des {
    display: inline-flex;
    align-items: center;
    margin-top: 0.1866rem;
    font-size: 0.32rem;
    color: @sui_color_gray_dark1;

    i {
      margin-left: 8/75rem;
      color: #959595;
    }
  }

  &__inner-addItem {
    display: flex;
    align-items: center;
    margin-top: 0.213333rem;
    height: 0.373333rem;
    font-size: 12px;
    color: #767676;
  }

  &__inner-dextroversion {
    position: absolute;
    top: 1rem;
    .right(0);
    color: #959595;
  }

  &__title-new {
    position: relative;
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
    }

    .sui_icon_more_right2_16px,
    .sui_icon_more_right_16px {
      top: 50%;
      transform: translateY(-50%);
    }

    .sui_icon_shipping_green_18px {
      color: @sui_color_safety;
    }
  }

  &__unfree {
    font-size: 12px;
    text-indent: 26px;
    color: #767676;
  }

  .medium {
    font-weight: 400;
  }

  .gray {
    color: @sui_color_gray_dark2;
  }

  .green {
    color: @sui_color_safety;
  }
  .time-text-area {
    line-height: 16px;
  }
  .qs-icon-top {
    margin-left: .0533rem;
    vertical-align: top!important; /* stylelint-disable-line declaration-no-important */
  }
}
</style>
