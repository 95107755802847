<script lang="jsx">
/**
 * @component 物流会员提示
 */
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { markPoint } from 'public/src/services/mark/index.js'
import { isLogin as isLoginFn } from 'public/src/pages/common/utils/index.js'
const { PUBLIC_CDN, langPath, GB_cssRight, IS_RW } = gbCommonInfo

export default {
  name: 'ShippingSheinClub',
  functional: true,
  props: {
    source: {
      type: String,
      default: 'goodsDetail_shipping'
    },
    sheinClubUserInfo: {
      type: Object,
      default: () => ({})
    },
    sheinClubShppingText: {
      type: String,
      default: ''
    }
  },
  render(h, { props }) {
    const {
      source,
      sheinClubUserInfo,
      sheinClubShppingText
    } = props
    const { prime_level = 0, total_saving = 0 } = sheinClubUserInfo || {}
    const analysisData = {
      prime_level,
      total_saving,
      location: 'shipping',
      from: 2
    }
    const handleClick = e => {
      daEventCenter.triggerNotice({
        daId: '1-6-1-65',
        extraData: analysisData
      })
      markPoint('toNextPageClick', 'public')
      if (!isLoginFn()) {
        // 未登录
        SHEIN_LOGIN.show({
          show: true,
          from: 'other',
          cb: () => {
            handleClick()
          }
        })
        e.stopPropagation()
        return
      }
      _gb_app_ &&
      _gb_app_.$router.push(
        `${langPath}/user/prime?productsource=${source}`
      )
      e.stopPropagation()
    }
    return (
      <div {...{
        directives: [{
          name: 'expose',
          value: {
            id: '1-6-1-64',
            data: analysisData
          }
        }]
      }}
      class="shipping-club__container"
      onClick={(e) => handleClick(e)}
      >
        <div class="shipping-club__logo"
          style={{
            backgroundImage: `url(${PUBLIC_CDN}/pwa_dist/images/pay_vip/paid_vip_shipping${GB_cssRight ? '_ar-851828de76' : '-1ab77ff6a0'}.png)`
          }}>

        </div>
        <p class="shipping-club__text">
          {sheinClubShppingText}
        </p>
        <i
          aria-hidden={true}
          class={['suiiconfont', 'shipping-club__icon', IS_RW ? 'sui_icon_more_right_16px' : 'sui_icon_more_right2_16px']}
        ></i>
      </div>
    )
  }
}
</script>

<style lang="less">
.shipping-club {
  &__container {
    margin-top: 0.16rem;
    display: inline-flex;
    align-items: center;
    color: #c96e3f;
  }
  &__logo {
    position: relative;
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 85px;
    height: 18px;
    margin-right: 0.10667rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  &__text {
    .font-dpr(24px);
    font-weight: 400;
    line-height: 0.32rem;
  }
  &__icon {
    margin-right: -0.3rem;
  }
}
</style>
