<script lang="jsx">
/**
 * @component 物流轮播卡片
 */
import { template } from '@shein/common-function'

export default {
  name: 'ShippingHead',
  functional: true,
  props: {
    language: {
      type: Object,
      default: () => ({})
    },
    lang: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      default: 0
    },
    shippingLanguage: {
      type: Object,
      default: () => ({})
    },
    shippingAbt: {
      type: String,
      default: ''
    },
    notSupportFree: {
      type: Boolean,
      default: false
    },
    quickShipHighlight: {
      type: Boolean,
      default: false
    },
    descQuickShipTime: {
      type: String,
      default: ''
    },
    dayPercentDes: {
      type: String,
      default: ''
    },
    shippingAbtState: {
      type: Object,
      default: () => ({})
    }
  },
  render (h, { props, listeners }) {
    const {
      language,
      shippingAbt,
      shippingLanguage,
      item,
      index,
      notSupportFree,
      quickShipHighlight,
      descQuickShipTime,
      dayPercentDes,
      shippingAbtState
    } = props
    const shippingTypeDom = () => {
      if (
        (item.hasZeroThreshold && Number(item.hasZeroThreshold) === 1) ||
        (item.startingPriceObj && Number(item.startingPriceObj.amount) === 0 && item.stepPriceObj && Number(item.stepPriceObj.amount) === 0)
      ) {
        return <span class={{ 'shipping-swiper-content__green': shippingAbtState.showTransportaop }}>{shippingLanguage[item.type + '_detail_free']}</span>
      } else {
        let typeDom = <span class="shipping-swiper-content__type">{ item.title.toLocaleLowerCase() }:</span>
        let cheapText = ''
        if (item.shipping_mold != 1 && Number(item.starting_price_obj.amount)) {
          cheapText = Number(item.last_price_obj.amount) ? 
            item.starting_price_obj.amountWithSymbol + '-' + item.last_price_obj.amountWithSymbol :
            item.starting_price_obj.amountWithSymbol
        }
        if (item.shipping_mold == 1) {
          cheapText = item.step_price_obj.amount > 0 ?
            template(
              Number(item.starting_num) > 0 ? item.starting_price_obj.amountWithSymbol : item.step_price_obj.amountWithSymbol,
              language.SHEIN_KEY_PWA_17566
            ) :
            item.starting_price_obj.amountWithSymbol
        }
        const showTransportaopClass = shippingAbtState.showTransportaop && cheapText === item.starting_price_obj.amountWithSymbol
        let cheapDom = <span class={{ 'shipping-swiper-content__cheap': shippingAbt === 'freeshipping', 'shipping-swiper-content__transportao': showTransportaopClass }}>{cheapText}</span>
        let notFreeDom = !notSupportFree && item.is_support_free_shipping == 0 ?
          <span>
            <span class="shipping-swiper-content__light">,</span>
            <span>{ language.SHEIN_KEY_PWA_18722 }</span>
          </span> : null
        let freeDom = () => {
          if (item.is_support_free_shipping == 0) return null
          if (!item.full_amount || item.full_amount.amount <= 0) return null
          return (
            shippingAbtState.showTransportaop ?
              <span class="shipping-swiper-content__cost">{ template(item.full_amount.amountWithSymbol, language.SHEIN_KEY_PWA_28237) }</span> :
              <span>
                <span class="shipping-swiper-content__light">,</span>
                <span>
                  { template(``, language.SHEIN_KEY_PWA_18452) }
                  <span class="shipping-swiper-content__cost">
                    { item.full_amount.amountWithSymbol }+
                  </span>
                </span>
              </span>
          )
        }
        return (
          <span>
            {typeDom}
            {cheapDom}
            {notFreeDom}
            {freeDom()}
          </span>
        )
      }
    }
    return (
      <p>
        {shippingTypeDom()}
        {quickShipHighlight ?
          <p
            {...{
              directives: [
                { name: 'expose', value: { id: '1-6-4-57' } },
              ]
            }}
            class="shipping-swiper-content__quickship-tip highlight"
          >
            <span domPropsInnerHTML={descQuickShipTime}></span>
          </p> : null
        }
        {(!quickShipHighlight && item.timeTip) ?
          <p
            class={[ 'shipping-swiper-content__time-tip', shippingAbt === 'quick' ? 'highlight' : '' ]}
            domPropsInnerHTML={item.timeTip}
          ></p> : null
        }
        {(shippingAbtState.showShippingDayPercent && dayPercentDes) ?
          <p 
            class="shipping-swiper-content__day-des"
            {...{
              directives: [
                { name: 'expose', value: { id: '1-6-4-59', data: { days: item.days || '', shipping_methods: item.transport_type || '' } } },
              ]
            }}
            onClick={e => {
              listeners.openDeliverySlide(e, index, item)
              e.stopPropagation()
            }}
          >
            <span domPropsInnerHTML={dayPercentDes}></span>
            { item.day_percents && item.day_percents.length ? <i aria-hidden="true" class="suiiconfont sui_icon_doubt_15px_2"></i> : null}
          </p> : null
        }
      </p>
    )
  }
}

</script>

<style lang="less">
.shipping-swiper-content {
  &__type {
    text-transform: capitalize;
  }
  &__cheap {
    font-weight: 700;
    color: #222;
  }
  &__green {
    color:var(---sui_color_safety, #198055);
  }
  &__light {
    font-weight: 100;
  }
  &__cost {
    direction: ltr/* rtl: ltr */;
    display: inline-block;
    color: @sui_color_safety;
  }
  &__transportao{
    color: var(--sui-color-gray-dark-1, #222);
  }
  &__quickship-tip {
    margin-left: .7rem;
    .font-dpr(24px);
    line-height: 18px;
    color: @sui_color_gray_dark2;
    &.highlight{
      margin-left: 0;
    }
    &.popup {
      margin-left: 0;
      padding: .213333rem 0 .32rem;
      .border-dpr(border-bottom, 2px, @sui_color_gray_weak1);
    }
    &.no-ml {
      margin-left: 0;
      margin-bottom: .16rem;
    }
  }
  &__time-tip {
    word-break: break-all;
    margin-top: 6px;
    span {
      color: #222;
    }
    &.highlight{
      span{
        font-weight: 700;
      }
    }
  }
  &__day-des {
    display: inline-flex;
    align-items: center;
    margin-top: 0.1866rem;
    font-size: .32rem;
    color: @sui_color_gray_dark1;
    i {
      margin-left: 8/75rem;
      color: #959595;
    }
  }
}
</style>
