<template>
  <s-drawer
    :no-header="true"
    :visible="showDrawer"
    type="full"
    :show-close="true"
    :title="language.SHEIN_KEY_PWA_31004"
    direction="rtl"
    custom-class="manual-drawer"
    :append-to-body="true"
    @close-from-mask="closeUserManualPop"
  >
    <div :style="{ height:innerHeight }">
      <section>
        <header class="manual-drawer__header">
          <i
            class="suiiconfont sui_icon_nav_back_24px j-clear-history-hash"
            @click="closeUserManualPop"
          ></i>
          <span class="manual-drawer__title">{{
            language.SHEIN_KEY_PWA_31004
          }}</span>
          <i
            class="suiiconfont sui_icon_share_24px j-clear-history-hash"
            @click="openShareFriendPop"
          ></i>
        </header>
      </section>
      <SLoading
        v-if="loading"
        type="curpage"
        :show="loading"
        :container-cover-style="{
          position: 'absolute',
          top: '40.125px'
        }"
      />
      <template v-else>
        <div class="manual-drawer__page">{{ currentPage }}/{{ pdfPages }}</div>
        <div
          ref="pdfContainer"
          :style="{ scale: scale, height: `calc(${innerHeight} - 1.07rem)` }"
          class="manual-drawer__footer"
          @touchstart="pdfTouchstart"
          @touchmove="pdfTouchmove"
        >
          <div>
            <canvas
              v-for="pageIndex in pdfPages"
              :ref="`pdfRef${pageIndex}`"
              :key="pageIndex"
              style="display: block"
            ></canvas>
          </div>
        </div>

        <ShareFriendsDrawer
          :showShareFriendPop="showShareFriendPop"
          :language="language"
          :shareLink="content"
          @closeShareFriendPop="showShareFriendPop = false"
        />
      </template>
    </div>
  </s-drawer>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { debounce } from '@shein/common-function'
import ShareFriendsDrawer from '../components/ShareFriendsDrawer'

export default {
  name: 'UserManualDrawer',
  components: {
    ShareFriendsDrawer
  },
  props: {
    showDrawer: {
      type: Boolean,
      default: false
    },
    userManualReady: {
      type: Boolean,
      default: false
    },
    closeUserManualPop: {
      type: Function,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    content: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showShareFriendPop: false,
      pdfDoc: {},
      pdfPages: 0,
      pdfUrl: '',
      pdfScale: 1,
      currentPage: 1,
      startTouches: [],
      loading: false,
      canvasHeight: 0,
      scale: 1,
      innerHeight: '100vh'
    }
  },
  watch: {
    showDrawer(newVal) {
      if (newVal && this.pdfDoc?.getPage){
        this.renderPage(1)
      }
    }
  },
  mounted() {
    this.loadFile(this.content)
    window.addEventListener('resize', this.renderPage(1))
    document.addEventListener('visibilitychange', this.handleVisibilitychange)
    this.innerHeight = window.innerHeight + 'px'
    window.addEventListener('popstate', this.handlePopstate)
  },
  destroyed() {
    window.removeEventListener('resize', this.renderPage(1))
    window.removeEventListener('popstate', this.handlePopstate)
    this.$refs.pdfContainerNaNpxoveEventListener('scroll', this.handleScroll)
    document.removeEventListener('visibilitychange', this.handleVisibilitychange)
  },
  methods: {
    handlePopstate() {
      this.closeUserManualPop()
      this.showShareFriendPop = false
    },
    handleVisibilitychange() {
      !document.hidden && this.renderPage(1)
    },
    pdfTouchstart(e) {
      if (e.touches.length > 1) {
        // 仅当有多于一个触点时开始跟踪
        this.startTouches = [...e.touches]
      }
    },
    pdfTouchmove(e) {
      if (e.touches.length > 1) {
        const endTouches = [...e.touches]
        const startDistance = Math.sqrt(
          Math.pow(this.startTouches[0].pageX - this.startTouches[1].pageX, 2) +
            Math.pow(this.startTouches[0].pageY - this.startTouches[1].pageY, 2)
        )
        const endDistance = Math.sqrt(
          Math.pow(endTouches[0].pageX - endTouches[1].pageX, 2) +
            Math.pow(endTouches[0].pageY - endTouches[1].pageY, 2)
        )
        const scaleChange = endDistance / startDistance

        // 根据触摸变化调整缩放级别
        this.adjustPdfScale(scaleChange)
      }
    },
    adjustPdfScale(scaleChange) {
      this.scale = scaleChange
      // this.pdfScale *= scaleChange
      // this.renderPdfPage(this.pdfScale)
    },
    renderPage(num, currentScale) {
      this.pdfDoc?.getPage?.(num)?.then(page => {
        const canvas = this.$refs[`pdfRef${num}`][0]
        const ctx = canvas.getContext('2d')
        const viewportOriginal = page.getViewport({ scale: 1 })
        // 计算缩放比例以适应屏幕宽度
        this.pdfScale =
          currentScale || window.innerWidth / viewportOriginal.width
        const devicePixelRatio = window.devicePixelRatio || 1
        const viewport = page.getViewport({
          scale: devicePixelRatio * this.pdfScale
        })
        // 获取并设置canvas元素的大小
        canvas.width = viewport.width
        canvas.height = viewport.height
        canvas.style.width = viewport.width / devicePixelRatio + 'px' // 调整CSS大小使其在页面上以正确的物理尺寸显示
        canvas.style.height = viewport.height / devicePixelRatio + 'px'
        this.canvasHeight = viewport.height / devicePixelRatio
        const renderContext = {
          canvasContext: ctx,
          viewport: viewport
        }
        page.render(renderContext)
        if (num === this.pdfPages) {
          this.$nextTick(() => {
            this.$refs.pdfContainer.addEventListener('scroll', debounce({
              func: this.handleScroll,
              wait: 100
            }))
          })
        }
        if (num < this.pdfPages) {
          this.renderPage(num + 1)
        }
      })
    },
    handleScroll() {
      const scrollPosition = this.$refs.pdfContainer.scrollTop
      const pageHeight = this.canvasHeight
      this.currentPage = Math.floor((scrollPosition + 0.5 * pageHeight) / pageHeight) + 1
      // 根据当前页面展示状态，更新页面展示
    },
    async loadFile(url) {
      this.loading = true
      const pdfjsLib = await import('pdfjs-dist/build/pdf')
      const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry')
      pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker

      const loadingTask = pdfjsLib.getDocument(url)
      loadingTask.promise.then(pdf => {
        daEventCenter.triggerNotice({
          daId: '1-6-1-220',
        })
        this.loading = false
        this.pdfDoc = pdf
        this.pdfPages = pdf.numPages
        this.$nextTick(() => {
          this.renderPage(1)
        })
      })
    },
    openShareFriendPop() {
      this.showShareFriendPop = true
      daEventCenter.triggerNotice({
        daId: '1-6-1-221'
      })
    }
  }
}
</script>

<style lang="less">
.manual-drawer {
  position: relative;
  .left(0);
  &__header {
    position: relative;
    width: 100%;
    z-index: 999;
    height: 1.07rem;
    line-height: 1.07rem;
    color: #333;
    .font-dpr(.7467rem);
    text-align: center;
    border-bottom: .0267rem solid #e5e5e5;
    background: #fff;
    .sui_icon_nav_back_24px {
      position: absolute;
      .left(11.25px);
      top: 50%;
      transform: translateY(-50%);
      width: 40.125px;
      height: 40.125px;
      font-weight: normal;
      font-size: .64rem;
    }
    .sui_icon_share_24px {
      position: absolute;
      .right(11.25px);
      top: 50%;
      transform: translateY(-50%);
      width: 40.125px;
      height: 40.125px;
      font-weight: normal;
      .font-dpr(.64rem);
    }
  }
  &__title {
    color: var(---sui_color_gray_dark1, #000);
    text-align: center;
    font-weight: 700;
    font-size: .4533rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    width: 7.44rem;
  }
  &__footer {
    width: 100%;
    overflow-y: scroll;
    overflow-x: auto;
    position: relative;

    canvas {
      &:not(:nth-child(1)) {
        border-top: 3px solid #e5e5e5;
      }
    }
  }
  &__page {
    position: absolute;
    z-index: 1;
    top: 1.6rem;
    left: .5333rem;
    background: #f8f7f7;
    padding: .16rem;
    color: #666;
    border-radius: .0533rem;
  }
}
</style>
