<template>
  <s-drawer
    :visible.sync="isShow"
    custom-class="description-drawer"
    direction="btt"
  >
    <header class="description-drawer__header">
      <i
        class="iconfont icon-close j-clear-history-hash"
        tabindex="1"
        role="none"
        @click="isShow = false"
      ></i>
      <div
        ref="title"
        tabindex="1"
        role="text"
        :aria-label="content.moduleTitleName"
      >
        {{ content.moduleTitleName }}
      </div>
    </header>
    <div class="description-drawer__wrap">
      <DetailFPosContent 
        :idx="idx"
        :content="content"
      />
    </div>
  </s-drawer>
</template>
<script>
import DetailFPosContent from './DetailFPosContent'
export default {
  name: 'DescriptionDrawer',
  components: {
    DetailFPosContent
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    content: {
      type: Object,
      default: () => {}
    },
    idx: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      isShow: false,
    }
  },
  watch: {
    value (newVal) {
      this.isShow = newVal
    },
    isShow (newVal) {
      this.$emit('input', newVal)
      if (newVal) {
        setTimeout(() => {
          this.$refs.title.focus()
        }, 500)
      }
    }
  }
}
</script>
