<script lang="jsx">
import { daEventCenter } from 'public/src/services/eventCenter/index'
import DetailEvoluSHEINBox from 'public/src/pages/goods_detail/middlect/components/DetailEvoluSHEINBox'
import DetailFPosContent from 'public/src/pages/goods_detail/middlect/components/DetailFPosContent'
import { replaceHrefSpecChar } from '@shein/common-function'
import { markPoint } from 'public/src/services/mark/index.js'
import TrendsLabel from 'public/src/pages/goods_detail/components/TrendsLabel'
const { langPath } = gbCommonInfo
  
export default {
  name: 'DescriptionDrawer',
  functional: true,
  props: {
    showDrawer: {
      type: Boolean,
      default: false
    },
    closeDescriptionPop: {
      type: Function,
      default: () => {}
    },
    showEvoluEntry: {
      type: Boolean,
      default: false
    },
    showEvolushein: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: () => ''
    },
    language: {
      type: Object,
      default: () => {}
    },
    content: {
      type: Object,
      default: () => {}
    },
    multiDescription: {
      type: Array,
      default: () => []
    },
    descriptionList: {
      type: Array,
      default: () => []
    },
    sellingPoints: {
      type: Array,
      default: () => []
    },
    $router: {
      type: Object,
      default: () => {}
    },
    trendsInfo: {
      type: Object,
      default: undefined
    },
    trendsImg: {
      type: String,
      default: ''
    },
    detail: {
      type: Object,
      default: () => ({})
    },
  },
  render(h, { props }) {
    const { showDrawer, language, title, sellingPoints, showEvoluEntry, showEvolushein, content, $router, closeDescriptionPop, trendsInfo, trendsImg, detail } = props
    const openSelectProductList = (curSellingPoint) => {
      const { change, tag_id, tag_val_id, tag_val_name_en } = curSellingPoint
      if (change === '1') {
        // 埋点
        daEventCenter.triggerNotice({
          daId: '1-6-2-36',
          extraData: {
            tag_id,
            tag_val_id,
            tag_val_name_en
          }
        })
        const tagValNameEn = tag_val_name_en.split(' ').join('_')
        markPoint('toNextPageClick', 'public')
        closeDescriptionPop()
        $router.push(
          replaceHrefSpecChar({
            href: `${langPath}/sellingpoint-${tag_id}-${tag_val_id}-${tagValNameEn}.html`
          })
        )
      }
    }
    const detailFPosContentProps = {
      directives: [
        {
          name: 'expose',
          value: {
            id: '1-6-4-92',
            data: { is_include_cv: content.hasCVAttr ? 1 : 0 },
          }
        }
      ]
    }

    const trendsEntrySaData = {
      goods_id: detail.goods_id,
      page_number: '-',
      product_select_id: trendsInfo?.product_select_id,
      result_order: '-',
      src_identifier: `trend=${trendsInfo?.trend_word_id}`,
      src_module: 'top_trend',
      trend_word_id: trendsInfo?.trend_word_id,
    }
    const jumpTrendsLandingPage = () => {
      // 埋点
      daEventCenter.triggerNotice({
        daId: '1-6-4-97',
        extraData: trendsEntrySaData,
      })

      closeDescriptionPop()
      $router.push({
        path: `${langPath}/trend-landing`,
        query: {
          adp: detail?.goods_id,
          trendId: trendsInfo?.trend_word_id,
          selectId: trendsInfo?.product_select_id,
          selectUrlId: trendsInfo?.product_select_url_id,
          src_identifier: `trend=${trendsInfo?.trend_word_id}`,
          src_module: 'top_trend',
          page_from: 'detail',
          src_tab_page_id: getSaPageInfo?.tab_page_id,
          catId: detail?.cat_id,
        }
      })
    }
    const trendsEntryExposeProps = {
      directives: [
        {
          name: 'expose',
          value: {
            id: '1-6-4-96',
            data: trendsEntrySaData
          }
        }
      ]
    }

    return <s-drawer
      visible={showDrawer}
      custom-class="description-drawer"
      show-close={false}
      direction="btt"
      append-to-body={true}
      v-on:close-from-mask={closeDescriptionPop}
    >
      <div
        slot="top"
        class="description-drawer__header"
      >
        <i
          class="iconfont icon-close j-clear-history-hash"
          tabindex="1"
          role="none"
          v-on:click={closeDescriptionPop}
          v-on:keyup_enter={closeDescriptionPop}
        ></i>
        <div
          ref="title"
          tabindex="1"
          role="text"
          aria-label={language?.SHEIN_KEY_PWA_14986}
        >
          { title }
        </div>
      </div>
      <div class="description-drawer__wrap">
        {
          trendsInfo && 
          <div 
            class="trends-goods__wrap" 
            vOn:click={jumpTrendsLandingPage}
            {...trendsEntryExposeProps}
          >
            <div class="trends-goods__content">
              <TrendsLabel 
                language={language} 
                trendsInfo={trendsInfo} 
                trendsImg={trendsImg} 
              />
              <span class="trends-goods__desc">
                { trendsInfo.display_desc || language.SHEIN_KEY_PWA_31036 }
              </span>
            </div>
            <i class="suiiconfont sui_icon_more_right_16px_2 trends-goods__icon"></i>
          </div>
        }

        {sellingPoints?.length ? 
          <div class="selling-points__ctn">
            {sellingPoints.map((item) => {
              return <div
                key={item.tag_val_id}
                class="selling-points__item"
                vOn:click={() => {
                  openSelectProductList(item)
                }}
              >
                <h3>{item.tag_val_name_lang}</h3>
                <p>{item.tag_val_desc}</p>
                {item.change === '1' ? <i class="suiiconfont sui_icon_more_right_16px_2"></i> : null}
              </div>
            })}
          </div>
          : null}
        {showEvoluEntry && showEvolushein ? <DetailEvoluSHEINBox /> : null}
        <DetailFPosContent
          {...detailFPosContentProps}
          idx={showDrawer ? 'description' : ''}
          content={content}
        />
      </div>
    </s-drawer>
  }
}
</script>
  
  <style lang="less">
  .description-drawer {
    max-height: 12.17rem;
    .left(0);
    &__header {
      position: relative;
      width: 100%;
      height: 1.07rem;
      line-height: 1.07rem;
      color: #333;
      .font-dpr(28px);
      text-align: center;
      [class*="iconfont"] {
        position: absolute;
        .left(0.3rem);
        top: 50%;
        transform: translateY(-50%);
        width: 1.07rem;
        height: 1.07rem;
        font-weight: normal;
        .font-dpr(24px);
      }
    }
    &__wrap {
      padding: 0.32rem 0.32rem;
    }
    .selling-points {
      &__ctn {
        margin-bottom: 12px;
      }
      &__item {
        position: relative;
        border: 1px solid #FFF5E9;
        background: #FFF;
        padding: 0.32rem 0.8rem 0.32rem 0.32rem;
        &:not(:last-child) {
          margin-bottom: 0.16rem;
        }
        > h3 {
          .font-dpr(28px);
          margin-bottom: 0.2134rem;
          width: 100%;
          .text-overflow();
        }
        > p {
          .font-dpr(24px);
          word-wrap: break-word;
        }
        [class*="iconfont"] {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          .right(0.32rem);
          color: #999;
        }
      }
    }
    &__explain {
      width: 10rem;
      padding: 0 0.32rem 0.533333rem;
      color: #666;
      .font-dpr(24px);
      word-break: break-word;
    }
    &__content {
      padding: 0.32rem 0 0;
      max-height: 10.5rem;
    }
    &__details {
      margin: 0.5333rem 0 0.32rem;
      display: table;
      color: #999;
      .font-dpr(24px);
    }
    &__details-row {
      display: table-row;
      &:first-child {
        .description-drawer__details-val {
          user-select: text;
        }
      }
    }
    &__details-key,
    &__details-val {
      display: table-cell;
      padding-bottom: 0.37rem;
      color: #222;
    }
    &__details-key {
      color: #222;
      .padding-r(0.53rem);
    }
  }
  .trends-goods__wrap {
    padding: 14px 12px 12px;
    display: flex;
    align-items: center;
		margin-bottom: 12px;
    background: url('/pwa_dist/images/product_detail/trends-goods-bg-5d4db3a1a6.png') no-repeat;
    background-size: cover;
    border-radius: 4px;
  }
  .trends-goods__content {
    flex: 1;
    margin-right: 12px;
		overflow: hidden;
  }
  .trends-goods__desc {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    padding-top: 12px;
    color: #fff;
  }
	.trends-goods__icon {
		color: #fff;
	}
  </style>
  
