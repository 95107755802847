'<!--  j-go-size-guide -->
<template>
  <div
    class="sizeChart"
    aria-hidden="true"
  >
    <div
      v-tap="{ id: '1-6-4-14', data: { goodsId: detail.goods_id, from: 'other' } }"
      v-expose="{ id: '1-6-4-14-2', data: { goodsId: detail.goods_id, from: 'other' } }"
      class="size-chart j-push-history-hash"
      state="sizeGuide"
      @click="openSizeGuide"
    >
      {{ language.SHEIN_KEY_PWA_15005 }}
      <i
        v-if="!hiddenArrow"
        :class="['suiiconfont', pageCommonInfo.IS_RW ? 'sui_icon_more_right_16px' : 'sui_icon_more_right2_16px']"
      ></i>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'

export default {
  name: 'SizeGuideEnter',
  props: {
    hiddenArrow: {
      type: Boolean,  
      default: false
    }
  },
  computed: {
    ...mapGetters('newProductDetail/common', ['language', 'detail', 'pageCommonInfo'])
  },
  mounted() {
    if (this.$el && this.$el?.tagName) {
      prefetchResource.listen({
        el: this.$el,
        prefetchList: [
          {
            chunkName: 'SizeGuideDrawer',
            relType: 'prefetch'
          }
        ],
        delay: 3000,
        prefetchCallback: ({ status, info }) => {
          console.log('prefetchCallback', status, info)      
        },
      })
    }
  },
  methods: {
    ...mapActions('newProductDetail', ['openSizeGuidePop']),
    openSizeGuide () {
      if(!this.hiddenArrow){
        this.openSizeGuidePop({ fromScene: 'detail_section' })
      }
    }
  }
}
</script>

<style lang="less">
.size-chart {
  position: relative;
  padding: 0.32rem 0 0.16rem 0;
  text-transform: capitalize;
  display: block;
  width: 100%;
  .txt-l();
  .font-dpr(28px);
  /* rw:begin */
  font-family: 'Adieu';
  .sui_icon_more_right_16px, .sui_icon_more_right2_16px {
    position: absolute;
    color: #959595;
    .right(0);
  }
}
.sizeChart {
  .c-aside.c-aside-full {
    .left(0);
  }
}
</style>
