<template>
  <div class="goods-attr__wrap">
    <div
      v-if="detailGoodsDesc.html && detailGoodsDesc.pos == idx"
      ref="attrDesBox"
      class="goods-attr__des"
      :class="{more: desMore || desMore === null}"
    >
      <span
        ref="attrDes"
        v-html="detailGoodsDesc.html"
      ></span>
      <span
        v-show="desMore"
        class="goods-attr__des-more"
        @click="changeDesMore"
      >...<span>{{ language.SHEIN_KEY_PWA_18777 }}</span></span>
      <span
        v-show="!desMore && desMore !== null"
        class="goods-attr__des-less"
        @click="changeDesMore"
      >{{ language.SHEIN_KEY_PWA_18779 }}</span>
    </div>

    <!-- 带图属性项 -->
    <PrdImageAttrGroup
      v-for="(group, index) in content.imgAttr"
      :key="index"
      :group="group"
    />

    <slot name="middle"></slot>

    <!-- 文字描述 -->
    <div
      v-if="descriptionConf.description27 && idx == 'description'"
      class="goods-attr__color"
      aria-hidden="true"
    >
      <div class="goods-attr__color-key">
        {{ descriptionConf.description27.name }}:
      </div>
      <div class="goods-attr__color-val">
        {{ descriptionConf.description27.value }}
        <div
          class="goods-attr__color-obj"
          :style="{'max-height': descShowMore || !descShowMoreInit ? colorLineHeight + 'px': 'none',}"
        >
          <div
            class="goods-attr__color-word"
            v-html="descriptionConf.goodsDesc"
          ></div>
        </div>
        <span
          v-if="descShowMore"
          class="goods-attr__color-more"
          @click="descShowMore = false"
        >
          {{ language.SHEIN_KEY_PWA_15813 }}
          <span class="iconfont icon-down"></span>
        </span>
      </div>
    </div>
    <div class="goods-attr__list-text">
      <div
        v-for="(item, index) in content.textAttr"
        :key="item.name + index"
        class="goods-attr__list-text-li"
      >
        <div class="goods-attr__list-text-name">
          {{ item.name }}:
        </div>
        <div class="goods-attr__list-text-val">
          {{ item.value }}
          <span dir="ltr">{{ calcValue(item) }}</span>
          <span
            v-if="item.name === 'SKU'"
            v-tap="{id: '1-6-4-91'}"
            class="goods-attr__list-text-copy copy-code-btn"
            :data-clipboard-text="item.value"
            dir="ltr"
            @click="copyCode"
          >
            <Icon
              name="sui_icon_copy_12px_2"
              size="12px"
              color="#2D68A8"
            />
            {{ language.SHEIN_KEY_PWA_25296 }}
          </span>
        </div>
      </div>
    </div>

    <slot name="bottom"></slot>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import PrdImageAttrGroup from './PrdImageAttrGroup'
import Clipboard from 'clipboard'
import { Icon } from '@shein-aidc/icon-vue2'

export default {
  components: {
    Icon,
    PrdImageAttrGroup,
  },
  props: {
    content: {
      type: Object,
      default() {
        return {}
      }
    },
    idx: {
      type: [Number, String],
      default: -1
    }
  },
  data () {
    return {
      desMore: null,
      descShowMore: false,
      descShowMoreInit: false,
      colorLineHeight: 0,
    }
  },
  computed: {
    // ...mapState('productDetail', ['sizeInfoDes']),
    ...mapState('newProductDetail/SizeBox', ['showHeelHeight', 'cmInchInfo']),
    ...mapGetters('newProductDetail/common', ['language']),
    ...mapGetters('newProductDetail/MiddleAttr', ['pageComponents', 'sizeInfoDes']),
    detailGoodsDesc () {
      return this.pageComponents.descPosConf.detailGoodsDesc
    },
    descriptionConf () {
      return this.pageComponents.descriptionConf
    },
    calcValue () {
      return (item) => {
        if(this.showHeelHeight) {
          let dimensionAttrInfo = this.sizeInfoDes?.dimensionAttrInfo ?? []
          const heelHeightMap = dimensionAttrInfo.find(item => { // {attr_name:"Heel Height"attr_name_en:"Heel Height"},通过attr_name的值（多语言）去尺码表拿数据
            return item?.attr_name_en?.includes('Heel Height')
          })
          // 外露场景heel height
          if(heelHeightMap?.attr_name?.includes(item.name)) {
            return this.cmInchInfo
          }
        }
        return ''
      }
    }
  },
  watch: {
    idx: {
      immediate: true,
      handler (val) {
        if (val == 'description') {
          this.calcLineHeight()
        }
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.detailGoodsDesc.html && this.detailGoodsDesc.pos == this.idx) {
        this.desMore = this.$refs.attrDes.offsetHeight > this.$refs.attrDesBox.offsetHeight ? true : null
      }
    })
  },
  methods: {
    changeDesMore () {
      this.desMore = !this.desMore
    },
    calcLineHeight () {
      // description color属性描述超出两行显示view more，计算行高是否超出两行
      this.$nextTick(() => {
        const el = document.querySelector('.goods-attr__color-word')
        if (!this.colorLineHeight) {
          if (el) {
            const fontSize = parseFloat(document.querySelector('html').getAttribute('data-dpr') * window.getComputedStyle(el).getPropertyValue('font-size').replace('px', ''))
            if (fontSize) {
              this.colorLineHeight = fontSize * 3 // 2行 * 行高1.5
            }
          }
        }
        if (!this.descShowMoreInit) {
          if (el) {
            this.descShowMoreInit = true
            this.descShowMore = el.offsetHeight > this.colorLineHeight
          }
        }
      })
    },
    copyCode(){
      const { language } = this
      const clipboard = new Clipboard(`.copy-code-btn`)
      clipboard.on('success', () => {
        this.$toast(language.SHEIN_KEY_PWA_30500 || 'Copied Successfully!', 3000)
        clipboard.destroy()
      })
    },
  }
}
</script>
<style lang="less">
.goods-attr {
  &__color {
    display: flex;
    margin: 0 0 .533333rem;
  }
  &__color-key {
    color: #767676;
    .padding-r(.08rem);
  }
  &__color-val {
    color: #767676;
  }
  &__color-obj {
    word-break: break-word;
    overflow: hidden;
  }
  &__color-more {
    color: #1860A7;
    span {
        color: #1860A7;
    }
  }
  &__des {
    position: relative;
    margin-bottom: .32rem;
    color: #767676;
    .font-dpr(24px);
    line-height: 1.33;
    overflow-y: scroll;
    &.more {
      .line-camp(3);
      overflow: hidden;
    }
  }
  &__des-more {
    position: absolute;
    background: white;
    bottom: 0;
    right: 0;
    span {
      color: #2D68A8;
    }
  }
  &__des-less {
    color: #2D68A8;
  }
  &__list-text {
    display: table;
    width: 100%;
  }
  &__list-text-li {
    display: table-row;
    color: #767676;
    .font-dpr(24px);
    line-height: 1.2;
  }
  &__list-text-name {
    display: table-cell;
    width: 2.72rem;
    padding: 0 .32rem .213333rem 0;
    vertical-align: bottom;
  }
  &__list-text-val {
    display: table-cell;
    padding: 0 0 .2133333rem;
    vertical-align: bottom;
    position: relative;
  }
  &__list-text-copy {
    color: #2D68A8;
    margin-left: 0.26667rem;
    font-size: 11px;
    position: absolute;
    top: 0;
  }
}

</style>
