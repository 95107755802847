<!-- 曝光埋点 j-desc-title-ingredients -->
<template>
  <div>
    <div
      class="ingredients-enter j-desc-title-ingredients"
      state="ingredients"
      da-event-click="1-6-1-19"
      @click="openIngredients"
    >
      <span class="ingredients-enter__title">{{ language.SHEIN_KEY_PWA_17425 }}</span>
      <i class="suiiconfont sui_icon_more_right2_16px"></i>
    </div>
    <IngredientsDrawer
      v-model="showIngredients"
      :language="language"
      :beauty-goods-desc="getBeautyGoodsDesc"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import IngredientsDrawer from './IngredientsDrawer'
export default {
  name: 'IngredientsEnter',
  components: {
    IngredientsDrawer
  },
  data () {
    return {
      showIngredients: false
    }
  },
  computed: {
    ...mapGetters('newProductDetail/common', ['language', 'getBeautyGoodsDesc'])
  },
  methods: {
    openIngredients () {
      this.showIngredients = !this.showIngredients
    }
  }
}
</script>

<style lang="less">
.ingredients-enter {
  border-top: 1px solid #e5e5e5;
  display: block;
  width: 100%;
  text-align: left;
  position: relative;
  height: 1.28rem;
  line-height: 1.28rem;
  text-transform: capitalize;
  &__title {
    .font-dpr(28px);
    white-space: nowrap;
    /* rw:begin */
    font-family: 'Adieu';
  }
  [class*="iconfont"].sui_icon_more_right2_16px {
    position: absolute;
    top: 0;
    color: #959595;
    right: 0;
  }
}
</style>
