<template>
  <div
    v-if="renderStatus"
  >
    <AppCcc 
      :content="content"
      :context="cccAppContext"
      :scene-data="sceneData"
      :cate-links="cateLinks"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '2-2' })

export default {
  components: {
    AppCcc: () => import(/* webpackChunkName: "plv2_FlowAd_AppCcc" */ 'public/src/pages/components/ccc/Index.vue'),
  },
  computed: {
    ...mapGetters('newProductDetail/CCCBannerBox', [
      'renderStatus', 
      'cateLinks',
      'content',
      'sceneData'])
  }
}
</script>
