<template>
  <s-drawer
    :no-header="true"
    :visible="showShareFriendPop"
    type="normal"
    :show-close="true"
    direction="btt"
    custom-class="shareFriends-drawer"
    :append-to-body="true"
    @close-from-mask="$emit('closeShareFriendPop')"
  >
    <section>
      <header class="shareFriends-drawer__header">
        <span class="shareFriends-drawer__title">{{
          language.SHEIN_KEY_PWA_18311
        }}</span>
      </header>
    </section>
    <div 
      class="shareFriends-drawer__footer"
    >
      <div
        v-for="item in mediaList"
        :key="item.name"
        class="shareFriends-drawer__footer-item"
      >
        <img
          class="shareFriends-drawer__footer-img copy-btn"
          :src="`${PUBLIC_CDN}/pwa_dist/images/orders/${item.img}`"
          :data-clipboard-text="shareLink"
          alt=""
          @click="goToShare(item.url)"
        />
        <div class="shareFriends-drawer__footer-title">
          {{ item.name }}
        </div>
      </div>
    </div>
    <div 
      class="shareFriends-drawer__cancel" 
      @click="$emit('closeShareFriendPop')"
    >
      {{ language.SHEIN_KEY_PWA_20168 }}
    </div>
  </s-drawer>
</template>

<script>
import Clipboard from 'clipboard'
import { Toast } from '@shein/sui-mobile'
import { daEventCenter } from 'public/src/services/eventCenter/index'

export default {
  name: 'ShareFriendsDrawer',
  props: {
    showShareFriendPop: {
      type: Boolean,
      default: false
    },
    closeUserManualPop: {
      type: Function,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    shareLink: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      mediaList: [
        {
          img: 'sui_img_whatsapp_58px-5c157abd10.svg',
          name: 'WhatsApp',
          url: 'https://api.whatsapp.com/send?text='
        },
        {
          img: 'sui_img_fb_58px-32eae9629e.svg',
          name: 'Facebook',
          url: 'http://www.facebook.com/sharer/sharer.php?u='
        },
        {
          img: 'sui_img_twitter_58px-3dd7eb87f4.svg',
          name: 'Twitter',
          url: 'https://twitter.com/intent/tweet?url='
        },
        // {
        //   img: 'sui_img_ins_58px-352c0299be.svg',
        //   name: 'Instagram',
        //   url: 'http://instagram.com/share?url='
        // },
        {
          img: 'sui_img_pinterest_58px-efcfac1dcd.svg',
          name: 'Pinterest',
          url: 'http://pinterest.com/pin/create/button/?url='
        },
        {
          img: 'sui_img_copy_58px-c1243d1b66.svg',
          name: 'Copy Link',
          url: ''
        }
      ]
    }
  },
  methods: {
    openShareFriendPop() {
      this.showShareFriendPop = true
      daEventCenter.triggerNotice({
        daId: '1-6-1-221'
      })
    },
    goToShare(url) {
      if (!url) {
        const clipboard = new Clipboard('.copy-btn', {
          text: () => this.shareLink
        })

        clipboard.on('success', e => {
          Toast({ content: this.language.SHEIN_KEY_PWA_16423, duration: 3000 })
          e.clearSelection()
        })
      } else {
        const link = `${url}${this.shareLink}?ReviewShare=ReviewShare`
        window.open(link, '_blank')
      }
    }
  }
}
</script>

<style lang="less">
.shareFriends-drawer {
  border-radius: .2133rem .2133rem 0 0;
  .left(0);
  &__header {
    position: relative;
    width: 100%;
    height: 40.125px;
    line-height: 40.125px;
    color: #333;
    .font-dpr(.7467rem);
    text-align: center;
    border-bottom: .0267rem solid #e5e5e5;
  }
  &__title {
    color: var(---sui_color_gray_dark1, #000);
    text-align: center;
    font-weight: 700;
    font-size: .4533rem;
  }
  &__cancel {
    width: 100%;
    height: 1.6rem;
    border-top: .32rem solid #f6f6f6;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .3733rem;
  }
  &__footer{
    display: flex;
    justify-content: flex-start;
    gap: .7467rem;
    flex-wrap:wrap;
    padding: .48rem .64rem;
    &-item{
      img {
        width: 1.5467rem;
        height: 1.5467rem;
      }
    }
    &-title{
      font-size: .32rem;
      color: #666;
      text-align: center;
      margin-top: .16rem;
    }
  }
}
</style>
